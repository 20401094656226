import React, { useState, useEffect } from "react";
import { Container, Row, Col, ButtonGroup, Button } from "react-bootstrap";

import Chart from "react-google-charts";
import axios from "axios";
import { THPHISTORY_SERVER } from "../../Config";
import { SETTING_SERVER } from "../../Config";
import { Icon } from "@iconify/react";
import rayVertex from "@iconify/icons-mdi/ray-vertex";
import DataChart from "./DataChart";
import ApexCharts from 'apexcharts';
let currentTemp;
let currentHumi;
let currentPres;

let tempChecked = true;
let humiChecked = true;

export const THP = (props) => {
  const [chartData, setChartData] = useState([]);
  const [temperatureChart, setTemperatureChart] = useState([]);
  const [humidityChart, setHumidityChart] = useState([]);
  const [pressureChart, setPressureChart] = useState([]);
  const [currentChartRange, setCurrentChartRange] = useState("all");
  const [isCelsius, setIsCelsius] = useState(true);
  let user = JSON.parse(window.localStorage.getItem("user"));
  let current = new Date();

  const [labelTemperature, setLabelTemperature] = useState("");
  const [dateTime, setDateTime] = useState(
    current.toLocaleString('en-US', {weekday: 'long', hour: 'numeric', minute: '2-digit', hour12: true})
  );

  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    makeChartData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let message = props.socketMsg;

    if (message.message === "thp") {
      let tempChartData = JSON.parse(JSON.stringify(chartData));

      let createdAt = new Date(message.thp.dt);
      let showHourMin =
        createdAt.getHours() < 12
          ? createdAt.getHours() + ":" + createdAt.getMinutes() + " AM"
          : createdAt.getHours() - 12 + ":" + createdAt.getMinutes() + " PM";
      let item = [showHourMin];
      if (humiChecked) item.push(parseFloat(message.thp.humidity));
      let temp = message.thp.temperature;
      let humi = message.thp.humidity;
      let pres = message.thp.pressure;

      currentTemp = parseFloat(temp);
      currentHumi = parseFloat(humi);
      currentPres = parseFloat(pres);

      if (!isCelsius) {
        temp = ((temp * 9) / 5 + 32).toFixed(1);
      }
      if (tempChecked) item.push(parseFloat(temp));
      tempChartData.push(item);

      setChartData(tempChartData);
    }
    // eslint-disable-next-line
  }, [props.socketMsg]);

  const makeChartData = () => {
    axios
      .post(`${SETTING_SERVER}/getorcreatebyuserid`, {
        userId: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          if ("timeZone" in data.data.setting) {
            if (data.data.setting.timeZone != null) {
              setDateTime(
                current.toLocaleString('en-US',
                 {weekday: 'long', hour: 'numeric', minute: '2-digit', hour12: true, timeZone: data.data.setting.timeZone})
              );
            }
          }

          let tempIsCelsius =
            data.data.setting.tempUnit === "fahrenheit" ? false : true;
          setIsCelsius(tempIsCelsius);
          let tempLabelTemperature =
            data.data.setting.tempUnit === "fahrenheit"
              ? "Temperature (F)"
              : "Temperature (C)";
          setLabelTemperature(tempLabelTemperature);
          axios
            .post(`${THPHISTORY_SERVER}/getfromdeviceid`, {
              deviceid: props.deviceId,
            })
            .then((data) => {
              if (data.data.success) {
                let thphistories = data.data.thphistories;
                thphistories.reverse();
                if (!tempIsCelsius) {
                  for (let i = 0; i < thphistories.length; i++) {
                    let temp = thphistories[i].temperature;
                    thphistories[i].temperature = ((temp * 9) / 5 + 32).toFixed(
                      1
                    );
                  }
                }

                let chartData = [["Time"]];
                if (humiChecked) chartData[0].push("Humidity");
                if (tempChecked) chartData[0].push("Temperature");

                currentTemp = parseFloat(
                  thphistories[thphistories.length - 1].temperature
                );
                currentHumi = parseFloat(
                  thphistories[thphistories.length - 1].humidity
                );
                currentPres = parseFloat(
                  thphistories[thphistories.length - 1].pressure
                );
                thphistories.forEach((thphistory) => {
                  let createdAt = new Date(thphistory.createdAt);
                  let mins = createdAt.getMinutes();
                  mins = mins < 10 ? "0" + mins : mins;
                  let noon = createdAt.getHours() < 12 ? " AM" : " PM";
                  let hours = createdAt.getHours();
                  let month = createdAt.getMonth() + 1;
                  let date = createdAt.getDate();
                  if (hours < 12) {
                    hours = hours ? hours : 12;
                    hours = hours < 10 ? "0" + hours : hours;
                  } else {
                    hours -= 12;
                    hours = hours < 10 ? "0" + hours : hours;
                  }
                  let showHourMin =
                    month + "-" + date + ":" + hours + ":" + mins + noon;

                  let item = [showHourMin];
                  if (humiChecked) item.push(parseFloat(thphistory.humidity));
                  if (tempChecked)
                    item.push(parseFloat(thphistory.temperature));
                  chartData.push(item);
                });

                setChartData(chartData);

                let tmpTemperatureData = thphistories.map(dataPoint => [new Date(dataPoint.createdAt).getTime(), parseFloat(dataPoint.temperature)]);
                setTemperatureChart(tmpTemperatureData);

                let tmpHumidityData = thphistories.map(dataPoint => [new Date(dataPoint.createdAt).getTime(), parseFloat(dataPoint.humidity)]);
                setHumidityChart(tmpHumidityData);

                let tmpPressureData = thphistories.map(dataPoint => [new Date(dataPoint.createdAt).getTime(), parseFloat(dataPoint.pressure)]);
                setPressureChart(tmpPressureData);
              } else {
                console.log("THPHISTORY_SERVER/getfromdeviceid Unsuccess");
              }
            })
            .catch(() => {
              console.log("Oops, request failed!");
            });
          // }
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const data = chartData;

  const options = {
    // width: 1000,
    // height: 600,
    hAxis: {
      title: "Time",
    },
    vAxis: {
      title: "Temperature, Humidity",
    },
    pointsVisible: false,
    legend: "none",
  };

  const zoomChart = (range) => {
    let charts = [
      "filzertech-temperature-chart",
      "filzertech-humidity-chart",
      "filzertech-pressure-chart"
    ];
    let startDate = new Date()
    if (range == "48H") {
      setCurrentChartRange("two_days")
      startDate.setDate(startDate.getDate() - 2)
    } else if (range == "1W") {
      setCurrentChartRange("one_week")
      startDate.setDate(startDate.getDate() - 7)
    } else if (range == "1M") {
      setCurrentChartRange("one_month")
      startDate.setMonth(startDate.getMonth() - 1)
    } else if (range == "1Y") {
      setCurrentChartRange("one_year")
      startDate.setFullYear(startDate.getFullYear() - 1)
    } else if (range == "ALL") {
      setCurrentChartRange("all")
      charts.forEach(chartId =>
        ApexCharts.exec(
          chartId,
          'resetSeries',
        )
      );
      return;
    }
    console.log(range);

    charts.forEach(chartId =>
      ApexCharts.exec(
        chartId,
        'zoomX',
        startDate.getTime(),
        new Date().getTime()
      )
    );
  }

  const chartDateRangeIsActive = (e) => {
    console.log(e.target.id)
    return currentChartRange == e.target.id
  }

  return (
    <div className="sub_content">
      <div className="sub_title">Temperature, Humidity</div>
      <div className="sub_body">
        {chartData.length === 0 ? (
          <div>Sorry, there is no data to show.</div>
        ) : (
          <Container>
            <Row style={{ marginTop: "20px" }}>
              <Col className="text-center">
                <Row className="px-4 mb-1">
                  <Col className="mb-2 col-md-6 offset-md-3">
                    <div class="toolbar">
                      <ButtonGroup className="d-flex">
                        <Button active={currentChartRange == "two_days"} id="two_days" onClick={() => zoomChart("48H")}>48H</Button>
                        <Button active={currentChartRange == "one_week"} id="one_week" onClick={() => zoomChart("1W")}>1W</Button>
                        <Button active={currentChartRange == "one_month"} id="one_month" class="active" onClick={() => zoomChart("1M")}>1M</Button>
                        <Button active={currentChartRange == "one_year"} id="one_year" onClick={() => zoomChart("1Y")}>1Y</Button>
                        <Button active={currentChartRange == "all"} id="all" onClick={() => zoomChart("ALL")}>ALL</Button>
                      </ButtonGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5 style={{marginBottom: 0}}>
                    Current Temperature: {currentTemp}&deg;{isCelsius ? "C" : "F"}
                    </h5>
                  </Col>
                </Row>
              </Col>
            </Row>
            {temperatureChart.length > 0 ? (
              <div>
                <DataChart dataType="temp" tempData={temperatureChart} tempUnit={isCelsius ? "C" : "F"}/>
              </div>
            ) : (
              <Row>There is no temperature history to show.</Row>
            )}
            <Col className="text-center">
              <Row>
                <Col>
                  <h5 style={{marginBottom: 0}}>
                  Current Humidity: {currentHumi}%
                  </h5>
                </Col>
              </Row>
            </Col>
            {humidityChart.length > 0 ? (
              <div>
                <DataChart dataType="humi" humiData={humidityChart}/>
              </div>
            ) : (
              <Row>There is no temperature history to show.</Row>
            )}
            {(pressureChart.length > 0 && !isNaN(pressureChart[pressureChart.length-1][1])) ? (
              <div>
                <Col className="text-center">
                  <Row>
                    <Col>
                      <h5 style={{marginBottom: 0}}>
                      Current Pressure: {currentPres}mBar
                      </h5>
                    </Col>
                  </Row>
                </Col>
                <DataChart dataType="pres" presData={pressureChart}/>
              </div>
            ) : null}
          </Container>
        )}
      </div>
    </div>
  );
};
