import React from 'react';
import { Menu } from 'antd';

function LeftMenu(props) {
  return (
    <Menu mode={props.mode}>
      <Menu.Item key="products" className='myNavs'>
        <a href="/product">Products</a>
      </Menu.Item>
      <Menu.Item key="aboutus" className='myNavs'>
        <a href="/about">About us</a>
      </Menu.Item>
      <Menu.Item key="contactus" className='myNavs'>
        <a href="/contactus">Contact us</a>
      </Menu.Item>
    </Menu>
  )
}

export default LeftMenu