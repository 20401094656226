import React, { useEffect, useState } from "react";

import NavBar from "../NavBar";
import FooterBar from "../FooterBar";

import axios from "axios";
import { FILZERDOC_SERVER } from "../../Config";

export const TermPage = () => {
  const [content, setContent] = useState(undefined);

  useEffect(() => {
    getContent();
    // eslint-disable-next-line
  }, []);

  const getContent = () => {
    axios
      .post(`${FILZERDOC_SERVER}/getorcreatebytag`, {
        tag: "terms",
      })
      .then((data) => {
        if (data.data.success) {
          setContent(data.data.filzerdoc.content);
        } else {
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  return (
    <>
      <NavBar />
      <div className="myMain">
        {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
        <div className="terms">
          <div className="terms_main">
            <div
              className="termstext"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </div>
        </div>
      </div>
      <FooterBar />
    </>
  );
};
