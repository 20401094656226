import React, { useState, useEffect } from "react";
import { Form, Button, Toast, Col } from "react-bootstrap";
import MenuBar from "../MenuBar";
import axios from "axios";
import { PRODUCT_SERVER } from "../../Config";
import { FILZERDOC_SERVER } from "../../Config";
import { TINYMCE_EDITOR_KEY } from "../../Config";
import NavBar from "../NavBar";

import ImageUploader from "react-images-upload";
import { Editor } from "@tinymce/tinymce-react";

export const AddProduct = (props) => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");

  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");

  /////////////////////////////////
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [productName, setProductName] = useState("");
  const [desc, setDesc] = useState("");
  const [spec, setSpec] = useState("");
  const [support, setSupport] = useState("");
  const [showOnHome, setShowOnHome] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [urlPdfs, setUrlPdfs] = useState("");

  useEffect(() => {
    if (window.innerWidth < 981) {
      setMainClass("body_panel col-sm-12");
      setPropShowMenu(false);
    }
  }, []);

  const onChangeProductName = (e) => {
    setProductName(e.target.value);
  };

  const onChangeShowOnHome = (e) => {
    setShowOnHome(e.target.checked);
  };

  const handleDescChange = (content) => {
    setDesc(content);
  };

  const handleSpecChange = (content) => {
    console.log("Spec changed..");
    setSpec(content);
  };

  const handleSupportChange = (content) => {
    setSupport(content);
  };

  const onDrop = (e) => {
    setPictures((pictures) => [...pictures, ...e]);
  };

  const onFormSubmit = (e) => {
    setAddErrorMessage("");
    e.preventDefault();

    if (productName === "") {
      setAddErrorMessage("Product name should be input.");
      return;
    }

    const myData = new FormData();
    myData.append("productName", productName);
    myData.append("productDescription", desc);
    myData.append("spec", spec);
    myData.append("support", support);
    myData.append("showOnHome", showOnHome);

    for (let picture of pictures) {
      myData.append("myPictures[]", picture);
    }

    axios
      .post(`${PRODUCT_SERVER}/addproduct`, myData, {})
      .then((data) => {
        if (data.data.success) {
          setToastText(data.data.message);
          setShowToast(true);
        } else {
          if (data.data.error.name === "SequelizeUniqueConstraintError")
            setAddErrorMessage("Product name should be unique.");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onChangePdf = (e) => {
    setPdfs(e.target.files);
  };

  const onClickGetPdfUrl = () => {
    const myData = new FormData();
    for (let pdf of pdfs) {
      myData.append("myPdfs[]", pdf);
    }
    axios
      .post(`${FILZERDOC_SERVER}/getpdfurl`, myData, {})
      .then((data) => {
        if (data.data.success) {
          let arrNames = data.data.pdfNames.split(",");
          arrNames.splice(-1);
          let arrUrlPdfs = [];
          for (let i = 0; i < arrNames.length; i++) {
            arrUrlPdfs.push(
              <span key={i}>
                /product_pdfs/{arrNames[i]}
                <br />
              </span>
            );
          }
          setUrlPdfs(arrUrlPdfs);
        } else {
          console.log("Oops, can't get url of pdf");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  //////////////////////////// Hamburger menu  ////////////////////////////////////////////////
  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu && window.innerWidth > 981
      ? setMainClass("body_panel col-sm-10")
      : setMainClass("body_panel col-sm-12");
  };

  return (
    <>
      <NavBar updateHamburger={updateHamburger} />
      {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
      <div className="mainpanel container-fluid">
        <div className="row">
          {propShowMenu ? <MenuBar /> : null}

          <Toast
            delay={3000}
            autohide
            show={showToast}
            onClose={() => setShowToast(false)}
            style={{
              position: "fixed",
              top: 10,
              right: 10,
              paddingLeft: 20,
              zIndex: 8,
              width: 400,
              borderTop: "4px solid #F47721",
            }}
          >
            <Toast.Body>{toastText}</Toast.Body>
          </Toast>

          <div className={mainClass}>
            <div className="page_title">Add product</div>
            <div className="page_content">
              <Form onSubmit={onFormSubmit}>
                {addErrorMessage && (
                  <label>
                    <p
                      style={{
                        color: "#EB5757",
                        fontSize: "14px",
                        marginTop: "-10px",
                      }}
                    >
                      {addErrorMessage}
                    </p>
                  </label>
                )}
                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Product name</Form.Label>
                    <Form.Control
                      value={productName}
                      name="productName"
                      onChange={(e) => {
                        onChangeProductName(e);
                      }}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Editor
                    apiKey={TINYMCE_EDITOR_KEY}
                    init={{
                      selector: "textarea",
                      height: 270,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table contextmenu paste code",
                      ],
                      toolbar:
                        "undo redo | insert | styleselect | bold italic underline| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                      // content_css: [
                      //   "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i",
                      //   "//www.tinymce.com/css/codepen.min.css",
                      // ],
                      theme: "silver",
                    }}
                    onEditorChange={(e) => handleDescChange(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Specs</Form.Label>
                  <Editor
                    apiKey={TINYMCE_EDITOR_KEY}
                    init={{
                      selector: "textarea",
                      height: 270,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table contextmenu paste code",
                      ],
                      toolbar:
                        "undo redo | insert | styleselect | bold italic underline| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                      theme: "silver",
                    }}
                    onEditorChange={(e) => handleSpecChange(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Support</Form.Label>
                  <Editor
                    apiKey={TINYMCE_EDITOR_KEY}
                    init={{
                      selector: "textarea",
                      height: 270,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table contextmenu paste code",
                      ],
                      toolbar:
                        "undo redo | insert | styleselect | bold italic underline| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                      theme: "silver",
                    }}
                    onEditorChange={(e) => handleSupportChange(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <ImageUploader
                    withIcon={true}
                    buttonText="Choose images"
                    onChange={onDrop}
                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                    withPreview={true}
                  />
                </Form.Group>
                <Form.Row>
                  <Form.Group>
                    <input
                      type="file"
                      multiple
                      accept="application/pdf"
                      onChange={(e) => {
                        onChangePdf(e);
                      }}
                    />
                    <Button
                      className="normalButton"
                      type="submit"
                      style={{ float: "right" }}
                      onClick={() => {
                        onClickGetPdfUrl();
                      }}
                    >
                      Get pdf url
                    </Button>
                    <div>{urlPdfs}</div>
                  </Form.Group>
                </Form.Row>
                <Form.Group>
                  <Form.Label>Show on Homepage</Form.Label>
                  <Form.Check
                    type="checkbox"
                    id="autoClear"
                    checked={showOnHome}
                    onChange={(e) => {
                      onChangeShowOnHome(e);
                    }}
                    label="Show this device on Homepage."
                  />
                </Form.Group>
                <Button className="normalButton" type="submit">
                  Add product
                </Button>
                <Button
                  className="cancelButton"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    props.history.goBack();
                  }}
                >
                  Back
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
