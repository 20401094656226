import React, { useState, useEffect } from "react";
import { Form, Button, Toast } from "react-bootstrap";
import axios from "axios";
import { FILZERDOC_SERVER } from "../../Config";
import { TINYMCE_EDITOR_KEY } from "../../Config";

import ImageUploader from "react-images-upload";
import { Editor } from "@tinymce/tinymce-react";

export const EditDoc = (props) => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");
  const [pictures, setPictures] = useState([]);
  const [urls, setUrls] = useState("");
  const [pdfs, setPdfs] = useState([]);
  const [urlPdfs, setUrlPdfs] = useState("");

  /////////////////////////////////
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [content, setContent] = useState("");

  let tag = props.tag;
  let subTitle = props.subTitle;
  useEffect(() => {
    getContent();
    // eslint-disable-next-line
  }, []);

  const getContent = () => {
    axios
      .post(`${FILZERDOC_SERVER}/getorcreatebytag`, {
        tag: tag,
      })
      .then((data) => {
        if (data.data.success) {
          setContent(data.data.filzerdoc.content);
        } else {
          if (data.data.error.name === "SequelizeUniqueConstraintError")
            setAddErrorMessage("Product name should be unique.");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const handleEditorChange = (str) => {
    setContent(str);
  };

  const onClickSave = (e) => {
    setAddErrorMessage("");

    axios
      .post(`${FILZERDOC_SERVER}/updateone`, { tag, content })
      .then((data) => {
        if (data.data.success) {
          setToastText("Docs content successfully updated.");
          setShowToast(true);
        } else {
          if (data.data.error.name === "SequelizeUniqueConstraintError")
            setAddErrorMessage("Product name should be unique.");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onDrop = (e) => {
    setPictures(e);
  };

  const onClickGetUrl = () => {
    const myData = new FormData();
    for (let picture of pictures) {
      myData.append("myPictures[]", picture);
    }
    axios
      .post(`${FILZERDOC_SERVER}/getimageurl`, myData, {})
      .then((data) => {
        if (data.data.success) {
          let arrNames = data.data.imageNames.split(",");
          arrNames.splice(-1);
          let arrUrls = [];
          for (let i = 0; i < arrNames.length; i++) {
            arrUrls.push(
              <span key={i}>
                product_pics/{arrNames[i]}
                <br />
              </span>
            );
          }
          setUrls(arrUrls);
        } else {
          console.log("Oops, can't get url of image");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onChangePdf = (e) => {
    setPdfs(e.target.files);
  };

  const onClickGetPdfUrl = () => {
    const myData = new FormData();
    for (let pdf of pdfs) {
      myData.append("myPdfs[]", pdf);
    }
    axios
      .post(`${FILZERDOC_SERVER}/getpdfurl`, myData, {})
      .then((data) => {
        if (data.data.success) {
          let arrNames = data.data.pdfNames.split(",");
          arrNames.splice(-1);
          let arrUrlPdfs = [];
          for (let i = 0; i < arrNames.length; i++) {
            arrUrlPdfs.push(
              <span key={i}>
                /product_pdfs/{arrNames[i]}
                <br />
              </span>
            );
          }
          setUrlPdfs(arrUrlPdfs);
        } else {
          console.log("Oops, can't get url of pdf");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  return (
    <>
      <div className="sub_content">
        <div className="sub_title">{subTitle}</div>
        <div className="sub_body">
          <Toast
            delay={3000}
            autohide
            show={showToast}
            onClose={() => setShowToast(false)}
            style={{
              position: "fixed",
              top: 10,
              right: 10,
              paddingLeft: 20,
              zIndex: 8,
              width: 400,
              borderTop: "4px solid #F47721",
            }}
          >
            <Toast.Body>{toastText}</Toast.Body>
          </Toast>

          {addErrorMessage && (
            <label>
              <p
                style={{
                  color: "#EB5757",
                  fontSize: "14px",
                  marginTop: "-10px",
                }}
              >
                {addErrorMessage}
              </p>
            </label>
          )}

          <div>
            <Editor
              apiKey={TINYMCE_EDITOR_KEY}
              init={{
                selector: "textarea",
                height: 270,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table contextmenu paste code",
                ],
                toolbar:
                  "undo redo | insert | styleselect | bold italic underline| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                theme: "silver",
              }}
              //initialValue={content}
              value={content}
              onEditorChange={(e) => handleEditorChange(e)}
            />
          </div>
          <Form.Group>
            <ImageUploader
              withIcon={true}
              buttonText="Choose images"
              onChange={onDrop}
              imgExtension={[".jpg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
              withPreview={true}
            />
            <Button
              className="normalButton"
              type="submit"
              style={{ float: "right" }}
              onClick={() => {
                onClickGetUrl();
              }}
            >
              Get image url
            </Button>
            <div>{urls}</div>
          </Form.Group>
          {tag === "faq" ? (
            <Form.Group>
              <Form.Row>
                <input
                  type="file"
                  multiple
                  accept="application/pdf"
                  onChange={(e) => {
                    onChangePdf(e);
                  }}
                />
              </Form.Row>
              <Form.Row>
                <Button
                  className="normalButton"
                  type="submit"
                  style={{ float: "right", marginTop: "10px" }}
                  onClick={() => {
                    onClickGetPdfUrl();
                  }}
                >
                  Get pdf url
                </Button>
                <div>{urlPdfs}</div>
              </Form.Row>
            </Form.Group>
          ) : null}
          <Button
            className="normalButton"
            type="submit"
            onClick={() => {
              onClickSave();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
