import React, { useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { USER_SERVER } from "../../Config";
import FooterBar from "../FooterBar";
import NavBar from "../NavBar";

export const VerificationRequiredPage = (props) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleClick = () => {
    setErrorMessage("");
    setSuccessMessage("");
    axios
      .post(`${USER_SERVER}/resendverificationemail`, {
        email: props.location.state.email,
      })
      .then((data) => {
        if (data.data.success) {
          setSuccessMessage(data.data.msg);
        } else {
          setErrorMessage(data.data.msg);
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
        setErrorMessage("Unable to resend verification email.");
      });
  };

  return (
    <>
      <NavBar />
      {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
      <div className="myMain">
        <div style={{ margin: "auto", paddingTop: "30px" }}></div>
        <div className="showPanel">
          <div style={{ height: "4px", backgroundColor: "#E0E0E0" }}></div>
          <div style={{ margin: "30px 30px 40px 30px" }}>
            <h1>Verification required</h1>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            {successMessage && (
              <p style={{ color: "green" }}>{successMessage}</p>
            )}
            <p>You did not verify your email address yet.</p>
            <p>
              A verification email has been sent to your email address{" "}
              {props.location.state.email && (
                <span style={{ fontWeight: "bold" }}>
                  {props.location.state.email}
                </span>
              )}
              . Please follow the instructions in the email to complete the sign
              up process.
            </p>
            <p>
              It may take a few minutes before the email arrives. If you can not
              find the email, please check your spam or junk email folders, or
              click on the button below to resend the verification email.
            </p>
            <div style={{ margin: "30px 0px 30px 0px" }}>
              <Button className="normalButton" onClick={handleClick}>
                Resend verification email
              </Button>
            </div>
          </div>
        </div>
      </div>
      <FooterBar />
    </>
  );
};
