import React from 'react';
import PropTypes from 'prop-types';
class ReactShowMoreLess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false
        };
    }
    render() {
        const { props } = this;
        const { readMoreText, readLessText, readMoreClassName, readLessClassName, readMoreStyle, readLessStyle, shortVersion, fullVersion } = props;
        const { showMore } = this.state;
        const that = this;
        const ShowMore = () => (
        <span
            className={readMoreClassName}
            role="presentation"
            style={readMoreStyle}
            onClick={(e) => {
                e.stopPropagation();
                that.setState({showMore: true});
            }}
        >
            {readMoreText}
            </span>
        );
        const ShowLess = () => (
        <span
            className={readLessClassName}
            role="presentation"
            style={readLessStyle}
            onClick={(e) => {
                e.stopPropagation();
                that.setState({showMore: false});
            }}
        >
            {readLessText}
        </span>
        );
        return (
            <React.Fragment>{showMore ? fullVersion : shortVersion} {showMore ? <ShowLess /> : <ShowMore />}</React.Fragment>
        );
    }
}

ReactShowMoreLess.propTypes = {
    ellipsis: PropTypes.string,
    readMoreText: PropTypes.string,
    readLessText: PropTypes.string,
    readMoreClassName: PropTypes.string,
    readLessClassName: PropTypes.string,
    readMoreStyle: PropTypes.object,
    readLessStyle: PropTypes.object,
    shortVersion: PropTypes.element,
    fullVersion: PropTypes.element,
};
ReactShowMoreLess.defaultProps = {
    ellipsis: '…',
    readMoreText: 'Read more',
    readLessText: 'Read less',
    readMoreClassName: 'react-read-more-read-less react-read-more-read-less-more',
    readLessClassName: 'react-read-more-read-less react-read-more-read-less-less',
    readMoreStyle: {whiteSpace: "nowrap", cursor: "pointer"},
    readLessStyle: {whiteSpace: "nowrap", cursor: "pointer"},
    shortVersion: null,
    fullVersion: null,
};
export default ReactShowMoreLess;