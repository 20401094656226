import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { loginUser } from "../../../actions/user.actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import {
  Icon,
  // InlineIcon
} from "@iconify/react";
import eyeIcon from "@iconify/icons-mdi/eye";
import eyeOff from "@iconify/icons-mdi/eye-off";
import { useDispatch } from "react-redux";
import { FaUser, FaLock } from "react-icons/fa";
import NavBar from "../NavBar";
import FooterBar from "../FooterBar";

const { Title } = Typography;

function SigninPage(props) {
  const dispatch = useDispatch();
  const rememberMeChecked = localStorage.getItem("rememberMe") ? true : false;
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(rememberMeChecked);
  const [showPassword, setShowPassword] = useState(false);

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
    // setRememberMe(rememberMe => !rememberMe);
  };

  const initialEmail =
    localStorage.getItem("rememberMe") === undefined || ""
      ? localStorage.getItem("rememberMe")
      : "";

  const handleShowPassword = (isShow) => {
    setShowPassword(isShow);
  };

  return (
    <>
      <NavBar />
      <div className="myMain">
        <Formik
          initialValues={{
            email: initialEmail,
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email is invalid!")
              .required("Email is required"),
            password: Yup.string()
              .min(8, "Password must be at least 8 characters")
              .required("Password is required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              let dataToSubmit = {
                email: values.email,
                password: values.password,
              };
              dispatch(loginUser(dataToSubmit))
                .then((response) => {
                  if (response.payload.loginSuccess) {
                    window.localStorage.setItem(
                      "user",
                      JSON.stringify(response.payload.user)
                    );
                    // var userData = JSON.parse(window.localStorage.getItem('user'));
                    if (rememberMe === true) {
                      window.localStorage.setItem("rememberMe", values.id);
                    } else {
                      localStorage.removeItem("rememberMe");
                    }
                    props.history.push("/devicelist");
                  } else if (response.payload.message === "not verified") {
                    props.history.push({
                      pathname: "/verificationrequiredpage",
                      state: {
                        email: dataToSubmit["email"],
                      },
                    });
                  } else {
                    setFormErrorMessage(
                      "Your email or password is incorrect, please try again."
                    );
                  }
                })
                .catch((err) => {
                  setFormErrorMessage(
                    "Check out your email and password again"
                  );
                  setTimeout(() => {
                    setFormErrorMessage("");
                  }, 3000);
                });
              setSubmitting(false);
            }, 500);
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <>
                <div className="loginpage">
                  <div className="mainlogin">
                    <div className="rect" style={{}}></div>
                    <Title
                      level={2}
                      style={{
                        paddingTop: "30px",
                        paddingLeft: "30px",
                        fontSize: "36px",
                      }}
                    >
                      Login
                    </Title>
                    <form onSubmit={handleSubmit} style={{ padding: "30px" }}>
                      {formErrorMessage && (
                        <label>
                          <p
                            style={{
                              color: "#EB5757",
                              fontSize: "14px",
                              marginTop: "-10px",
                            }}
                          >
                            {formErrorMessage}
                          </p>
                        </label>
                      )}
                      <Form.Item required>
                        <label style={{ color: "#333333", fontWeight: "bold" }}>
                          {" "}
                          Email
                        </label>
                        <Input
                          id="email"
                          prefix={
                            <FaUser style={{ color: "rgba(0,0,0,.25)" }} />
                          }
                          placeholder="Enter your email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ borderRadius: "4px" }}
                          className={
                            errors.email && touched.email
                              ? "text-input error"
                              : "text-input"
                          }
                        />
                        {errors.email && touched.email && (
                          <div className="input-feedback">{errors.email}</div>
                        )}
                      </Form.Item>
                      <Form.Item required>
                        <label style={{ color: "#333333", fontWeight: "bold" }}>
                          Password
                        </label>
                        <Input
                          id="password"
                          prefix={
                            <FaLock style={{ color: "rgba(0,0,0,.25)" }} />
                          }
                          placeholder="Enter your password"
                          type={showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ borderRadius: "4px" }}
                          className={
                            errors.password && touched.password
                              ? "text-input error"
                              : "text-input"
                          }
                          suffix={
                            showPassword ? (
                              <Icon
                                onClick={() => {
                                  handleShowPassword(false);
                                }}
                                icon={eyeIcon}
                                width="19"
                              />
                            ) : (
                              <Icon
                                onClick={() => {
                                  handleShowPassword(true);
                                }}
                                icon={eyeOff}
                                width="19"
                              />
                            )
                          }
                        />
                        {errors.password && touched.password && (
                          <div className="input-feedback">
                            {errors.password}
                          </div>
                        )}
                      </Form.Item>
                      <br />

                      <Form.Item>
                        <Checkbox
                          id="rememberMe"
                          onChange={handleRememberMe}
                          checked={rememberMe}
                        >
                          Remember me
                        </Checkbox>
                        <div>
                          <Button
                            htmlType="submit"
                            className="login-form-button"
                            style={{
                              minWidth: "100%",
                              marginTop: "15px",
                              backgroundColor: "#F47721",
                              borderRadius: "4px",
                              color: "white",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                            disabled={isSubmitting}
                            onSubmit={handleSubmit}
                          >
                            Log in
                          </Button>
                        </div>
                      </Form.Item>
                      <Form.Item>
                        <a
                          className="login-form-forgot"
                          href="/forgotpassword"
                          style={{
                            color: "#F47721",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          forgot password
                        </a>

                        <div
                          className="rect"
                          style={{ margin: "30px 0px" }}
                        ></div>
                        <label
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          Don't have an account?
                        </label>
                        <a
                          style={{
                            color: "#F47721",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          href="/signup"
                        >
                          Sign up now!
                        </a>
                      </Form.Item>
                    </form>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
      <FooterBar />
    </>
  );
}

export default withRouter(SigninPage);
