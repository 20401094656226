import React, { useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { USER_SERVER } from "../../Config";
import FooterBar from "../FooterBar";
import NavBar from "../NavBar";

function VerifychangedemailPage(props) {
  const [verifyError, setVerifyError] = useState("");
  let search = window.location.search;
  let _params = new URLSearchParams(search);
  let uu = _params.get("uu");

  const handleClick = () => {
    setVerifyError("");
    axios
      .get(`${USER_SERVER}/verifychangedemail`, { params: { uu } })
      .then((data) => {
        if (data.data.success) {
          let users = JSON.parse(window.localStorage.getItem("user"));
          if (users) {
            var user = data.data.user;
            window.localStorage.removeItem("user");
            window.localStorage.setItem("user", JSON.stringify(user));
            props.history.push("/myaccount");
          } else {
            props.history.push("/signin");
          }
        } else {
          setVerifyError(data.data.msg);
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  return (
    <>
      <NavBar />
      {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
      <div className="myMain">
        <div
          style={{
            width: "600px",
            margin: "auto",
            paddingTop: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            alt="logo"
            src="/logo.svg"
            style={{ width: "216px", height: "40px" }}
          />
        </div>
        <div className="showPanel">
          <div style={{ height: "4px", backgroundColor: "#E0E0E0" }}></div>
          <div style={{ margin: "0px 30px 40px 30px" }}>
            <h1>New email verification</h1>
            {verifyError && <p style={{ color: "red" }}>{verifyError}</p>}
            <p>
              You have requested to change your email address.
              <br />
              Press the button below to verify your new email address. Thank
              you.
            </p>
            <div style={{ margin: "30px 0px 30px 0px" }}>
              <Button className="normalButton" onClick={handleClick}>
                Verify new email
              </Button>
            </div>
          </div>
        </div>
      </div>
      <FooterBar />
    </>
  );
}

export default VerifychangedemailPage;
