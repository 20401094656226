import React, { useRef, useEffect, useState } from "react";
import { CanvasModal } from "./CanvasModal";

export const DectCanvas = (props) => {
  const canvasRef = useRef(null);
  const [showImageModal, setShowImageModal] = useState(false);
  useEffect(() => {
    updateCanvas();
    // eslint-disable-next-line
  }, []);

  const updateCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = props.filename;
    let arrBox = props.box.split(",");
    let arrClass = props.class.split(",");
    let chunksArray = [];
    for (let i = 0; i < arrBox.length; i += 4) {
      chunksArray.push(arrBox.slice(i, i + 4));
    }
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 1;
      for (let item of chunksArray) {
        ctx.beginPath();

        let randomColor =
          "#" +
          (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
        ctx.strokeStyle = randomColor;
        let index = chunksArray.indexOf(item);
        let xPos = (item[0] * 5) / 24; //640:480=133:100
        xPos = xPos.toFixed();
        let yPos = (item[1] * 5) / 24;
        yPos = yPos.toFixed();
        let xLen = ((item[2] - item[0]) * 5) / 24;
        xLen = xLen.toFixed();
        let yLen = ((item[3] - item[1]) * 5) / 24;
        yLen = yLen.toFixed();
        ctx.rect(xPos, yPos, xLen, yLen);
        ctx.fillStyle = randomColor;
        ctx.fillText(arrClass[index], xPos, yPos - 2);
        ctx.stroke();
      }
    };
  };

  const onClose = () => {
    setShowImageModal(false);
  };

  return (
    <>
      {showImageModal ? (
        <CanvasModal
          filename={props.filename}
          class={props.class}
          box={props.box}
          confidence={props.confidence}
          createdAt={props.createdAt}
          event={props.event}
          onClose={onClose}
          deviceModel={props.deviceModel}
        />
      ) : null}
      <canvas
        width={133.3}
        height={100}
        ref={canvasRef}
        onClick={() => {
          setShowImageModal(true);
        }}
      />
    </>
  );
};
