import React from "react";
import { withRouter } from "react-router-dom";

function RightMenu(props) {
  // const logoutHandler = () => {
  //   axios.get(`${USER_SERVER}/logout`).then(response => {
  //     if (response.status === 200) {
  //       window.localStorage.removeItem('user')
  //       props.history.push("/signin");
  //     } else {
  //       alert('Log Out Failed')
  //     }
  //   });
  // };

  const signinHandler = () => {
    props.history.push("/signin");
  };

  const signupHandler = () => {
    props.history.push("/signup");
  };

  return (
    <div>
      <button className="signupbutton" onClick={signupHandler}>
        Sign up
      </button>
      <button className="signinbutton" onClick={signinHandler}>
        Log in
      </button>
    </div>
  );
}

export default withRouter(RightMenu);
