import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { MdSettings, MdMap, MdDevicesOther } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { Nav } from "react-bootstrap";
import axios from "axios";
import { USER_SERVER } from "../../Config";

function MenuBar(props) {
  let user = JSON.parse(window.localStorage.getItem("user"));
  if (user == null) {
    user = {firstname:"", lastname:""}
  }
  const [count, setCount] = useState(0);
  const logoutHandler = () => {
    setCount(count + 1);

    axios.get(`${USER_SERVER}/logout`).then((response) => {
      if (response.status === 200) {
        window.localStorage.removeItem("user");
        props.history.push("/signin");
      } else {
        alert("Log Out Failed");
      }
    });
  };

  return (
    <div
      className="col-sm-2 flex-column menu_Bar"
      style={{ padding: "0px", display: "flex" }}
    >
      <Nav defaultActiveKey="/devicelist" className="menubar flex-column">
        <div style={{ padding: "14px 0px 14px 5px" }}>
          <span style={{ color: "#333", fontWeight: "bold" }}>
            {user.firstName} {user.lastName}
          </span>
          <br />
          <span>{user.email}</span>
        </div>
        <div className="seperator"></div>
        {user.role === 1 ? null : (
          <>
            <Nav.Link href="/adminarea">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.01172 6C6.41016 6 6.75 6.15234 7.03125 6.45703C7.33594 6.73828 7.48828 7.08984 
                        7.48828 7.51172C7.48828 7.91016 7.33594 8.26172 7.03125 8.56641C6.75 8.84766 6.39844 8.98828 
                        5.97656 8.98828C5.57812 8.98828 5.22656 8.84766 4.92188 8.56641C4.64062 8.26172 4.5 7.91016 4.5
                        7.51172C4.5 7.08984 4.64062 6.73828 4.92188 6.45703C5.22656 6.15234 5.58984 6 6.01172 6ZM9 
                        12.0117H2.98828V11.2383C2.98828 10.793 3.36328 10.418 4.11328 10.1133C4.74609 9.87891 
                        5.36719 9.76172 5.97656 9.76172C6.60938 9.76172 7.24219 9.87891 7.875 10.1133C8.625 10.418
                        9 10.793 9 11.2383V12.0117ZM15.0117 5.26172H10.5117V6.73828H15.0117V5.26172ZM15.0117
                        8.25H10.5117V9.76172H15.0117V8.25ZM15.0117 11.2383H10.5117V12.75H15.0117V11.2383ZM16.4883 
                        2.23828H10.5117V3.75H16.4883V14.2617H1.51172V3.75H7.48828V2.23828H1.51172C1.08984 2.23828 
                        0.726562 2.39062 0.421875 2.69531C0.140625 2.97656 0 3.32812 0 3.75V14.2617C0 14.6602 0.140625 
                        15 0.421875 15.2812C0.726562 15.5859 1.08984 15.7383 1.51172 15.7383H16.4883C16.9102 15.7383 
                        17.2617 15.5859 17.543 15.2812C17.8477 15 18 14.6602 18 14.2617V3.75C18 3.32812 17.8477 
                        2.97656 17.543 2.69531C17.2617 2.39062 16.9102 2.23828 16.4883 2.23828ZM9.73828 
                        3.75H8.26172V0.761719H9.73828V3.75Z"
                  fill="#333333"
                />
              </svg>
              Admin area
            </Nav.Link>
            <div className="seperator"></div>
          </>
        )}
        <Nav.Link href="/devicelist">
          <MdDevicesOther />
          Devices list
        </Nav.Link>
        <Nav.Link href="/devicesmap">
          <MdMap />
          Devices map
        </Nav.Link>
        <div className="seperator"></div>
        <Nav.Link href="/myaccount">
          <FaUserCircle />
          My account
        </Nav.Link>
        <Nav.Link href="/settings">
          <MdSettings />
          Settings
        </Nav.Link>
        <div className="seperator"></div>
        <Nav.Link onClick={logoutHandler}>
          <RiLogoutBoxRLine />
          Log out
        </Nav.Link>
      </Nav>
      <div
        style={{
          bottom: "0px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          paddingBottom: "20px",
        }}
      >
        <div
          className="bottomMenuLinks"
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <a href="/home">Home</a>•<a href="/product">Product</a>•
          <a href="/about">About us</a>•<a href="/contactus">Contact us</a>•
          <a href="/faq">FAQ</a>•<a href="/term">Term of service</a>•
          <a href="/privacypolicy">Privacy policy</a>
        </div>
        <span style={{ color: "#828282" }}>© 2020 Filzer</span>
      </div>
    </div>
  );
}

export default withRouter(MenuBar);
