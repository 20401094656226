export const eventDescriptions = {
    A1: {
        web: "Web “Close Trap” Button Pressed – trap closed",
        auto: "Device Touch Sensor Pressed – trap closed",
        physical: "PCB Button Pressed – trap closed",
    },
    A2: {
        web: "Web “Close Trap” Button Pressed – trap closed",
        auto: "Device Touch Sensor Pressed – trap closed",
        physical: "Device Button Pressed – trap closed",
    },
    B1: {
        web: "Web “Activate Trap” Button Pressed – trap activated",
        auto: "IR Sensor activated – trap activated",
        physical: "Device Button Pressed – trap closed",
    },
    F1: {
        web: "Web “Close Trap” button pressed",
        auto: "Touch sensor pressed",
        physical: "Device button pressed",
    },
    F2: {
        web: "Web “Close Trap” button pressed",
        auto: "Touch sensor pressed",
        physical: "Device button pressed",
    },

    // default events
    photo: "“Take Picture” Button pressed",
    hot: "Too hot",
    schedule: "Scheduled Picture Taken",

    // new default events
    scheduled_picture: "Scheduled Picture Taken",
    pir: "PIR triggered",
    trap_closed: "Trap closed",
    trap_armed: "Trap armed",
    mouse_entered_trap: "Mouse entered trap",
    door_open: "Excluder door opened",
    door_closed: "Excluder door closed",
    caught_mouse: "Caught mouse",
    check_for_bugs: "Check for bugs",
}

