import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Toast,
  Col,
  Container,
  Row,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";

import axios from "axios";
import { SCHEDULE_SERVER } from "../../Config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Switch from "react-switch";
import { BsPlus } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { getWs, sendJsonByWebSocket } from "../../../common";

let newStringTime, editDefaultStringTime, editDefaultInterval;
let MAX_LENGTH = 3;

export const Schedule = (props) => {
  const [arrSchedules, setArrSchedules] = useState(undefined);
  const [toastText, setToastText] = useState("");
  const [addDisabled, setAddDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showDeleteOneModal, setShowDeleteOneModal] = useState(false);
  const [showDeleteSelectedModal, setShowDeleteSelectedModal] = useState(false);
  const [theSchedule, setTheSchedule] = useState(undefined);
  const [showToast, setShowToast] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const [showNewModal, setShowNewModal] = useState(false);
  const [newSchName, setNewSchName] = useState("");
  const [newSchInterval, setNewSchInterval] = useState(0);
  const [newSchCount, setNewSchCount] = useState(0);
  const [newDateTime, setNewDateTime] = useState(undefined);
  const [isNewNeverRepeat, setIsNewNeverRepeat] = useState("never");
  const [newRepeatArr, setNewRepeatArr] = useState([]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editSchName, setEditSchName] = useState("");
  const [editSchInterval, setEditSchInterval] = useState(0);
  const [editSchCount, setEditSchCount] = useState(0);
  const [editDateTime, setEditDateTime] = useState(undefined);
  const [isEditNeverRepeat, setIsEditNeverRepeat] = useState("never");
  const [editRepeatArr, setEditRepeatArr] = useState([]);

  useEffect(() => {
    getSchedulesByDeviceID();
    // eslint-disable-next-line
  }, []);

  const getSchedulesByDeviceID = () => {
    axios
      .post(`${SCHEDULE_SERVER}/getschedules`, {
        deviceid: props.deviceId,
      })
      .then((data) => {
        if (data.data.success) {
          setArrSchedules(data.data.schedules);
          data.data.schedules.length >= MAX_LENGTH
            ? setAddDisabled(true)
            : setAddDisabled(false);
        } else {
          setArrSchedules([]);
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const openAddModal = () => {
    setErrorMessage("");
    setShowNewModal(false);
    setNewSchName("");
    setNewSchInterval(0);
    setNewSchCount(0);
    setNewRepeatArr([]);
    setShowNewModal(true);
  };

  const onClickDeleteOne = () => {
    axios
      .post(`${SCHEDULE_SERVER}/deleteschedule`, {
        id: theSchedule.id,
      })
      .then((data) => {
        if (data.data.success) {
          setShowToast(true);
          setToastText("Schedule deleted successfully.");
          setTheSchedule(undefined);
          getSchedulesByDeviceID();
          setShowDeleteOneModal(false);
          sendSocketMessage();
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onClickDeleteSelected = () => {
    let id = [];
    for (let i in selectedList) {
      id.push(selectedList[i].id);
    }

    axios
      .post(`${SCHEDULE_SERVER}/deleteschedule`, {
        id,
      })
      .then((data) => {
        if (data.data.success) {
          setShowToast(true);
          setToastText("Selected schedules deleted successfully.");
          getSchedulesByDeviceID();
          setShowDeleteSelectedModal(false);
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const selectRow = {
    mode: "checkbox",
    bgColor: "#EAF6FC",
    style: { backgroundColor: "red" },
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedList((selectedList) => {
        if (isSelect) {
          return [...selectedList, row];
        } else {
          let k = selectedList.findIndex((temp) => temp.id === row.id);
          return [
            ...selectedList.slice(0, k),
            ...selectedList.slice(k + 1, selectedList.length),
          ];
        }
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      setSelectedList((selectedList) => {
        if (isSelect) {
          return [...arrSchedules];
        } else {
          return [];
        }
      });
    },
  };

  const pnoptions = {
    hideSizePerPage: true,
  };

  const startAtFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (cell !== undefined) {
      let newD = new Date(cell);
      /*let hour =
        newD.getHours() > 9
          ? newD.getHours()
          : "0" + newD.getHours().toString();
      let min =
        newD.getMinutes() > 9
          ? newD.getMinutes()
          : "0" + newD.getMinutes().toString();
      return (
        <div>
          {hour}:{min}
        </div>
      );*/
      let time = newD.toLocaleString([], {hour: '2-digit', minute:'2-digit'});
      return (
        <div>
          {time}
        </div>
      );
    } else {
      return "";
    }
  };

  const intervalFormatter = (cell, row, rowIndex, formatExtraData) => {
    let ret = cell / 60 < 60 ? cell / 60 + " mins" : cell / 3600 + " hours";
    return <div>{ret}</div>;
  };

  const repeatFormatter = (cell, row, rowIndex, formatExtraData) => {
    let ret = [];
    let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let arr = cell.split(",");
    arr.splice(-1);
    for (let i = 0; i < days.length; i++) {
      if (cell.includes(i)) ret.push(<span key={i}>{days[i]} </span>);
    }
    return <div>{ret}</div>;
  };

  const countFormatter = (cell, row, rowIndex, formatExtraData) => {
    let ret = "";
    if (cell > 0) {
      ret = cell === 1 ? cell + " interval" : cell + " intervals";
    } else {
      ret = "Never";
    }
    return <div>{ret}</div>;
  };

  const enabledFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Switch
        checked={cell}
        onColor="#F47721"
        disabled={!props.own}
        onChange={() => {
          axios
            .post(`${SCHEDULE_SERVER}/enableschedule`, {
              id: row.id,
            })
            .then((data) => {
              if (data.data.success) {
                sendSocketMessage();
                getSchedulesByDeviceID();
              } else {
                console.log("unsuccess");
              }
            })
            .catch(() => {
              console.log("Oops, request failed!");
            });
        }}
      />
    );
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="actionFormat">
        <Button
          className="normalButton actionBtn"
          style={{ borderRadius: "4px 0px 0px 4px" }}
          disabled={!props.own}
          onClick={() => {
            onClickEditBtn(row);
          }}
        >
          <MdEdit />
        </Button>

        <Button
          variant="outline-secondary"
          className="actionBtn"
          disabled={!props.own}
          style={{ borderRadius: "0px 4px 4px 0px" }}
          onClick={() => {
            setShowDeleteOneModal(true);
            setTheSchedule(row);
          }}
        >
          <FaTrash />
        </Button>
      </div>
    );
  };

  const columns = [
    {
      dataField: "schName",
      text: "Name",
      headerStyle: { color: "#F47721", cursor: "pointer" },
      sort: true,
    },
    {
      dataField: "schStart",
      text: "Start at",
      formatter: startAtFormatter,
    },
    {
      dataField: "schInterval",
      text: "Interval",
      formatter: intervalFormatter,
    },
    {
      dataField: "schRepeat",
      text: "Repeat",
      formatter: repeatFormatter,
    },
    {
      dataField: "schCount",
      text: "End after",
      formatter: countFormatter,
    },
    {
      dataField: "schEnabled",
      text: "Enable",
      formatter: enabledFormatter,
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  const sendSocketMessage = () => {
    var json = {
      message: "createAlarm",
    };
    sendJsonByWebSocket(getWs(), props.deviceId, json);
  };

  //Add new start------------------
  const onAddNewSchedule = () => {
    setErrorMessage("");
    if (
      newDateTime === undefined ||
      newSchName === "" ||
      (newSchInterval === "0" &&
        isNewNeverRepeat === "after" &&
        newSchCount < 1)
    ) {
      setErrorMessage("Please input all fiels correctly.");
      return;
    }

    if (newSchInterval * newSchCount > 86399) {
      //if repeat for more than 24 hours
      setErrorMessage("Repeat over 24hrs. Please input vailid.");
      return;
    }

    newRepeatArr.sort((a, b) => {
      return a - b;
    });
    let newRepeatStr = "";
    for (let i = 0; i < newRepeatArr.length; i++) {
      newRepeatStr += newRepeatArr[i] + ",";
    }
    axios
      .post(`${SCHEDULE_SERVER}/addschedule`, {
        schname: newSchName,
        schstart: newDateTime,
        schinterval: newSchInterval,
        schcount: newSchCount,
        schrepeat: newRepeatStr,
        deviceid: props.deviceId,
      })
      .then((data) => {
        if (data.data.success) {
          sendSocketMessage();
          setShowNewModal(false);
          getSchedulesByDeviceID();
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onNewChangeTime = (e) => {
    let hour = e.target.value.substr(0, 2);
    let min = e.target.value.substr(3, 2);
    let newD = new Date();
    newD.setHours(parseInt(hour));
    newD.setMinutes(parseInt(min));
    newD.setSeconds(0);
    setNewDateTime(newD);
  };

  const onChangeNewEndAfterRadio = (e) => {
    setIsNewNeverRepeat(e.target.value);
  };

  const onChangeNewRepeatArr = (e) => {
    setNewRepeatArr(e);
  };
  //Add new end ------------------

  //Edit start ------------------
  const onClickEditBtn = (row) => {
    setErrorMessage("");
    setTheSchedule(row);
    setEditSchName(row.schName);

    //default value for start at
    let tempD = new Date(row.schStart);
    //editDefaultStringTime = tempD.getHours() + ":" + tempD.getMinutes();
    //editDefaultStringTime = tempD.toLocaleString([], {hour: '2-digit', minute:'2-digit'});
    editDefaultStringTime = tempD.toLocaleString([], {hour: '2-digit', minute:'2-digit', hour12: false});
    editDefaultInterval = row.schInterval;
    setEditDateTime(row.schStart);
    //default value for schrepeat
    let tempA = row.schRepeat.split(",");
    tempA.splice(-1);
    let ret = [];
    for (let i = 0; i < tempA.length; i++) {
      ret.push(parseInt(tempA[i]));
    }
    setEditRepeatArr(ret);
    if (row.schCount === 0) {
      setIsEditNeverRepeat("never");
      setEditSchCount(row.schCount);
    } else {
      setIsEditNeverRepeat("after");
      setEditSchCount(row.schCount);
    }
    setShowEditModal(true);

    //console.log("SCHSTART = " + row.schstart);
    console.log(row.schStart);
    //console.log("COUNT = " + row.schcount);
    console.log(row.schCount);
  };

  const onEditChangeTime = (e) => {
    let hour = e.target.value.substr(0, 2);
    let min = e.target.value.substr(3, 2);
    let newD = new Date();
    newD.setHours(parseInt(hour));
    newD.setMinutes(parseInt(min));
    newD.setSeconds(0);
    setEditDateTime(newD);
  };

  const onChangeEditEndAfterRadio = (e) => {
    setIsEditNeverRepeat(e.target.value);
  };

  const onChangeEditRepeatArr = (e) => {
    setEditRepeatArr(e);
  };

  const onUpdateSchedule = () => {
    setErrorMessage("");
    if (
      editDateTime === undefined ||
      editSchName === "" ||
      (editSchInterval === 0 &&
        isEditNeverRepeat === "after" &&
        editSchCount < 1)
    ) {
      setErrorMessage("Please input all fiels correctly.");
      return;
    }

    if (editSchInterval * editSchCount > 86399) {
      //if repeat for more than 24 hours
      setErrorMessage("Repeat over 24hrs. Please input vailid.");
      return;
    }

    editRepeatArr.sort((a, b) => {
      return a - b;
    });
    let editRepeatStr = "";
    for (let i = 0; i < editRepeatArr.length; i++) {
      editRepeatStr += editRepeatArr[i] + ",";
    }
    axios
      .post(`${SCHEDULE_SERVER}/updateschedule`, {
        schname: editSchName,
        schstart: editDateTime,
        schinterval: editSchInterval,
        schcount: editSchCount,
        schrepeat: editRepeatStr,
        id: theSchedule.id,
      })
      .then((data) => {
        if (data.data.success) {
          sendSocketMessage();
          setShowEditModal(false);
          getSchedulesByDeviceID();
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  //Edit end ------------------

  return (
    <div className="sub_content">
      <div className="sub_title">Scheduled pictures (max: 3)</div>
      <div className="sub_body"></div>
      <Toast
        delay={3000}
        autohide
        show={showToast}
        onClose={() => {
          setShowToast(false);
        }}
        style={{
          position: "fixed",
          top: 10,
          right: 10,
          paddingLeft: 20,
          zIndex: 8,
          width: 400,
          borderTop: "4px solid #F47721",
        }}
      >
        <Toast.Body>{toastText}</Toast.Body>
      </Toast>
      {props.own && (
        <Form.Group>
          <Button
            variant="outline-dark"
            className="controlBtn"
            disabled={selectedList.length < 1 ? "disabled" : ""}
            onClick={() => {
              setShowDeleteSelectedModal(true);
            }}
          >
            <FaTrash style={{ marginRight: "6px" }} />
            Delete selected
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            disabled={addDisabled}
            className="normalButton"
            onClick={() => {
              openAddModal();
            }}
          >
            <BsPlus style={{ marginRight: "6px" }} />
            Add new schedule
          </Button>
        </Form.Group>
      )}
      {arrSchedules !== undefined && (
        <BootstrapTable
          keyField="id"
          data={arrSchedules}
          columns={columns}
          bordered={false}
          striped={true}
          selectRow={selectRow}
          pagination={paginationFactory(pnoptions)}
        />
      )}
      {theSchedule !== undefined && (
        <Modal //Delete one modal...
          show={showDeleteOneModal}
          onHide={() => {
            setShowDeleteOneModal(false);
            setTheSchedule(undefined);
          }}
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title>Delete schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <p>
                  Are you sure want to delete schedule "{theSchedule.schName}"?
                </p>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Button
                  className="normalButton"
                  onClick={() => {
                    onClickDeleteOne();
                  }}
                >
                  Yes
                </Button>
                <Button
                  className="cancelButton"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setShowDeleteOneModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      )}
      <Modal //Delete selected...
        show={showDeleteSelectedModal}
        onHide={() => {
          setShowDeleteSelectedModal(false);
        }}
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Delete schedules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <p>Are you sure want to delete selected schedules?</p>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Button
                className="normalButton"
                onClick={() => {
                  onClickDeleteSelected();
                }}
              >
                Yes
              </Button>
              <Button
                className="cancelButton"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setShowDeleteSelectedModal(false);
                }}
              >
                Cancel
              </Button>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal //Add new Modal...
        show={showNewModal}
        onHide={() => {
          setShowNewModal(false);
        }}
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Add new schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {errorMessage && (
              <label>
                <p
                  style={{
                    color: "#EB5757",
                    fontSize: "14px",
                    marginTop: "-10px",
                  }}
                >
                  {errorMessage}
                </p>
              </label>
            )}
            <Form.Group>
              <Form.Label>Schedule name</Form.Label>
              <Form.Control
                value={newSchName}
                onChange={(e) => {
                  setNewSchName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Start at</Form.Label>
              <Form.Control
                type="time"
                value={newStringTime}
                name="newDateTime"
                onChange={(e) => {
                  onNewChangeTime(e);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Interval</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setNewSchInterval(parseInt(e.target.value));
                }}
              >
                <option value="0">No repeat</option>
                <option value="60">1min</option>
                <option value="120">2min</option>
                <option value="300">5min</option>
                <option value="600">10min</option>
                <option value="900">15min</option>
                <option value="1200">20min</option>
                <option value="1800">30min</option>
                <option value="3600">1hr</option>
                <option value="7200">2hr</option>
                <option value="14400">4hr</option>
                <option value="21600">6hr</option>
                <option value="43200">12hr</option>
                <option value="86400">24hr</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <ToggleButtonGroup
                type="checkbox"
                value={newRepeatArr}
                onChange={(e) => onChangeNewRepeatArr(e)}
              >
                <ToggleButton value={0} variant="light" className="buttonWeek">
                  Sun
                </ToggleButton>
                <ToggleButton value={1} variant="light" className="buttonWeek">
                  Mon
                </ToggleButton>
                <ToggleButton value={2} variant="light" className="buttonWeek">
                  Tue
                </ToggleButton>
                <ToggleButton value={3} variant="light" className="buttonWeek">
                  Wed
                </ToggleButton>
                <ToggleButton value={4} variant="light" className="buttonWeek">
                  Tue
                </ToggleButton>
                <ToggleButton value={5} variant="light" className="buttonWeek">
                  Fri
                </ToggleButton>
                <ToggleButton value={6} variant="light" className="buttonWeek">
                  Sat
                </ToggleButton>
              </ToggleButtonGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>End after</Form.Label>
              <Form>
                <Row>
                  <Col sm={4}>
                    <Form.Check
                      type="radio"
                      label="Never"
                      checked={isNewNeverRepeat === "never"}
                      name="formHorizontalRadios"
                      id="formHorizontalRadios1"
                      value="never"
                      disabled={newSchInterval === 0 ? "disabled" : ""}
                      onChange={(e) => {
                        onChangeNewEndAfterRadio(e);
                      }}
                    />
                    <Form.Check
                      type="radio"
                      label="After"
                      checked={isNewNeverRepeat === "after"}
                      name="formHorizontalRadios"
                      id="formHorizontalRadios2"
                      value="after"
                      disabled={
                        newSchInterval === 0 || newSchInterval === 86400
                          ? "disabled"
                          : ""
                      }
                      onChange={(e) => {
                        onChangeNewEndAfterRadio(e);
                      }}
                    />
                  </Col>
                  <Col style={{ marginTop: "10px" }}>
                    <Row>
                      <Col>
                        <Form.Control
                          value={newSchCount}
                          type="number"
                          disabled={
                            isNewNeverRepeat === "never" ? "disabled" : ""
                          }
                          min="1"
                          onChange={(e) => {
                            setNewSchCount(e.target.value);
                          }}
                        />
                      </Col>
                      <Col style={{ paddingLeft: "0px", marginTop: "6px" }}>
                        Intervals
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Form.Group>
          </Container>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: "none", justifyContent: "flex-start" }}
        >
          <Button
            className="normalButton"
            onClick={() => {
              onAddNewSchedule();
            }}
          >
            Add
          </Button>
          <Button
            className="cancelButton"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setShowNewModal(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {theSchedule !== undefined && (
        <Modal //Edit Modal...
          show={showEditModal}
          onHide={() => {
            setShowEditModal(false);
          }}
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title>Edit schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {errorMessage && (
                <label>
                  <p
                    style={{
                      color: "#EB5757",
                      fontSize: "14px",
                      marginTop: "-10px",
                    }}
                  >
                    {errorMessage}
                  </p>
                </label>
              )}
              <Form.Group>
                <Form.Label>Schedule name</Form.Label>
                <Form.Control
                  value={editSchName}
                  onChange={(e) => {
                    setEditSchName(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Start at</Form.Label>
                <Form.Control
                  type="time"
                  defaultValue={editDefaultStringTime}
                  //value={editDefaultStringTime}
                  name="newDateTime"
                  onChange={(e) => {
                    onEditChangeTime(e);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Interval</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={editDefaultInterval}
                  onChange={(e) => {
                    setEditSchInterval(parseInt(e.target.value));
                  }}
                >
                  <option value="0">No repeat</option>
                  <option value="60">1min</option>
                  <option value="120">2min</option>
                  <option value="300">5min</option>
                  <option value="600">10min</option>
                  <option value="900">15min</option>
                  <option value="1200">20min</option>
                  <option value="1800">30min</option>
                  <option value="3600">1hr</option>
                  <option value="7200">2hr</option>
                  <option value="14400">4hr</option>
                  <option value="21600">6hr</option>
                  <option value="43200">12hr</option>
                  <option value="86400">24hr</option>
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <ToggleButtonGroup
                  type="checkbox"
                  value={editRepeatArr}
                  onChange={(e) => onChangeEditRepeatArr(e)}
                >
                  <ToggleButton
                    value={0}
                    variant="light"
                    className="buttonWeek"
                  >
                    Sun
                  </ToggleButton>
                  <ToggleButton
                    value={1}
                    variant="light"
                    className="buttonWeek"
                  >
                    Mon
                  </ToggleButton>
                  <ToggleButton
                    value={2}
                    variant="light"
                    className="buttonWeek"
                  >
                    Tue
                  </ToggleButton>
                  <ToggleButton
                    value={3}
                    variant="light"
                    className="buttonWeek"
                  >
                    Wed
                  </ToggleButton>
                  <ToggleButton
                    value={4}
                    variant="light"
                    className="buttonWeek"
                  >
                    Tue
                  </ToggleButton>
                  <ToggleButton
                    value={5}
                    variant="light"
                    className="buttonWeek"
                  >
                    Fri
                  </ToggleButton>
                  <ToggleButton
                    value={6}
                    variant="light"
                    className="buttonWeek"
                  >
                    Sat
                  </ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label>End after</Form.Label>
                <Form>
                  <Row>
                    <Col sm={4}>
                      <Form.Check
                        type="radio"
                        label="Never"
                        checked={isEditNeverRepeat === "never"}
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                        value="never"
                        //disabled={editSchInterval === 0 ? false : true}
                        onChange={(e) => {
                          onChangeEditEndAfterRadio(e);
                        }}
                      />
                      <Form.Check
                        type="radio"
                        label="After"
                        checked={isEditNeverRepeat === "after"}
                        name="formHorizontalRadios"
                        id="formHorizontalRadios2"
                        value="after"
                        //disabled={
                        //  editSchInterval === 0 || editSchInterval === 86400
                        //    ? "disabled"
                        //    : ""
                        //}
                        onChange={(e) => {
                          onChangeEditEndAfterRadio(e);
                        }}
                      />
                    </Col>
                    <Col style={{ marginTop: "10px" }}>
                      <Row>
                        <Col>
                          <Form.Control
                            value={editSchCount}
                            type="number"
                            disabled={
                              isEditNeverRepeat === "never" ? "disabled" : ""
                            }
                            min="1"
                            onChange={(e) => {
                              setEditSchCount(e.target.value);
                            }}
                          />
                        </Col>
                        <Col style={{ paddingLeft: "0px", marginTop: "6px" }}>
                          Intervals
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Form.Group>
            </Container>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "none", justifyContent: "flex-start" }}
          >
            <Button
              className="normalButton"
              onClick={() => {
                onUpdateSchedule();
              }}
            >
              Update
            </Button>
            <Button
              className="cancelButton"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setShowEditModal(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};