import React from "react";
import { Link } from "react-router-dom";

function FooterBar(props) {
  return (
    <div className="footer">
      <div className="footer_menus">
        <Link to="/home">Home</Link>
        <Link to="/product">Product</Link>
        <Link to="/about">About us</Link>
        <Link to="/contactus">Contact us</Link>
        <Link to="/term">Term of service</Link>
        <Link to="/privacypolicy">Privacy policy</Link>
        <Link to="/faq">FAQ</Link>
      </div>
      <div className="footer_copyright">
        © Copyright 2012-2020 Filzer. All rights reserved.
      </div>
    </div>
  );
}

export default FooterBar;
