import React, { useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { USER_SERVER } from "../../Config";
import FooterBar from "../FooterBar";
import NavBar from "../NavBar";

function VerifyemailPage(props) {
  const [verifyError, setVerifyError] = useState("");

  let search = window.location.search;
  let _params = new URLSearchParams(search);
  let uu = _params.get("uu");

  const handleClick = () => {
    setVerifyError("");
    axios
      .get(`${USER_SERVER}/verifyemail`, { params: { uu } })
      .then((data) => {
        if (data.data.success) {
          props.history.push("/signin");
        } else {
          setVerifyError(data.data.msg);
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };
  return (
    <>
      <NavBar />
      {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
      <div className="myMain">
        <div style={{ width: "600px", margin: "auto", paddingTop: "30px" }}>
          <img
            alt="logo"
            src="/logo.svg"
            style={{ width: "216px", height: "40px", marginLeft: "192px" }}
          />
        </div>
        <div
          style={{
            width: "600px",
            backgroundColor: "#FFF",
            margin: "auto",
            marginTop: "30px",
            paddingBottom: "1px",
          }}
        >
          <div style={{ height: "4px", backgroundColor: "#E0E0E0" }}></div>
          <div style={{ margin: "0px 30px 40px 30px" }}>
            <h1>Email verification</h1>
            {verifyError && <p style={{ color: "red" }}>{verifyError}</p>}
            <p>
              To verify email address, Press on the button.
              <br />
              Thank you.
            </p>
            <div style={{ margin: "30px 0px 30px 0px" }}>
              <Button className="normalButton" onClick={handleClick}>
                Verify email
              </Button>
            </div>
          </div>
        </div>
      </div>
      <FooterBar />
    </>
  );
}

export default VerifyemailPage;
