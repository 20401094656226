import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Button,
  Toast,
  Container,
  Row,
  Modal,
  Badge,
} from "react-bootstrap";
import EditDevice from "./EditDevice";
import { MdEdit, MdShare, MdClose } from "react-icons/md";
import {
  FaTrash,
  FaSortAmountDownAlt,
  FaEyeSlash,
  FaEye,
} from "react-icons/fa";
import { FiWifi, FiWifiOff } from "react-icons/fi";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import { DEVICE_SERVER } from "../../Config";
import { SHAREDDEVICE_SERVER } from "../../Config";

import { useHistory } from "react-router-dom";

import ReactShowMoreLess from "./ReactShowMoreLess";
import { deviceTypes } from "../../DeviceTypes";

import ShareDeviceModal from "./ShareDeviceModal";

export const Sharedbyme = (props) => {
  let user = JSON.parse(window.localStorage.getItem("user"));
  let history = useHistory();

  const [sharedBymeDeviceList, setSharedBymeDeviceList] = useState([]);
  const [sharedBymeDeviceListForTable, setSharedBymeDeviceListForTable] =
    useState([]);

  const [showEditDeviceModal, setShowEditDeviceModal] = useState(false);
  const [selectedDevicesArray, setSelectedDevicesArray] = useState([]);
  const [shareSelectedEmail, setShareSelectedEmail] = useState("");
  const [showShareSelectedModal, setShowShareSelectedModal] = useState(false);
  const [theDevice, setTheDevice] = useState(undefined);
  const [showDeleteSelectedModal, setShowDeleteSelectedModal] = useState(false);

  //share in list
  // const [shareDevice, setShareDevice] = useState(undefined);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareEmail, setShareEmail] = useState("");
  const [shareDeviceError, setShareDeviceError] = useState("");
  const [shareDeviceSuccess, setShareDeviceSuccess] = useState("");
  const [sharedDeviceList, setSharedDeviceList] = useState(undefined);

  /////// For Toast show
  const [showToast, setShowToast] = useState(false);
  const [deletedLength, setDeletedLength] = useState(0);
  const [sortField, setSortField] = useState("devid");
  const [sortValue, setSortValue] = useState("asc");

  const [showDeleteOneModal, setShowDeleteOneModal] = useState(false);

  //For disabled buttons
  let disabledSelected = selectedDevicesArray.length < 1 ? true : false;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth > 981 ? false : true);
    getSharedBymeDeviceList();
    // eslint-disable-next-line
  }, []);

  const openSharedDeviceModal = () => {
    setShowShareModal(true);
  };

  const closeSharedDeviceModal = () => {
    setShowShareModal(false);
  };

  const getSharedBymeDeviceList = () => {
    axios
      .post(`${DEVICE_SERVER}/getsharedbymedevice`, {
        owner: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          //let tempDeviceList = data.data.devices;
          let sharedDevicesData = data.data.devices;
          let tempDeviceObj = {};
          sharedDevicesData.forEach((sharedRelation) => {
            if (tempDeviceObj[sharedRelation.deviceId] === undefined) {
              tempDeviceObj[sharedRelation.deviceId] = sharedRelation.device;
              tempDeviceObj[sharedRelation.deviceId].sharedWithCount = 1;
            } else {
              tempDeviceObj[sharedRelation.deviceId].sharedWithCount += 1;
            }
          });
          let tempDeviceList = Object.values(tempDeviceObj);
          for (let i in tempDeviceList) {
            if (props.onlineStatus[tempDeviceList[i].id] !== undefined)
              tempDeviceList[i].deviceStatus =
                props.onlineStatus[tempDeviceList[i].id];
          }

          if (sortField === "devid" && sortValue === "asc")
            tempDeviceList.sort(
              (a, b) => parseFloat(a.deviceCode) - parseFloat(b.deviceCode)
            );
          else if (sortField === "devid" && sortValue === "desc")
            tempDeviceList.sort(
              (a, b) => parseFloat(b.deviceCode) - parseFloat(a.deviceCode)
            );
          else if (sortField === "activity" && sortValue === "asc")
            tempDeviceList.sort((a, b) => a._count.trapEventNotifications - b._count.trapEventNotifications);
          else if (sortField === "activity" && sortValue === "desc")
            tempDeviceList.sort((a, b) => b._count.trapEventNotifications - a._count.trapEventNotifications);
          else if (sortField === "devicename" && sortValue === "asc")
            tempDeviceList.sort((a, b) =>
              ("" + a.deviceName).localeCompare(b.deviceName)
            );
          else if (sortField === "devicename" && sortValue === "desc")
            tempDeviceList.sort((a, b) =>
              ("" + b.deviceName).localeCompare(a.deviceName)
            );
          setSharedBymeDeviceList(tempDeviceList);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch((e) => {
        //console.log("Oops, request failed!");
        console.log(e);
      });
  };

  useEffect(() => {
    makeListForTable();
    // eslint-disable-next-line
  }, [props.onlineStatus, sharedBymeDeviceList]);

  useEffect(() => {
    getSharedBymeDeviceList();
    // eslint-disable-next-line
  }, [sortField, sortValue]);

  const makeListForTable = () => {
    let tempListForTable = JSON.parse(JSON.stringify(sharedBymeDeviceList));
    for (let i in sharedBymeDeviceList) {
      if (props.onlineStatus[tempListForTable[i].id] !== undefined)
        tempListForTable[i].deviceStatus =
          props.onlineStatus[tempListForTable[i].id];
    }
    setSharedBymeDeviceListForTable(tempListForTable);
  };

  const onClickShowEditDeviceModal = () => {
    setShowEditDeviceModal((showEditDeviceModal) => !showEditDeviceModal);
  };

  //Update device list after Edit List
  const updateEditedDeviceList = () => {
    getSharedBymeDeviceList();
  };

  const onClickDeleteSeleted = () => {
    selectedDevicesArray.forEach((row) => {
      axios
        .post(`${DEVICE_SERVER}/removeonedevice`, {
          deviceid: row.deviceid,
          owner: row.owner,
        })
        .then((data) => {
          if (data.data.success) {
            getSharedBymeDeviceList();
            setDeletedLength(selectedDevicesArray.length);
            setShowToast(true);
            setShowDeleteSelectedModal(false);
          } else {
            console.log("unsuccess");
          }
        })
        .catch(() => {
          console.log("Oops, request failed!");
        });
    });
    props.updateAllDeviceListLength(selectedDevicesArray.length);
    setSelectedDevicesArray([]);
  };

  const onCloseShareSelectedModal = () => {
    setShareSelectedEmail("");
    setShowShareSelectedModal(false);
  };

  const handleShareSelectedEmailChange = (e) => {
    setShareSelectedEmail(e.target.value);
  };

  const onClickShareSeleted = () => {
    setShowShareSelectedModal(true);
  };

  const onShareSeleted = () => {
    selectedDevicesArray.forEach((row) => {
      axios
        .post(`${SHAREDDEVICE_SERVER}/addshareddevice`, {
          deviceid: row.id,
          owner: row.ownerId,
          email: shareSelectedEmail,
        })
        .then((data) => {
          if (data.data.success) {
            // setShareDeviceSuccess(data.data.message);
            console.log("Success");
            getSharedBymeDeviceList();
          } else {
            // setShareDeviceError(data.data.message);
            console.log("Unsuccess");
          }
        })
        .catch(() => {
          console.log("Oops, request failed!");
        });
    });
    getSharedBymeDeviceList();
    setSelectedDevicesArray([]);
    props.updateAllDeviceListLength();
  };

  const onClickDeleteOne = () => {
    axios
      .post(`${DEVICE_SERVER}/removeonedevice`, {
        deviceid: theDevice.id,
      })
      .then((data) => {
        if (data.data.success) {
          setDeletedLength(1);
          setShowToast(true);
          getSharedBymeDeviceList();
          setShowDeleteOneModal(false);
          props.updateAllDeviceListLength();
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  ////////////////////////////////////////----------Table Config--------/////////////////////////////////////////////////
  const idHeaderFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        ID <FaSortAmountDownAlt />
      </div>
    );
  };

  function StatusHelper(props) {
    let status = props.currentStatus;

    if (status === "online") {
      return (
        <FiWifi style={{ color: "#27AE60", width: "16px", height: "16px" }} />
      );
    } else if (status === "offline") {
      return (
        <FiWifiOff
          style={{ color: "#BDBDBD", width: "16px", height: "16px" }}
        />
      );
    }

  }

  function ActivityHelper(props) {
    let activity = props.currentActivity;

    if (activity === null) {
      return (
        <Badge
          variant="secondary"
          style={{
            fontSize: "18px",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
        >
          0
        </Badge>
      );
    } else if (activity > 0) {
      return (
        <Badge variant="danger" style={{ fontSize: "18px" }}>
          {activity}
        </Badge>
      );
    }
  }

  const idFormatter = (cell, row, rowIndex, formatExtraData) => {
    let divBorder = row.showOnMap ? "1px solid #27AE60" : "1px solid #BDBDBD";
    let divBackgroundColor = row.showOnMap ? "#27AE60" : "white";
    let divColor = row.showOnMap ? "white" : "black";
    let model;
    model = `${deviceTypes[row.model]} (${row.model})`;

    let shortVersion = (
      <Row>
        <Col xs={8}>
          <p
            style={{
              textAlign: "left",
              marginBottom: 0,
            }}
          >
            <strong>Model: </strong>{model}<br/>
            <strong>Name: </strong>{row.deviceName}<br/>
          </p>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={6}
              style={{
                paddingInline: 0,
                textAlign: "end",
              }}
            >
              <ActivityHelper currentActivity={row._count.trapEventNotifications}/>
            </Col>
            <Col xs={6}
              style={{
                paddingInline: "8px",
                textAlign: "start",
              }}
            >
              <StatusHelper currentStatus={row.deviceStatus}/>
            </Col>
          </Row>
          <Row
            style={{
              marginRight: "-5px",
              marginLeft: "-28px",
              marginTop: "2px",
            }}
          >
            <Col xs={12}>
              <p>
                <strong>ID: </strong>{row.deviceCode}<br/>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    let fullVersion = (
      <Row>
        <Col xs={8}>
          <p
            style={{
              textAlign: "left",
              marginBottom: 0,
            }}
          >
            <strong>Model: </strong>{model}<br/>
            <strong>Name: </strong>{row.deviceName}<br/>
            <strong>Owner: </strong>{row.firstName} {row.lastName}<br/>
            <strong>Address: </strong>{row.address}<br/>
            <strong>Position: </strong>{row.position}<br/>
            <strong>Shared with: </strong>{row.sharedWithCount} {(row.devcount > 1 || row.devcount == 0) ? "users" : "user"}<br/>
          </p>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={6}
              style={{
                paddingInline: 0,
                textAlign: "end",
              }}
            >
              <ActivityHelper currentActivity={row._count.trapEventNotifications}/>
            </Col>
            <Col xs={6}
              style={{
                paddingInline: "8px",
                textAlign: "start",
              }}
            >
              <StatusHelper currentStatus={row.deviceStatus}/>
            </Col>
          </Row>
          <Row
            style={{
              marginRight: "-5px",
              marginLeft: "-28px",
              marginTop: "2px",
            }}
          >
            <Col xs={12}>
              <p>
                <strong>ID: </strong>{row.deviceCode}<br/>
              </p>
            </Col>
          </Row>
          <Row>
            <Col 
              xs={6}
              style={{
                paddingRight: "2px",
              }}
            >
              <Button
                className="actionBtn"
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  borderRadius: "4px",
                  border: divBorder,
                  background: divBackgroundColor,
                  color: divColor,
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  axios
                  .post(`${DEVICE_SERVER}/setshowonmapbydeviceid`, {
                    deviceid: row.id,
                    owner: row.ownerId,
                  })
                  .then((data) => {
                    if (data.data.success) {
                      getSharedBymeDeviceList();
                    } else {
                      console.log("unsuccess");
                    }
                  })
                  .catch(() => {
                    console.log("Oops, request failed!");
                  });
                }}
              >
                {row.showOnMap ? <FaEye /> : <FaEyeSlash color="disabled" />}
              </Button>
            </Col>
            <Col
              xs={6}
              style={{
                paddingLeft: "2px",
              }}
            >
              <Button 
                variant="outline-secondary"
                className="actionBtn"
                style={{
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTheDevice(row);
                  setShowDeleteOneModal(true);
                }}
              >
                <FaTrash />
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "4px",
            }}
          >
            <Col 
              xs={6}
              style={{
                paddingRight: "2px",
              }}
            >
              <Button
                className="normalButton actionBtn"
                style={{
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTheDevice(row);
                  onClickShowEditDeviceModal();
                }}
              >
                <MdEdit />
              </Button>
            </Col>
            <Col
              xs={6}
              style={{
                paddingLeft: "2px",
              }}
            >
              <Button
                variant="outline-secondary"
                className="actionBtn"
                style={{
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTheDevice(row);
                  //getModalSharedDeviceList(row.id, row.ownerId);
                  setShowShareModal(true);
                }}
              >
                <MdShare />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    if (isMobile) {
      return (
        <ReactShowMoreLess
          shortVersion={shortVersion}
          fullVersion={fullVersion}
          readMoreText={"Show more ▼"}
          readLessText={"Show less ▲"}
          readMoreClassName="read-more-less--more"
          readLessClassName="read-more-less--less"
        />
      );
    } else {
      return (
        <>
          <span>{row.deviceCode}</span>
        </>
      );
    }
  };

  const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.deviceName}</span> }
      </>
    );
  };

  const addressFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.address}</span> }
      </>
    );
  };

  const positionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.position}</span> }
      </>
    );
  };

  const modelFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      let model;
      model = `${deviceTypes[cell]} (${cell})`;
      return (
        <span>{model}</span>
      );
    } else {
      return null;
    }
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      if (cell === "online") {
        return (
          <FiWifi style={{ color: "#27AE60", width: "16px", height: "16px" }} />
        );
      } else if (cell === "offline") {
        return (
          <FiWifiOff
            style={{ color: "#BDBDBD", width: "16px", height: "16px" }}
          />
        );
      }
    } else {
      return null;
    }
  };

  const activityFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      if (cell === null) {
        return (
          <Badge
            variant="secondary"
            style={{
              fontSize: "18px",
              color: "#333333",
              backgroundColor: "#E0E0E0",
            }}
          >
            0
          </Badge>
        );
      } else if (cell > 0) {
        return (
          <Badge variant="danger" style={{ fontSize: "18px" }}>
            {cell}
          </Badge>
        );
      }
    } else {
      return null;
    }
  };

  const sharedwithFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{cell} {(cell > 1 || cell == 0) ? "users" : "user"}</span> }
      </>
    );
  };

  const showonmapFormatter = (cell, row, rowIndex, formatExtraData) => {
    let divBorder = cell ? "1px solid #27AE60" : "1px solid #BDBDBD";
    let divBackgroundColor = cell ? "#27AE60" : "white";
    let divColor = cell ? "white" : "black";

    if (!isMobile) {
      return (
        <>
          <Button
            style={{
              textAlign: "center",
              cursor: "pointer",
              lineHeight: "normal",
              borderRadius: "4px",
              border: divBorder,
              background: divBackgroundColor,
              color: divColor,
            }}
          >
            {cell ? <FaEye /> : <FaEyeSlash color="disabled" />}
          </Button>
        </>
      );
    } else {
      return null;
    }
  };

  const actFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      return (
        <div className="actionFormat">
          <Button
            className="normalButton actionBtn"
            style={{ borderRadius: "4px 0px 0px 4px" }}
            onClick={() => {
              setTheDevice(row);
              onClickShowEditDeviceModal();
            }}
          >
            <MdEdit />
          </Button>
          <Button
            variant="outline-secondary"
            className="actionBtn"
            style={{
              borderLeft: "none",
              borderRight: "none",
              borderRadius: "0px",
            }}
            onClick={() => {
              setTheDevice(row);
              //getModalSharedDeviceList(row.id, row.ownerId);
              setShowShareModal(true);
            }}
          >
            <MdShare />
          </Button>
          <Button
            variant="outline-secondary"
            className="actionBtn"
            style={{ borderRadius: "0px 4px 4px 0px" }}
            onClick={() => {
              setTheDevice(row);
              setShowDeleteOneModal(true);
            }}
          >
            <FaTrash />
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  const columns = [
    {
      dataField: "deviceStatus",
      text: "Status",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      style: { cursor: "pointer" },
      formatter: statusFormatter,
      //classes: "tenWidth",
      classes: (isMobile ? null : "tenWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.id,
            // search: '?devid=' + row.deviceid,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "_count.trapEventNotifications",
      text: "Activity",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      style: { cursor: "pointer" },
      formatter: activityFormatter,
      //classes: "tenWidth",
      classes: (isMobile ? null : "tenWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "deviceCode",
      text: "ID",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", width: "calc(100% - 35px)" } : { cursor: "pointer" }),
      headerFormatter: idHeaderFormatter,
      formatter: idFormatter,
      //classes: "idWidth",
      classes: (isMobile ? null : "idWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "model",
      text: "Model",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: modelFormatter,
      //classes: "modelWidth",
      classes: (isMobile ? null : "modelWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "deviceName",
      text: "Device name",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: nameFormatter,
      classes: "fullWidth nameWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: addressFormatter,
      classes: "fullWidth addressWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "sharedWithCount",
      text: "Shared with",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: sharedwithFormatter,
      classes: "halfWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          //getModalSharedDeviceList(row.deviceid, row.owner);
          setTheDevice(row);
          setShowShareModal(true);
        },
      },
    },
    {
      dataField: "position",
      text: "Position",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: positionFormatter,
      classes: "halfWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
            },
          });
        },
      },
    },
    {
      dataField: "showOnMap",
      text: "On map",
      formatter: showonmapFormatter,
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      //classes: "mapWidth",
      classes: (isMobile ? "realHalfWidth" : "mapWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          axios
            .post(`${DEVICE_SERVER}/setshowonmapbydeviceid`, {
              deviceid: row.id,
              owner: row.ownerId,
            })
            .then((data) => {
              if (data.data.success) {
                getSharedBymeDeviceList();
              } else {
                console.log("unsuccess");
              }
            })
            .catch(() => {
              console.log("Oops, request failed!");
            });
        },
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: actFormatter,
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
    },
  ];

  const selectRow = {
    mode: "checkbox",
    bgColor: "#EAF6FC",
    style: { backgroundColor: "red" },
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedDevicesArray((selectedDevicesArray) => {
        if (isSelect) {
          return [...selectedDevicesArray, row];
        } else {
          let k = selectedDevicesArray.findIndex((temp) => temp.id === row.id);
          return [
            ...selectedDevicesArray.slice(0, k),
            ...selectedDevicesArray.slice(k + 1, selectedDevicesArray.length),
          ];
        }
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      setSelectedDevicesArray((selectedDevicesArray) => {
        if (isSelect) {
          return [...sharedBymeDeviceListForTable];
        } else {
          return [];
        }
      });
    },
  };

  const pnoptions = {
    hideSizePerPage: true,
  };

  const onCloseToast = () => {
    setShowToast(false);
    setDeletedLength(0);
  };

  const onCloseShareModal = () => {
    setShareDeviceError("");
    setShareDeviceSuccess("");
    setShareEmail("");
    setShowShareModal(false);
    // setShareDevice(undefined);
  };

  const onClickShare = () => {
    setShareDeviceError("");
    setShareDeviceSuccess("");
    axios
      .post(`${SHAREDDEVICE_SERVER}/addshareddevice`, {
        deviceid: sharedDeviceList[0].id,
        owner: sharedDeviceList[0].ownerId,
        email: shareEmail,
      })
      .then((data) => {
        if (data.data.success) {
          getModalSharedDeviceList(
            sharedDeviceList[0].id,
            sharedDeviceList[0].ownerId
          );
          getSharedBymeDeviceList();
          setShareDeviceSuccess(data.data.message);
          props.updateSharedBymeDeviceListLength(); //refresh sharedbyme tag number
        } else {
          setShareDeviceError(data.data.message);
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const handleShareEmailChange = (e) => {
    setShareEmail(e.target.value);
  };

  const createSharedList = () => {
    let items = [];
    for (let i in sharedDeviceList) {
      let shared = sharedDeviceList[i];
      //let isPending = shared.sharedto > 0 ? "" : "Pending";
      const sharedDeviceStatuses = {
        "accept": "Accepted",
        "pending": "Pending",
        "decline": "",
        "block": "",
        "notallow": "",
      };
      let sharedStatus = sharedDeviceStatuses[shared.status];
      let nameValue;
      if (shared.sharedWithUser != undefined && shared.sharedWithUser != null) {
        if (shared.sharedWithUser.firstName === null && shared.sharedWithUser.lastName === null)
          nameValue = shared.sharedWithUserEmail;
        else if (shared.sharedWithUser.firstName === null) nameValue = shared.sharedWithUser.lastName;
        else if (shared.sharedWithUser.lastName === null) nameValue = shared.sharedWithUser.firstName;
        else nameValue = `${shared.sharedWithUser.firstName} ${shared.sharedWithUser.lastName}`;
      }
      /*if (shared.sharedWithUser.firstName === null && shared.sharedWithUser.lastName === null)
        nameValue = shared.sharedWithUserEmail;
      else if (shared.sharedWithUser.firstName === null) nameValue = shared.sharedWithUser.lastName;
      else if (shared.sharedWithUser.lastName === null) nameValue = shared.sharedWithUser.firstName;*/
      items.push(
        <Row
          key={i}
          className="sharemodallist"
          style={{ marginTop: "10px", paddingBottom: "10px" }}
        >
          <Col xs={6}>{nameValue}</Col>
          <Col xs={3}>{sharedStatus}</Col>
          <Col xs={3} className="devdetailright">
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => onClickRemove(shared.deviceId, shared.sharedWithUserId)}
            >
              <MdClose /> Remove
            </Button>
          </Col>
        </Row>
      );
    }
    return items;
  };

  const onClickRemove = (deviceid, sharedto, owner) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/removebydeviceidsharedto`, {
        deviceid,
        sharedto,
      })
      .then((data) => {
        if (data.data.success) {
          getModalSharedDeviceList(deviceid, owner); //refresh modal
          getSharedBymeDeviceList(); //refresh sharedbyme
          props.updateSharedBymeDeviceListLength(); //refresh sharedbyme tag number
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getModalSharedDeviceList = (deviceid, owner) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/getbydeviceidowner`, {
        deviceid,
        owner,
      })
      .then((data) => {
        if (data.data.success) {
          setSharedDeviceList(data.data.shareddevices);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onClickRemoveAll = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/removebydeviceidall`, {
        deviceid: sharedDeviceList[0].deviceid,
        owner: sharedDeviceList[0].owner,
      })
      .then((data) => {
        if (data.data.success) {
          setShowShareModal(false);
          getSharedBymeDeviceList(); //refresh sharedbyme
          props.updateSharedBymeDeviceListLength(); //refresh sharedbyme tag number
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onChangeSortField = (e) => {
    setSortField(e.target.value);
  };

  const onChangeSortVaule = (e) => {
    setSortValue(e.target.value);
  };

  return (
    <div className="sub_content">
      <Toast
        delay={3000}
        autohide
        show={showToast}
        onClose={onCloseToast}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          paddingLeft: 20,
          zIndex: 8,
          width: 400,
          borderTop: "4px solid #F47721",
        }}
      >
        <Toast.Body>{deletedLength} devices deleted.</Toast.Body>
      </Toast>
      <div className="sub_title">Shared by me</div>
      <div className="sub_body">
        <Form.Group>
          <Button
            variant="outline-dark"
            className="controlBtn"
            disabled={disabledSelected}
            onClick={onClickShareSeleted}
            style={{ marginRight: "10px" }}
          >
            <MdShare
              style={{ marginRight: "6px", width: "16px", height: "16px" }}
            />
            Share selected
          </Button>
          <Button
            variant="outline-dark"
            className="controlBtn"
            disabled={disabledSelected}
            onClick={() => {
              setShowDeleteSelectedModal(true);
            }}
          >
            <FaTrash style={{ marginRight: "6px" }} />
            Delete selected
          </Button>
        </Form.Group>
        <Modal show={showShareSelectedModal} onHide={onCloseShareSelectedModal}>
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title>
              Shared {selectedDevicesArray.length}device
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col sm={9} className="">
                  <Form.Control
                    placeholder="Enter email address"
                    value={shareSelectedEmail}
                    onChange={handleShareSelectedEmailChange}
                  />
                </Col>
                <Col sm={3} className="devdetailright">
                  <Button className="normalButton" onClick={onShareSeleted}>
                    Share
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>

        <Form.Group className="sortArea">
          <Row>
            <Col
              xs={2}
              style={{
                textAlign: "right",
                padding: "8px 0px",
              }}
            >
              <Form.Label>Sort by </Form.Label>
            </Col>
            <Col xs={5}>
              <Form.Control
                onChange={(e) => {
                  onChangeSortField(e);
                }}
                as="select"
              >
                <option value="devid">ID</option>
                <option value="activity">Activity</option>
                <option value="devicename">Device name</option>
              </Form.Control>
            </Col>
            <Col xs={5}>
              <Form.Control
                onChange={(e) => {
                  onChangeSortVaule(e);
                }}
                as="select"
              >
                <option value="asc">ascending</option>
                <option value="desc">descending</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>

        <BootstrapTable
          keyField="id"
          classes="tableWithMobile"
          data={sharedBymeDeviceListForTable}
          columns={columns}
          bordered={false}
          striped={true}
          selectRow={selectRow}
          pagination={paginationFactory(pnoptions)}
        />
        {theDevice === undefined ? null : (
          <EditDevice
            show={showEditDeviceModal}
            editdevice={theDevice}
            onClickShowEditDeviceModal={onClickShowEditDeviceModal}
            updateEditedDeviceList={updateEditedDeviceList}
          />
        )}

        {/*sharedDeviceList === undefined ? null : (
          <Modal show={showShareModal} onHide={onCloseShareModal}>
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>Shared device</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {shareDeviceError && (
                <label>
                  <p
                    style={{
                      color: "#EB5757",
                      fontSize: "14px",
                      marginTop: "-10px",
                    }}
                  >
                    {shareDeviceError}
                  </p>
                </label>
              )}
              {shareDeviceSuccess && (
                <label>
                  <p
                    style={{
                      color: "green",
                      fontSize: "14px",
                      marginTop: "-10px",
                    }}
                  >
                    {shareDeviceSuccess}
                  </p>
                </label>
              )}
              <Container>
                <Row>
                  <Col sm={9} className="sharedEmailInput">
                    <Form.Control
                      placeholder="Enter email address"
                      value={shareEmail}
                      onChange={handleShareEmailChange}
                    />
                  </Col>
                  <Col sm={3} className="sharedShareBtn">
                    <Button className="normalButton" onClick={onClickShare}>
                      Share
                    </Button>
                  </Col>
                </Row>
                <Row
                  className="sharemodallistheader"
                  style={{ marginTop: "20px" }}
                >
                  <Col sm={6} className="sharedUser">
                    Shared with
                  </Col>
                  <Col sm={3} className="sharedRemove">
                    Status
                  </Col>
                  <Col xs={3}></Col>
                </Row>
                {createSharedList()}
              </Container>
            </Modal.Body>
            <Modal.Footer
              style={{ borderTop: "none", justifyContent: "flex-end" }}
            >
              <Col sm={4} className="sharedRemove">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => {
                    onClickRemoveAll();
                  }}
                >
                  <MdClose /> Remove all
                </Button>
              </Col>
            </Modal.Footer>
          </Modal>
        )*/}

        {theDevice === undefined ? null : (
        <ShareDeviceModal
        show={showShareModal}
        onOpen={openSharedDeviceModal}
        onClose={closeSharedDeviceModal}
        deviceId={theDevice.id}
        ownerId={theDevice.ownerId}
        />
        )}

        {theDevice === undefined ? null : (
          <Modal
            show={showDeleteOneModal}
            onHide={() => {
              setShowDeleteOneModal(false);
            }}
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>Delete device</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <p>
                    Are you sure want to delete device {theDevice.deviceCode} -{" "}
                    {theDevice.deviceName}?
                  </p>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Button
                    className="normalButton"
                    onClick={() => {
                      onClickDeleteOne();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="cancelButton"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setShowDeleteOneModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        )}

        {!selectedDevicesArray.length ? null : (
          <Modal
            show={showDeleteSelectedModal}
            onHide={() => {
              setShowDeleteSelectedModal(false);
            }}
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>Delete devices</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <p>
                    Are you sure want to delete {selectedDevicesArray.length}{" "}
                    devices?
                  </p>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Button
                    className="normalButton"
                    onClick={() => {
                      onClickDeleteSeleted();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="cancelButton"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setShowDeleteSelectedModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </div>
  );
};