// SERVER ROUTES
export const USER_SERVER = "/api/users";
export const DEVICE_SERVER = "/api/device";
export const TRAPHISTORY_SERVER = "/api/traphistory";
export const THPHISTORY_SERVER = "/api/thphistory";
export const SHAREDDEVICE_SERVER = "/api/shareddevice";
export const SETTING_SERVER = "/api/setting";
export const CONTACTUS_SERVER = "/api/contactus";
export const PRODUCT_SERVER = "/api/product";
export const FILZERDOC_SERVER = "/api/filzerdoc";
export const SCHEDULE_SERVER = "/api/schedule";
export const TIMEZONE_SERVER = "/api/timezone";
export const DEBUG_SERVER = "/api/debugevents";
export const DEVICE_SETTING_SERVER = "/api/devicesetting";
export const DEVICE_TYPE_SERVER = "/api/devicetype";

export const TINYMCE_EDITOR_KEY =
  "hloyhhi64dohhe18ttcotfmgxcxxuefuc5kgd8jpv3v9ohfd"; //Phil's

// export const TINYMCE_EDITOR_KEY =
//   "wrdpsq871u8ghzr7sbgd5umq3l6kiai9ay1ophfhckgzs2ia"; //my key

//export const WS_SERVER_URL = "ws://localhost:65081";
export const WS_SERVER_URL = "wss://filzertech.com:65081";
