import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Toast,
  Col,
  Container,
  Row,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import {
  FaFileAlt,
  FaRegFileAlt,
  FaSignal,
} from "react-icons/fa";
import resetIcon from "@iconify/icons-mdi/reload";
import { Icon } from "@iconify/react";

import axios from "axios";
import { SETTING_SERVER, DEBUG_SERVER, DEVICE_SETTING_SERVER } from "../../Config";
import { getWs, sendJsonByWebSocket } from "../../../common";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DeviceReportModal from "./DeviceReportModal";

export const DebugEvents = (props) => {
  const [deviceInfo, setDeviceInfo] = useState(props.deviceInfo);
  const [isLoggingEnabled, setIsLoggingEnabled] = useState(false);
  const [arrDebugEvents, setArrDebugEvents] = useState(undefined);
  const [userTimezone, setuserTimezone] = useState("America/Halifax");
  const [showDeviceReportModal, setShowDeviceReportModal] = useState(false);

  const logLevelButtons = [
    { name: "Info", value: 1, endpoint: "setloglevelinfo" },
    { name: "Warning", value: 2, endpoint: "setloglevelwarning" },
    { name: "Error", value: 3, endpoint: "setloglevelerror" },
  ];
  const [logLevel, setLogLevel] = useState(new Array(logLevelButtons.length).fill(false));

  const toggleLogLevel = (idx) => {
    axios
    .post(`${DEVICE_SETTING_SERVER}/${logLevelButtons[idx].endpoint}`, {
      deviceId: props.deviceId,
    })
    .then((data) => {
      if (data.data.success) {
        setLogLevel(
          [
            data.data.deviceSetting.logLevelInfo,
            data.data.deviceSetting.logLevelWarning,
            data.data.deviceSetting.logLevelError,
          ]
        );
      } else {
        console.log("Unsuccess");
      }
    })
    .catch(() => {
      console.log("Oops, request failed!");
    });
  };

  const logTypeButtons = [
    { name: "All", value: 1 },
    { name: "Connection", value: 2 },
    { name: "Camera", value: 3 },
    { name: "Error", value: 4 },
  ];
  const [logType, setLogType] = useState(new Array(logTypeButtons.length).fill(false));

  useEffect(() => {
    getUserTimezone();
    getDebugEventsByDeviceID();
    getDeviceSettingsByDeviceID();
    // eslint-disable-next-line
  }, []);

  const getUserTimezone = () => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    axios
      .post(`${SETTING_SERVER}/getorcreatebyuserid`, {
        userId: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          if ("timeZone" in data.data.setting) {
            if (data.data.setting.timeZone != null) {
              setuserTimezone(data.data.setting.timeZone);
            }
          }
        }
      })
  }

  const getDebugEventsByDeviceID = () => {
    axios
      .post(`${DEBUG_SERVER}/getfromdeviceid`, {
        deviceId: props.deviceId,
      })
      .then((data) => {
        if (data.data.success) {
          setArrDebugEvents(data.data.debugEvents);
        } else {
          setArrDebugEvents([]);
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getDeviceSettingsByDeviceID = () => {
    axios
      .post(`${DEVICE_SETTING_SERVER}/getfromdeviceid`, {
        deviceId: props.deviceId,
      })
      .then((data) => {
        if (data.data.success) {
          //setArrDebugEvents(data.data.debugEvents);
          setIsLoggingEnabled(data.data.deviceSettings.isDebugLogEnabled);
          setLogLevel(
            [
              data.data.deviceSettings.logLevelInfo,
              data.data.deviceSettings.logLevelWarning,
              data.data.deviceSettings.logLevelError,
            ]
          );
        } else {
          //setArrDebugEvents([]);
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const toggleDebugLogging = () => {
    axios
      .post(`${DEVICE_SETTING_SERVER}/setloggingbydeviceid`, {
        deviceId: deviceInfo.id,
        owner: deviceInfo.ownerId,
      })
      .then((data) => {
        if (data.data.success) {
          /*let temp = JSON.parse(JSON.stringify(deviceInfo));
          temp.showOnMap = !temp.showOnMap;
          setDeviceInfo((deviceInfo) => temp);
          props.updateEditedDeviceList(temp);*/
          setIsLoggingEnabled(data.data.deviceSetting.isDebugLogEnabled);
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const pnoptions = {
    hideSizePerPage: true,
  };

  const getImageDate = (dateTime) => {
    return dateTime.toLocaleString('en-US',
                  {
                  month: 'short', 
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                  timeZone: userTimezone
                });
  };

  const timeFormatter = (cell, row, rowIndex, formatExtraData) => {
    let dbDateTime = new Date(cell);
    let dateTime = getImageDate(dbDateTime);
    return <div>{dateTime}</div>;
  };

  const columns = [
    {
      dataField: "id",
      text: "Log ID",
      sort: true,
    },
    {
      dataField: "eventType.eventName",
      text: "Description",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created at",
      formatter: timeFormatter,
      sort: true,
    }
  ];

  const onClickResetDevice = (e) => {
    let command = "resetDevice";
    var json = {
      message: command,
    };
    sendJsonByWebSocket(getWs(), props.deviceId, json);
    console.log("Reset device clicked");
    getDebugEventsByDeviceID();
  };

  const onClickGetReport = (e) => {
    setShowDeviceReportModal(true);
  };

  const closeDeviceReportModal = () => {
    setShowDeviceReportModal(false);
  };

  return (
    <div className="sub_content">
      <div className="sub_title">System logs</div>
      {!props.own ? null : (
          <>
            <Row style={{ marginTop: "16px" }}>
              <Col xs={2} className="devdetailleft">
                Logging
              </Col>  
              <Col xs={8} className="devdetailright">
                {isLoggingEnabled ? (
                  <Button size="sm" variant="success" onClick={toggleDebugLogging}>
                    <FaFileAlt /> Enabled
                  </Button>
                ) : (
                  <Button size="sm" variant="outline-dark" onClick={toggleDebugLogging}>
                    <FaRegFileAlt /> Disabled
                  </Button>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: "16px" }}>
              <Col xs={2} className="devdetailleft">
                Log level
              </Col>
              <Col xs={8} className="devdetailright">
                <ToggleButtonGroup
                  type="checkbox"
                  name="options"
                  size="sm"
                >
                  {logLevel.map((value, index) => (
                    <ToggleButton
                      key={index}
                      variant="secondary"
                      value={index}
                      active={value}
                      onChange={(e) => toggleLogLevel(index)}
                    >
                      {logLevelButtons[index].name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: "16px" }}>
              <Col xs={2} className="devdetailleft">
                Log type
              </Col>
              <Col xs={8} className="devdetailright">
                <ToggleButtonGroup
                  type="checkbox"
                  name="logType"
                  size="sm"
                >
                  {logTypeButtons.map((button, index) => (
                    <ToggleButton
                      key={index}
                      variant="outline-dark"
                      value={index}
                      checked={logType[index]}
                    >
                      {button.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: "16px" }}>
            <Col xs={2} className="devdetailleft">
              Current status
            </Col>
            <Col
              xs={8}
              className="devdetailright"
              style={{ display: "flex" }}
            >
              <Button
                size="sm"
                style={{
                  backgroundColor: "#F47721",
                  borderColor: "#F47721",
                  color: "white",
                }}
                onClick={onClickGetReport}
              >
                <FaSignal /> Get report
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col xs={2} className="devdetailleft">
              Remote reset
            </Col>
            <Col
              xs={8}
              className="devdetailright"
              style={{ display: "flex" }}
            >
              <Button
                variant="outline-dark"
                className="controlBtns"
                size="sm"
                onClick={onClickResetDevice}
              >
                <Icon icon={resetIcon} width="19" /> Reset device
              </Button>
            </Col>
          </Row>
          <hr style={{ marginTop: "16px" }} />
          </>
      )}
      <div className="sub_body">
        {arrDebugEvents !== undefined && (
          <BootstrapTable
            keyField="id"
            data={arrDebugEvents}
            columns={columns}
            bordered={false}
            striped={true}
            pagination={paginationFactory(pnoptions)}
          />
        )}

        {deviceInfo === undefined ? null : (
          <DeviceReportModal
          show={showDeviceReportModal}
          onClose={closeDeviceReportModal}
          deviceId={deviceInfo.id}
          ownerId={deviceInfo.ownerId}
          socketMsg={props.socketMsg}
          />
        )}
      </div>
    </div>
  );
}