import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Toast,
  Badge,
  Row,
  Col,
  Modal,
  Container,
} from "react-bootstrap";
import { FiWifi, FiWifiOff } from "react-icons/fi";
import {
  FaTrash,
  FaSortAmountDownAlt,
  FaEyeSlash,
  FaEye,
} from "react-icons/fa";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import { SHAREDDEVICE_SERVER } from "../../Config";

import { useHistory } from "react-router-dom";

import ReactShowMoreLess from "./ReactShowMoreLess";
import { deviceTypes } from "../../DeviceTypes";

export const Sharedwithme = (props) => {
  let history = useHistory();
  const [sharedDeviceList, setSharedDeviceList] = useState([]);
  const [sharedDeviceListForTable, setSharedDeviceListForTable] = useState([]);
  const [selectedDevicesArray, setSelectedDevicesArray] = useState([]);

  /////// For Toast show
  const [showToast, setShowToast] = useState(false);
  const [deletedLength, setDeletedLength] = useState(0);
  //const [sortField, setSortField] = useState("devid");
  const [sortField, setSortField] = useState("deff");
  const [sortValue, setSortValue] = useState("asc");

  //For disabled buttons
  let disabledSelected = selectedDevicesArray.length < 1 ? true : false;
  const [theDevice, setTheDevice] = useState(undefined);
  const [showDeleteOne, setShowDeleteOne] = useState(false);
  const [showDeleteSelectedModal, setShowDeleteSelectedModal] = useState(false);
  let user = JSON.parse(window.localStorage.getItem("user"));

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth > 981 ? false : true);
    getSharedDeviceList();
    // eslint-disable-next-line
  }, []);

  const getSharedDeviceList = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/getshareddevicebyto`, {
        sharedto: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          let tempDeviceList = data.data.shareddevices;
          for (let i in tempDeviceList) {
            if (props.onlineStatus[tempDeviceList[i].deviceId] !== undefined)
              tempDeviceList[i].deviceStatus =
                props.onlineStatus[tempDeviceList[i].deviceId];
          }
          if (sortField === "deff" && sortValue === "asc")
            tempDeviceList.sort(
              (a, b) => ((b.deviceStatus == "offline") ? 0 : 1) - ((a.deviceStatus == "offline") ? 0 : 1) || b.device._count.trapEventNotifications - a.device._count.trapEventNotifications 
            );
          else if (sortField === "devid" && sortValue === "asc")
            tempDeviceList.sort(
              (a, b) => parseFloat(a.device.deviceCode) - parseFloat(b.device.deviceCode)
            );
          else if (sortField === "devid" && sortValue === "desc")
            tempDeviceList.sort(
              (a, b) => parseFloat(b.device.deviceCode) - parseFloat(a.device.deviceCode)
            );
          else if (sortField === "activity" && sortValue === "asc")
            tempDeviceList.sort((a, b) => a.device._count.trapEventNotifications - b.device._count.trapEventNotifications);
          else if (sortField === "activity" && sortValue === "desc")
            tempDeviceList.sort((a, b) => b.device._count.trapEventNotifications - a.device._count.trapEventNotifications);
          else if (sortField === "devicename" && sortValue === "asc")
            tempDeviceList.sort((a, b) =>
              ("" + a.device.deviceName).localeCompare(b.device.deviceName)
            );
          else if (sortField === "devicename" && sortValue === "desc")
            tempDeviceList.sort((a, b) =>
              ("" + b.device.deviceName).localeCompare(a.device.deviceName)
            );
          setSharedDeviceList(tempDeviceList);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  useEffect(() => {
    makeSharedDeviceListForTable();
    // eslint-disable-next-line
  }, [sharedDeviceList, props.onlineStatus]);

  useEffect(() => {
    getSharedDeviceList();
    // eslint-disable-next-line
  }, [sortField, sortValue]);

  const makeSharedDeviceListForTable = () => {
    let tempDeviceListForTable = JSON.parse(JSON.stringify(sharedDeviceList));
    for (let i in sharedDeviceList) {
      if (props.onlineStatus[tempDeviceListForTable[i].deviceId] !== undefined)
        tempDeviceListForTable[i].deviceStatus =
          props.onlineStatus[tempDeviceListForTable[i].deviceId];
    }
    setSharedDeviceListForTable(tempDeviceListForTable);
  };

  const onClickDeleteSeleted = () => {
    // let deleted = 0;
    selectedDevicesArray.forEach((row) => {
      axios
        .post(`${SHAREDDEVICE_SERVER}/removebydeviceidto`, {
          deviceid: row.deviceId,
          sharedto: row.sharedto,
        })
        .then((data) => {
          if (data.data.success) {
            //state right stone
            setSharedDeviceList((sharedDeviceList) => {
              let k = sharedDeviceList.findIndex(
                (device) => device.deviceId === row.deviceId
              );
              if (k > -1) {
                return [
                  ...sharedDeviceList.slice(0, k),
                  ...sharedDeviceList.slice(k + 1, sharedDeviceList.length),
                ];
              } else {
                return sharedDeviceList;
              }
            });
            // setDeletedLength(selectedDevicesArray.length);
            // deleted++;
          } else {
            console.log("unsuccess");
          }
        })
        .catch(() => {
          console.log("Oops, request failed!");
        });
    });
    setShowToast(true);
    setShowDeleteSelectedModal(false);
    props.updateSharedDeviceListLength(selectedDevicesArray.length);
    getSharedDeviceList();
    setSelectedDevicesArray([]);
  };

  const onClickDeleteOne = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/removebydeviceidto`, {
        deviceid: theDevice.device.id,
        sharedto: theDevice.sharedWithUserId,
      })
      .then((data) => {
        if (data.data.success) {
          setShowToast(true);
          setDeletedLength(1);
          setShowDeleteOne(false);
          getSharedDeviceList();
          props.updateSharedDeviceListLength(1);
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  ////////////////////////////////////////----------Table Config--------/////////////////////////////////////////////////
  const idHeaderFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        ID <FaSortAmountDownAlt />
      </div>
    );
  };


  function StatusHelper(props) {
    let status = props.currentStatus;

    if (status === "online") {
      return (
        <FiWifi style={{ color: "#27AE60", width: "16px", height: "16px" }} />
      );
    } else if (status === "offline") {
      return (
        <FiWifiOff
          style={{ color: "#BDBDBD", width: "16px", height: "16px" }}
        />
      );
    }

  }

  function ActivityHelper(props) {
    let activity = props.currentActivity;

    if (activity === null) {
      return (
        <Badge
          variant="secondary"
          style={{
            fontSize: "18px",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
        >
          0
        </Badge>
      );
    } else if (activity > 0) {
      return (
        <Badge variant="danger" style={{ fontSize: "18px" }}>
          {activity}
        </Badge>
      );
    }
  }

  const idFormatter = (cell, row, rowIndex, formatExtraData) => {
    let divBorder = row.showonmap ? "1px solid #27AE60" : "1px solid #BDBDBD";
    let divBackgroundColor = row.showonmap ? "#27AE60" : "white";
    let divColor = row.showonmap ? "white" : "black";
    let model;
    model = `${deviceTypes[row.device.model]} (${row.device.model})`;

    let shortVersion = (
      <Row>
        <Col xs={8}>
          <p
            style={{
              textAlign: "left",
              marginBottom: 0,
            }}
          >
            <strong>Model: </strong>{model}<br/>
            <strong>Name: </strong>{row.device.deviceName}<br/>
          </p>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={6}
              style={{
                paddingInline: 0,
                textAlign: "end",
              }}
            >
              <ActivityHelper currentActivity={row.device._count.trapEventNotifications}/>
            </Col>
            <Col xs={6}
              style={{
                paddingInline: "8px",
                textAlign: "start",
              }}
            >
              <StatusHelper currentStatus={row.deviceStatus}/>
            </Col>
          </Row>
          <Row
            style={{
              marginRight: "-5px",
              marginLeft: "-28px",
              marginTop: "2px",
            }}
          >
            <Col xs={12}>
              <p>
                <strong>ID: </strong>{row.device.deviceCode}<br/>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    let fullVersion = (
      <Row>
        <Col xs={8}>
          <p
            style={{
              textAlign: "left",
              marginBottom: 0,
            }}
          >
            <strong>Model: </strong>{model}<br/>
            <strong>Name: </strong>{row.device.deviceName}<br/>
            <strong>Owner: </strong>{row.device.owner.firstname} {row.device.owner.lastName}<br/>
            <strong>Address: </strong>{row.device.address}<br/>
            <strong>Position: </strong>{row.device.position}<br/>
          </p>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={6}
              style={{
                paddingInline: 0,
                textAlign: "end",
              }}
            >
              <ActivityHelper currentActivity={row.device._count.trapEventNotifications}/>
            </Col>
            <Col xs={6}
              style={{
                paddingInline: "8px",
                textAlign: "start",
              }}
            >
              <StatusHelper currentStatus={row.deviceStatus}/>
            </Col>
          </Row>
          <Row
            style={{
              marginRight: "-5px",
              marginLeft: "-28px",
              marginTop: "2px",
            }}
          >
            <Col xs={12}>
              <p>
                <strong>ID: </strong>{row.device.deviceCode}<br/>
              </p>
            </Col>
          </Row>
          <Row>
            <Col 
              xs={6}
              style={{
                paddingRight: "2px",
              }}
            >
              <Button
                className="actionBtn"
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  borderRadius: "4px",
                  border: divBorder,
                  background: divBackgroundColor,
                  color: divColor,
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  axios
                    .post(`${SHAREDDEVICE_SERVER}/setshowonmap`, {
                      deviceid: row.deviceId,
                      sharedto: row.sharedto,
                    })
                    .then((data) => {
                      if (data.data.success) {
                        getSharedDeviceList();
                      } else {
                        console.log("unsuccess");
                      }
                    })
                    .catch(() => {
                      console.log("Oops, request failed!");
                    });
                }}
              >
                {row.showonmap ? <FaEye /> : <FaEyeSlash color="disabled" />}
              </Button>
            </Col>
            <Col
              xs={6}
              style={{
                paddingLeft: "2px",
              }}
            >
              <Button 
                variant="outline-secondary"
                className="actionBtn"
                style={{
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTheDevice(row);
                  setShowDeleteOne(true);
                }}
              >
                <FaTrash />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    if (isMobile) {
      return (
        <ReactShowMoreLess
          shortVersion={shortVersion}
          fullVersion={fullVersion}
          readMoreText={"Show more ▼"}
          readLessText={"Show less ▲"}
          readMoreClassName="read-more-less--more"
          readLessClassName="read-more-less--less"
        />
      );
    } else {
      return (
        <>
          {isMobile ? <span className="fieldName">ID: </span> : null}
          <span>{row.device.deviceCode}</span>
        </>
      );
    }

  };

  const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.device.deviceName}</span> }
      </>
    );
  };

  const addressFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.device.address}</span> }
      </>
    );
  };

  const positionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.device.position}</span> }
      </>
    );
  };

  const modelFormatter = (cell, row, rowIndex, formatExtraData) => {
    let model;
    model = `${deviceTypes[cell]} (${cell})`;
    return (
      <>
        {isMobile ? null : <span>{model}</span> }
      </>
    );
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      if (cell === "online") {
        return (
          <FiWifi style={{ color: "#27AE60", width: "16px", height: "16px" }} />
        );
      } else if (cell === "offline") {
        return (
          <FiWifiOff
            style={{ color: "#BDBDBD", width: "16px", height: "16px" }}
          />
        );
      }
    } else {
      return null;
    }
  };

  const activityFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      /*if (cell === null) {
        return (
          <Badge
            variant="secondary"
            style={{
              fontSize: "18px",
              color: "#333333",
              backgroundColor: "#E0E0E0",
            }}
          >
            0
          </Badge>
        );
      } else if (cell > 0) {
        return (
          <Badge variant="danger" style={{ fontSize: "18px" }}>
            {cell}
          </Badge>
        );
      }*/
      if (cell) {
        return (
          <Badge variant="danger" style={{ fontSize: "18px" }}>
            {cell}
          </Badge>
        );
       } else {
        return (
          <Badge
            variant="secondary"
            style={{
              fontSize: "18px",
              color: "#333333",
              backgroundColor: "#E0E0E0",
            }}
          >
            0
          </Badge>
        );
       }
    } else {
      return null;
    }
  };

  const ownerFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.device.owner.firstName} {row.device.owner.lastName}</span> }
      </>
    );
  };

  const showonmapFormatter = (cell, row, rowIndex, formatExtraData) => {
    let divBorder = cell ? "1px solid #27AE60" : "1px solid #BDBDBD";
    let divBackgroundColor = cell ? "#27AE60" : "white";
    let divColor = cell ? "white" : "black";

    if (!isMobile) {
      return (
        <>
          <Button
            style={{
              textAlign: "center",
              cursor: "pointer",
              lineHeight: "normal",
              borderRadius: "4px",
              border: divBorder,
              background: divBackgroundColor,
              color: divColor,
            }}
          >
            {cell ? <FaEye /> : <FaEyeSlash color="disabled" />}
          </Button>
        </>
      );
    } else {
      return null;
    }
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      return (
        <Button variant="outline-secondary" className="actionBtn">
          <FaTrash />
        </Button>
      );
    } else {
      return null;
    }
  };

  const columns = [
    {
      dataField: "deviceStatus",
      text: "Status",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      style: { cursor: "pointer" },
      formatter: statusFormatter,
      //classes: "tenWidth",
      classes: (isMobile ? null : "tenWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.deviceId,
            state: {
              devid: row.deviceId,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "device._count.trapEventNotifications",
      text: "Activity",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      style: { cursor: "pointer" },
      formatter: activityFormatter,
      //classes: "tenWidth",
      classes: (isMobile ? null : "tenWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.deviceId,
            state: {
              devid: row.deviceId,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "device.deviceCode",
      text: "Device ID",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", width: "calc(100% - 35px)" } : { cursor: "pointer" }),
      headerFormatter: idHeaderFormatter,
      formatter: idFormatter,
      //classes: (isMobile ? "centerWMargin" : "idWidth"),
      classes: (isMobile ? null : "idWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.deviceId,
            state: {
              devid: row.deviceId,
              own: false,
            },
          });
        },
      },
    },
    {
      dataField: "device.model",
      text: "Model",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: modelFormatter,
      //classes: "modelWidth",
      classes: (isMobile ? null : "modelWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.deviceId,
            state: {
              devid: row.deviceId,
              own: false,
            },
          });
        },
      },
    },
    {
      dataField: "device.deviceName",
      text: "Device name",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: nameFormatter,
      classes: "fullWidth nameWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.deviceId,
            state: {
              devid: row.deviceId,
              own: false,
            },
          });
        },
      },
    },
    {
      dataField: "device.address",
      text: "Address",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: addressFormatter,
      classes: "fullWidth addressWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.deviceId,
            state: {
              devid: row.deviceId,
              own: false,
            },
          });
        },
      },
    },
    {
      dataField: "device.position",
      text: "Position",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: positionFormatter,
      classes: "halfWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.deviceId,
            state: {
              devid: row.deviceId,
              own: false,
            },
          });
        },
      },
    },
    {
      dataField: "device.owner",
      text: "Owner",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: ownerFormatter,
      classes: "halfWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/device/" + row.deviceId,
            state: {
              devid: row.deviceId,
              own: false,
            },
          });
        },
      },
    },
    {
      dataField: "device.showOnMap",
      text: "On map",
      formatter: showonmapFormatter,
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      classes: (isMobile ? "realHalfWidth" : "mapWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          axios
            .post(`${SHAREDDEVICE_SERVER}/setshowonmap`, {
              deviceid: row.id,
              sharedto: row.sharedto,
            })
            .then((data) => {
              if (data.data.success) {
                getSharedDeviceList();
              } else {
                console.log("unsuccess");
              }
            })
            .catch(() => {
              console.log("Oops, request failed!");
            });
        },
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      classes: (isMobile ? "realHalfWidth" : null),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setTheDevice(row);
          setShowDeleteOne(true);
        },
      },
    },
  ];

  const selectRow = {
    mode: "checkbox",
    bgColor: "#EAF6FC",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedDevicesArray((selectedDevicesArray) => {
        if (isSelect) {
          return [...selectedDevicesArray, row];
        } else {
          let k = selectedDevicesArray.findIndex((temp) => temp.deviceId === row.deviceId);
          return [
            ...selectedDevicesArray.slice(0, k),
            ...selectedDevicesArray.slice(k + 1, selectedDevicesArray.length),
          ];
        }
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      setSelectedDevicesArray((selectedDevicesArray) => {
        if (isSelect) {
          return [...sharedDeviceList];
        } else {
          return [];
        }
      });
    },
  };

  const pnoptions = {
    hideSizePerPage: true,
  };

  const onCloseToast = () => {
    setShowToast(false);
    setDeletedLength(0);
  };

  const onChangeSortField = (e) => {
    setSortField(e.target.value);
  };

  const onChangeSortVaule = (e) => {
    setSortValue(e.target.value);
  };

  return (
    <div className="sub_content">
      <Toast
        delay={3000}
        autohide
        show={showToast}
        onClose={onCloseToast}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          paddingLeft: 20,
          zIndex: 8,
          width: 400,
          borderTop: "4px solid #F47721",
        }}
      >
        <Toast.Body>
          {deletedLength} {deletedLength < 2 ? "device" : "devices"} deleted.
        </Toast.Body>
      </Toast>
      <div className="sub_title">Share with me</div>
      <div className="sub_body">
        <Form.Group>
          <Button
            variant="outline-dark"
            className="controlBtn"
            disabled={disabledSelected}
            onClick={() => {
              setShowDeleteSelectedModal(true);
            }}
          >
            <FaTrash style={{ marginRight: "6px" }} />
            Delete selected
          </Button>
        </Form.Group>
        <Form.Group className="sortArea">
          <Row>
            <Col
              xs={2}
              style={{
                textAlign: "right",
                padding: "8px 0px",
              }}
            >
              <Form.Label>Sort by </Form.Label>
            </Col>
            <Col xs={5}>
              <Form.Control
                onChange={(e) => {
                  onChangeSortField(e);
                }}
                as="select"
              >
                <option value="devid">ID</option>
                <option value="activity">Activity</option>
                <option value="devicename">Device name</option>
              </Form.Control>
            </Col>
            <Col xs={5}>
              <Form.Control
                onChange={(e) => {
                  onChangeSortVaule(e);
                }}
                as="select"
              >
                <option value="asc">ascending</option>
                <option value="desc">descending</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
        {theDevice === undefined ? null : (
          <Modal
            show={showDeleteOne}
            onHide={() => {
              setShowDeleteOne(false);
            }}
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>Delete device</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <p>
                    Are you sure want to delete device {theDevice.device.deviceCode} -{" "}
                    {theDevice.device.deviceName}?
                  </p>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Button
                    className="normalButton"
                    onClick={() => {
                      onClickDeleteOne();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="cancelButton"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setShowDeleteOne(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        )}
        {!selectedDevicesArray.length ? null : (
          <Modal
            show={showDeleteSelectedModal}
            onHide={() => {
              setShowDeleteSelectedModal(false);
            }}
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>Delete devices</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <p>
                    Are you sure want to delete {selectedDevicesArray.length}{" "}
                    devices?
                  </p>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Button
                    className="normalButton"
                    onClick={() => {
                      onClickDeleteSeleted();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="cancelButton"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setShowDeleteSelectedModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        )}
        <BootstrapTable
          keyField="id"
          classes="tableWithMobile"
          data={sharedDeviceListForTable}
          columns={columns}
          bordered={false}
          striped={true}
          selectRow={selectRow}
          pagination={paginationFactory(pnoptions)}
        />
      </div>
    </div>
  );
};