import React, { useState, useEffect } from "react";
import { Button, Toast, Form, Col } from "react-bootstrap";
import axios from "axios";
import { DEVICE_TYPE_SERVER } from "../../Config";

export const DeviceTypes = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [currentDeviceType, setCurrentDeviceType] = useState({
    id: 0,
    name: "",
    model: "",
    aiPrompt: "",
  });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getDeviceTypes();
    // eslint-disable-next-line
  }, []);

  const getDeviceTypes = () => {
    axios
      .post(`${DEVICE_TYPE_SERVER}/getalldevicetypes`)
      .then(async (data) => {
        if (data.data.success) {
          setDeviceTypes(data.data.deviceTypes);
        } else {
          if (data.data.error.name === "SequelizeUniqueConstraintError")
            setAddErrorMessage("Product name should be unique..");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onUpdateDeviceType = () => {
    axios
      .post(`${DEVICE_TYPE_SERVER}/editdevicetype`, {
          deviceTypeId: currentDeviceType.id,
          name: currentDeviceType.name,
          model: currentDeviceType.model,
          aiPrompt: currentDeviceType.aiPrompt
      })
      .then((data) => {
        if (data.data.success) {
          setToastText("Device type updated successfully.");
          setShowToast(true);
          setCurrentDeviceType(data.data.deviceType);
          setDeviceTypes(
            deviceTypes.map((dt) =>
              dt.id === currentDeviceType.id ? currentDeviceType : dt
            )
          );
        } else {
          console.log("Error updating device type...");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onChangeDeviceType = (e) => {
    // if id not in deviceTypes, set currentDeviceType to empty
    if (deviceTypes.find((dt) => dt.id === parseInt(e)) === undefined) {
      setCurrentDeviceType({ id: 0, name: "", model: "", aiPrompt: "" });
      return;
    } else {
      const deviceType = deviceTypes.find((dt) => dt.id === parseInt(e));
      setCurrentDeviceType(deviceType);
      console.log(deviceType);
    }
  };

  return (
    <>
      <div className="sub_content">
        <div className="sub_title">Device types</div>
        <div className="sub_body">
          <Toast
            delay={3000}
            autohide
            show={showToast}
            onClose={() => setShowToast(false)}
            style={{
              position: "fixed",
              top: 10,
              right: 10,
              paddingLeft: 20,
              zIndex: 8,
              width: 400,
              borderTop: "4px solid #F47721",
            }}
          >
            <Toast.Body>{toastText}</Toast.Body>
          </Toast>

          {addErrorMessage && (
            <label>
              <p
                style={{
                  color: "#EB5757",
                  fontSize: "14px",
                  marginTop: "-10px",
                }}
              >
                {addErrorMessage}
              </p>
            </label>
          )}

          <Form>
            <Form.Row>
              <Form.Group as={Col} sm={6}>
                <Form.Label aria-label="Select device type to edit">
                  <Form.Control 
                    value={currentDeviceType.id}
                    id="deviceTypeSelect"
                    onChange={(e) => {
                      onChangeDeviceType(e.target.value);
                    }}
                    as="select"
                    >
                    <option value={0}>Select device type to edit</option>
                    {deviceTypes.map((dt, i) => <option value={dt.id}>{dt.name} ({dt.model})</option>)}
                  </Form.Control>
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm={6}>
                <Form.Label htmlFor="device-type-name">Device type name</Form.Label>
                <Form.Control
                  id="device-type-name"
                  type="text"
                  aria-label="Device type name"
                  value={currentDeviceType.name}
                  onChange={(e) => {
                    setCurrentDeviceType({ ...currentDeviceType, name: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} sm={6}>
                <Form.Label htmlFor="device-type-model">Device type model</Form.Label>
                <Form.Control
                  id="device-type-model"
                  type="text"
                  aria-label="Device type model"
                  value={currentDeviceType.model}
                  onChange={(e) => {
                    setCurrentDeviceType({ ...currentDeviceType, model: e.target.value });
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm={12}>
                <Form.Label htmlFor="ai-prompt">AI prompt</Form.Label>
                <Form.Control
                  rows={8}
                  id="ai-prompt"
                  as="textarea"
                  aria-label="AI prompt"
                  value={currentDeviceType.aiPrompt}
                  onChange={(e) => {
                    setCurrentDeviceType({ ...currentDeviceType, aiPrompt: e.target.value });
                  }}
                />
              </Form.Group>
            </Form.Row>
          </Form>

          <Button
            className="normalButton"
            type="submit"
            style={{ marginTop: "10px" }}
            onClick={() => {
              onUpdateDeviceType();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};
