import React, { Suspense } from "react";
import Auth from "../hoc/auth";
import { Switch, Route } from "react-router-dom";
import HomePage from "./views/HomePage";
import SigninPage from "./views/SigninPage";
// import FooterBar from './views/FooterBar';
// import NavBar from './views/NavBar';
import SingupPage from "./views/SignupPage";
import { TermPage } from "./views/TermPage";
import { PolicyPage } from "./views/PolicyPage";
import { FaqPage } from "./views/FaqPage";
import { ProductPage } from "./views/ProductPage";
import { ProductDetailPage } from "./views/ProductDetailPage";
import { ContactusPage } from "./views/ContactusPage";
import AboutPage from "./views/AboutPage";
import ForgotpasswordPage from "./views/ForgotpasswordPage";
import MyAccountPage from "./views/MyAccountPage";
import VerifychangedemailPage from "./views/VerifychangedemailPage";
import VerifyemailPage from "./views/VerifyemailPage";
import { ReactivatePage } from "./views/ReactivatePage";
import { ThanksSignupPage } from "./views/ThanksSignupPage";
import { VerificationRequiredPage } from "./views/VerificationRequiredPage";
import DevicelistPage from "./views/DevicelistPage";
import { DevicePage } from "./views/DevicelistPage/DevicePage";
import { NotificationPage } from "./views/NotificationPage";
import { AdminareaPage } from "./views/AdminareaPage";
import { AddProduct } from "./views/AddProduct";
import { EditProduct } from "./views/AdminareaPage/EditProduct";
import { DevicesMapPage } from "./views/DevicesMapPage";
import { SettingsPage } from "./views/SettingsPage";

function App() {
  // let user = JSON.parse(window.localStorage.getItem("user"));
  // let isLoggedIn = user === null || user === undefined ? false : true;
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
      <Switch>
        <Route exact path="/" component={Auth(HomePage, false)} />
        {/* <Route path="*" component={Auth(DevicelistPage, true)} /> */}
        <Route exact path="/signin" component={Auth(SigninPage, false)} />
        <Route exact path="/signup" component={Auth(SingupPage, false)} />
        <Route exact path="/term" component={Auth(TermPage, null)} />
        <Route exact path="/privacypolicy" component={Auth(PolicyPage, null)} />
        <Route exact path="/faq" component={Auth(FaqPage, null)} />
        <Route exact path="/about" component={Auth(AboutPage, null)} />
        <Route exact path="/product" component={Auth(ProductPage, null)} />
        {/*<Route
          exact
          path="/productdetail"
          component={Auth(ProductDetailPage, null)}
        />*/}
        <Route
          exact
          path="/productdetail/:id"
          component={Auth(ProductDetailPage, null)}
        />
        <Route exact path="/contactus" component={Auth(ContactusPage, null)} />
        <Route
          exact
          path="/forgotpassword"
          component={Auth(ForgotpasswordPage, null)}
        />
        <Route exact path="/myaccount" component={Auth(MyAccountPage, true)} />
        <Route
          exact
          path="/devicelist"
          component={Auth(DevicelistPage, true)}
        />
        <Route exact path="/device/:id" component={Auth(DevicePage, true)} />
        {/* <Route exact path="/device" component={Auth(HomePage, false)} /> */}
        <Route
          exact
          path="/notification"
          component={Auth(NotificationPage, true)}
        />
        <Route
          exact
          path="/devicelist"
          component={Auth(DevicelistPage, true)}
        />
        <Route exact path="/adminarea" component={Auth(AdminareaPage, true)} />
        <Route exact path="/addproduct" component={Auth(AddProduct, true)} />
        <Route exact path="/editproduct" component={Auth(EditProduct, true)} />
        <Route
          exact
          path="/devicesmap"
          component={Auth(DevicesMapPage, true)}
        />
        <Route exact path="/settings" component={Auth(SettingsPage, true)} />
        <Route
          exact
          path="/verifychangedemail"
          component={Auth(VerifychangedemailPage, null)}
        />
        <Route
          exact
          path="/verifyemail"
          component={Auth(VerifyemailPage, null)}
        />
        <Route
          exact
          path="/reactivate"
          component={Auth(ReactivatePage, null)}
        />
        <Route
          exact
          path="/thankssignuppage"
          component={Auth(ThanksSignupPage, null)}
        />
        <Route
          exact
          path="/verificationrequiredpage"
          component={Auth(VerificationRequiredPage, null)}
        />
        <Route path="*" component={Auth(HomePage, null)} />
      </Switch>
      {/* </div> */}
      {/* <FooterBar /> */}
    </Suspense>
  );
}

export default App;
