import React, { useState, useEffect } from "react";
import moment from "moment";
import LeftMenu from "./Sections/LeftMenu";
import RightMenu from "./Sections/RightMenu";
import { BsCheck } from "react-icons/bs";

import { MdShare, MdMenu } from "react-icons/md";
import { Nav, NavDropdown, Button } from "react-bootstrap";
import "./Sections/Navbar.css";
import { TRAPHISTORY_SERVER } from "../../Config";
import { DEVICE_SERVER } from "../../Config";
import { SHAREDDEVICE_SERVER } from "../../Config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { eventDescriptions } from "../../DeviceEvents";

import {
  getWs,
  registerEventHandler,
  unRegisterEventHandler,
} from "../../../common";

const MenuLogo = (props) => {
  return (
    <Nav.Item className="menu__logo">
      <Nav.Link href={props.imageLink}>
        <img alt="filzertech" src="/logo.svg" />
      </Nav.Link>
    </Nav.Item>
  );
}

function NavBar(props) {
  let history = useHistory();

  const [traphistories, setTraphistories] = useState([]);
  const [shareddevices, setShareddevices] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [menuClass, setMenuClass] = useState("menu");
  const [actCount, setActCount] = useState(0);
  const [shareCount, setShareCount] = useState(0);
  const [colorAct, setColorAct] = useState("#333");
  const [colorShare, setColorShare] = useState("#333");
  const [showMenu, setShowMenu] = useState(true);
  const [imageLink, setImageLink] = useState("/");
  const [allDevices, setAllDevices] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);

  let user = JSON.parse(window.localStorage.getItem("user"));
  useEffect(() => {
    checkLoggedIn();
    if (window.innerWidth < 981) setShowMenu(false);

    if (user != null) {
      getAvailableDeviceList();
      getShareddevices();
    }

    return function cleanup() {
      setTraphistories([]);
      setActCount(0);
      setShareddevices([]);
      setShareCount(0);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user != null) getUnread();
    // eslint-disable-next-line
  }, [props.initNav]);

  useEffect(() => {
    // getWebSocket();
    const user = JSON.parse(window.localStorage.getItem("user"));
    if (user !== undefined && user !== null) {
      let ws = getWs();
      if (ws !== null) {
        const browserId = window.localStorage.getItem("browserId");
        const userId = user.id;

        let deviceIds = [];
        allDevices.forEach((device) => {
          deviceIds.push(device.id);
        });
        let json = {
          senderType: "browser",
          userId: userId,
          browserId: browserId,
          receiver: "server",
          deviceIds: deviceIds,
          message: "registerBrowser",
          page: "devicelist",
        };
        registerEventHandler("navBar", onmessage, json);
      }

      return () => {
        unRegisterEventHandler("navBar");
      };
    }
    // eslint-disable-next-line
  }, [allDevices]);

  function DemoButton(props) {

    if (props.wsize < 981) {
      return null;
    } else {
      return (
        <Button
          className="normalButton"
          style={{
            marginTop: "14px",
            height: "40px",
            position: "absolute",
            right: "120px",
          }}
          onClick={() => {
            history.push({
              pathname: "/contactus",
              state: { fromRD: true },
            });
          }}
        >
          Request Demo
        </Button>
        );
    }
    
  }

  const getAvailableDeviceList = () => {
    axios
      .post(`${DEVICE_SERVER}/getavailabledevices`, {
        userid: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          setAllDevices(data.data.devices);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onmessage = (res) => {
    if (res.message === "newEvent") {
      if (user) getUnread();
    }
  };

  const getUnread = () => {
  
    axios
      .post(`${TRAPHISTORY_SERVER}/getunread`, {
        userId: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          /*let dataFromDB = data.data.traphistories;
          let readArray = [];
          let unreadArray = [];
          let checkText = "@" + user.id + ",";
          dataFromDB.forEach((item) => {
            if (item.haveread.indexOf(checkText) > -1) {
              readArray.push(item);
            } else {
              unreadArray.push(item);
            }
          });

          let plusArray = unreadArray.concat(readArray);

          // setUnreadArray(unreadArray);
          // setReadArray(readArray);
          setTraphistories(plusArray);
          setActCount(unreadArray.length);
          unreadArray.length > 0 ? setColorAct("#EB5757") : setColorAct("#333");*/
          let notifications = data.data.traphistories;
          let unreadNotifications = notifications.filter(notification => notification.isRead == false);
          setTraphistories(notifications);
          setActCount(unreadNotifications.length);
          if (unreadNotifications)
            setColorAct("#EB5757");
          else
            setColorAct("#333");
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getShareddevices = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/getsharededevicebysharedto`, {
        userid: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          let dataFromDB = data.data.shareddevices;
          let acceptArray = [];
          let pendingArray = [];
          dataFromDB.forEach((item) => {
            if (item.status === "accept") {
              acceptArray.push(item);
            } else {
              pendingArray.push(item);
            }
          });
          let plusArray = pendingArray.concat(acceptArray);
          setShareddevices(plusArray);
          setShareCount(pendingArray.length);
          pendingArray.length > 0
            ? setColorShare("#EB5757")
            : setColorShare("#333");
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const checkLoggedIn = () => {
    user === undefined || user === null
      ? setLoggedIn(false)
      : setLoggedIn(true);
    user === undefined || user === null
      ? setMenuClass("menu")
      : setMenuClass("menu_loggedin");
    user === undefined || user === null
      ? setImageLink("/")
      : setImageLink("/devicelist");
  };

  const createDeviceAlerts = () => {
    let items = [];
    //let checkText = "@" + user.id + ",";

    const makeEventText = (event, model) => {
      let eventDescription = "";
      if (event in eventDescriptions) {
        eventDescription = eventDescriptions[event];
      }
      if (model in eventDescriptions) {
        if (event in eventDescriptions[model]) {
          eventDescription = eventDescriptions[model][event];
        }
      }
      return eventDescription;
    };

    let recentNotifications = traphistories.slice(0, 4);
    recentNotifications.forEach(notification => {
      let isOwner = true;
      if (notification.isRead) {
        items.push(
          <div key={notification.id}>
            <NavDropdown.Item
              onClick={() => {
                onClickDevNoti(notification.deviceId, isOwner);
              }}
            >
              <div className="empty_space"></div>
              <span className="noti_dev">
                {notification.deviceId} - {notification.device.deviceName}
              </span>{" "}
              <span className="noti_time">
                {makeEventText(notification.trapEvent.event, notification.device.model)}{" "}
              </span>
              <br />
              <div className="empty_space"></div>{" "}
              <span className="noti_time">{getTimeDiff(notification.createdAt)}</span>
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </div>
        );
      } else {
        items.push(
          <div key={notification.id}>
            <NavDropdown.Item
              onClick={() => {
                onClickDevNoti(notification.deviceId, isOwner);
              }}
            >
              <div className="orange_dot"></div>
              <span className="noti_dev">
                {notification.deviceId} - {notification.device.deviceName}
              </span>{" "}
              <span className="noti_time">
                {makeEventText(notification.trapEvent.event, notification.device.model)}{" "}
              </span>
              {/* <br /> */}
              <div className="empty_space"></div>{" "}
              <span className="noti_time">{getTimeDiff(notification.createdAt)}</span>
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </div>
        );
      }
    });
    return items;
  };

  const getTimeDiff = (from) => {
    let current = new Date();
    let start = moment(current);
    let end = moment(from);

    if (start.diff(end, "seconds") < 60) {
      return "less than a minutes ago";
    } else if (start.diff(end, "minutes") < 60) {
      return start.diff(end, "minutes") + " minutes ago";
    } else if (start.diff(end, "hours") < 24) {
      return start.diff(end, "hours") + " hours ago";
    } else if (start.diff(end, "days") < 30) {
      return start.diff(end, "days") + " days ago";
    } else if (start.diff(end, "months") < 12) {
      return start.diff(end, "months") + " months ago";
    } else {
      return start.diff(end, "years") + " years ago";
    }
  };

  const onClickDevNoti = (deviceid, isOwner) => {
    if (window.location.pathname === "/device") window.location.reload(false);
    console.log(isOwner);
    history.push({
      pathname: "/device/" + deviceid,
      state: {
        devid: deviceid,
        own: isOwner,
      },
    });
  };

  const markAllAsRead = () => {
    axios
      .post(`${TRAPHISTORY_SERVER}/markallasread`, {
        userid: user.id,
      })
      .then(() => {
        getUnread();
      });
  };

  const makeSharedList = () => {
    let items = [];

    for (let i in shareddevices) {
      let row = shareddevices[i];

      if (i > 3) break;
      if (row.status === "pending") {
        items.push(
          <div key={i}>
            <NavDropdown.Item>
              <span className="noti_dev">
                {row.device.owner.firstName} {row.device.owner.lastName}
              </span>{" "}
              <span className="noti_time">
                shared {row.device.deviceCode} with you.{" "}
              </span>
              <br />
              <span className="noti_time">{getTimeDiff(row.createdAt)}</span>
              <br />
              <Button
                className="normalButton"
                style={{ marginTop: "10px" }}
                onClick={() => {
                  acceptDevice(row.device.id);
                }}
              >
                Accept
              </Button>
              <Button
                className="cancelButton"
                style={{ marginTop: "10px", marginLeft: "10px" }}
                onClick={() => {
                  declineDevice(row.id);
                }}
              >
                Decline
              </Button>
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </div>
        );
      } else {
        items.push(
          <div key={i}>
            <NavDropdown.Item>
              <span className="noti_dev">
                {row.device.owner.firstName} {row.device.owner.lastName}
              </span>{" "}
              <span className="noti_time">
                shared {row.device.deviceCode} with you.{" "}
              </span>
              <br />{" "}
              <span className="noti_time">{getTimeDiff(row.createdAt)}</span>
              <br />
              <span className="noti_time">
                <BsCheck />
                Accepted
              </span>
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </div>
        );
      }
    }
    return items;
  };

  const acceptDevice = (deviceid) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/acceptdevice`, {
        userid: user.id,
        deviceid,
      })
      .then((data) => {
        if (data.data.success) {
          getShareddevices();
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const declineDevice = (deviceid) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/declinedevice`, {
        userid: user.id,
        deviceid,
      })
      .then((data) => {
        if (data.data.success) {
          getShareddevices();
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onClickHamburger = () => {
    if (loggedIn) {
      setShowMenu(!showMenu);
      props.updateHamburger(!showMenu);
    } else {
      setShowOverlay(true);
    }
  };

  const onClickCloseOverlay = (e) => {
    e.preventDefault();
    setShowOverlay(false);
  };

  return (
    <Nav className={menuClass}>
      {loggedIn || window.innerWidth < 981 ? (
        //  &&
        // window.location.pathname !== "/faq" &&
        // window.location.pathname !== "/term" &&
        // window.location.pathname !== "/about" &&
        // window.location.pathname !== "/product" &&
        // window.location.pathname !== "/contactus" &&
        // window.location.pathname !== "/reactivate" &&
        // window.location.pathname !== "/verifyemail" &&
        // window.location.pathname !== "/productdetail" &&
        // window.location.pathname !== "/privacypolicy" &&
        // window.location.pathname !== "/thankssignuppage" &&
        // window.location.pathname !== "/verifychangedemail" &&
        // window.location.pathname !== "/verificationrequiredpage"
        <MdMenu
          onClick={onClickHamburger}
          style={{
            marginTop: "22px",
            marginRight: "10px",
            fontSize: "24px",
            cursor: "pointer",
          }}
        />
      ) : null}

      <MenuLogo imageLink={imageLink} />

      {loggedIn ? (
        <>
          <div className="notiGroup">
            <NavDropdown
              title={
                <div className="noti_act">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 0.015625C2.4375 0.015625 1.95312 0.21875 1.54688 0.625C1.17188 1 0.984375 1.45313 
                0.984375 1.98438V13.9844C0.984375 14.5469 1.17188 15.0312 1.54688 15.4375C1.95312 15.8125 
                2.4375 16 3 16H9.98438V18.0156H8.01562V19.9844H15.9844V18.0156H14.0156V16H21C21.5625 16 
                22.0312 15.8125 22.4062 15.4375C22.8125 15.0312 23.0156 14.5469 23.0156 13.9844V1.98438C23.0156 
                1.45313 22.8125 1 22.4062 0.625C22.0312 0.21875 21.5625 0.015625 21 0.015625H3ZM3 
                1.98438H21V13.9844H3V1.98438ZM15 3.01562L11.4844 6.48438L15 10L16.4062 8.59375L14.2969 
                6.48438L16.4062 4.42188L15 3.01562ZM9 6.01562L7.59375 7.42188L9.70312 9.48438L7.59375 
                11.5938L9 13L12.5156 9.48438L9 6.01562Z"
                      fill={colorAct}
                    />
                  </svg>
                  {actCount < 1 ? null : (
                    <span style={{ color: colorAct, marginLeft: "10px" }}>
                      {actCount}
                    </span>
                  )}
                </div>
                // } id="nav-dropdown" style={{ paddingTop: '14px' }}>
              }
              id="nav-dropdown"
            >
              {traphistories.length < 1 ? (
                <div>
                  <NavDropdown.Item>
                    <span className="">There is no new device activity.</span>
                  </NavDropdown.Item>
                </div>
              ) : (
                <>
                  {createDeviceAlerts()}
                  <NavDropdown.Item
                    className="noti_last noti_all"
                    onClick={() => {
                      history.push({
                        pathname: "/notification",
                        state: { isSharing: false },
                      });
                    }}
                  >
                    View all alert
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="noti_last noti_mark"
                    onClick={() => {
                      markAllAsRead();
                    }}
                  >
                    Mark all as read
                  </NavDropdown.Item>
                </>
              )}
            </NavDropdown>
            <NavDropdown
              title={
                <div className="noti_share">
                  <MdShare
                    color={colorShare}
                    style={{ width: "24px", height: "24px" }}
                  />
                  {shareCount < 1 ? null : (
                    <span style={{ color: colorShare, marginLeft: "10px" }}>
                      {shareCount}
                    </span>
                  )}
                </div>
              }
              id="nav-dropdown1"
            >
              {shareddevices.length < 1 ? (
                <div>
                  <NavDropdown.Item>
                    <span className="">There are no new shared devices.</span>
                  </NavDropdown.Item>
                </div>
              ) : (
                <>
                  {makeSharedList()}
                  <NavDropdown.Item
                    className="noti_share"
                    onClick={() => {
                      history.push({
                        pathname: "/notification",
                        state: { isSharing: true },
                      });
                    }}
                  >
                    View all notification
                  </NavDropdown.Item>
                </>
              )}
            </NavDropdown>
          </div>
          <DemoButton wsize={window.innerWidth} />
        </>
      ) : window.innerWidth > 981 ? (
        <>
          <div className="menu_left">
            <LeftMenu mode="horizontal" />
          </div>
          <div className="menu_right">
            <RightMenu mode="horizontal" />
          </div>
        </>
      ) : (
        <div className="overlay" style={{ width: showOverlay ? "100%" : "0%" }}>
          <a
            href="!#"
            className="closebtn"
            onClick={(e) => {
              onClickCloseOverlay(e);
            }}
          >
            &times;
          </a>
          <img
            alt="filzertech"
            src="/logo.svg"
            style={{ width: "140px", margin: "20px 40px" }}
            onClick={() => {
              history.push("/home");
            }}
          />
          <div className="overlay-content">
            <a href="/product">Products</a>
            <a href="/about">About us</a>
            <a href="/contactus">Contact us</a>
            <RightMenu mode="horizontal" />
          </div>
        </div>
      )}


    </Nav>
  );
}

export default NavBar;