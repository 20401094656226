import React from "react";
import Chart from 'react-apexcharts';

function DataChart(props) {
  if (props.dataType == "temp") {
    var chartId = "filzertech-temperature-chart";
    var chartColor = ['#1A73E8'];
    var chartUnit = `°${props.tempUnit}`;
    var seriesName = "Temperature";
    var seriesData = props.tempData;
  } else if (props.dataType == "humi") {
    var chartId = "filzertech-humidity-chart";
    var chartColor = ['#C569D9'];
    var chartUnit = "%";
    var seriesName = "Humidity";
    var seriesData = props.humiData;
  } else if (props.dataType == "pres") {
    var chartId = "filzertech-pressure-chart";
    var chartColor = ['#FF8F7E'];
    var chartUnit = "mBar";
    var seriesName = "Pressure";
    var seriesData = props.presData;
  }
  
  const [options, setOptions] = React.useState({
      chart: {
        id: chartId,
        type: 'area',
        zoom: {
          autoScaleYaxis: true
        }
      },
      colors: chartColor,
      fill: {
        colors: chartColor
      },
      dataLabels: {
          enabled: false
      },
      xaxis: {
          'type':'datetime',
          labels: {
              datetimeUTC: false
          }
      },
      yaxis: {
          labels: {
            formatter: (val) => {
              return val.toFixed(0) + chartUnit
            }
          }
      },
      tooltip: {
          x: {
            format: 'dd MMM yyyy — hh:mm TT'
          }
        },
    });

    const [series, setSeries] = React.useState([
      {
          'name':seriesName,
          'data':seriesData
      }
    ]);

    return (
        <Chart options={options} series={series} type="area" height="200px"/>
    );
}

export default React.memo(DataChart);