import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Toast,
  Badge,
  Row,
  Col,
  Modal,
  Container,
} from "react-bootstrap";
import { FiWifi, FiWifiOff } from "react-icons/fi";
import {
  FaTrash,
  FaSortAmountDownAlt,
  FaEyeSlash,
  FaEye,
} from "react-icons/fa";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import { DEVICE_SERVER } from "../../Config";
import { SHAREDDEVICE_SERVER } from "../../Config";

import { useHistory } from "react-router-dom";

import ReactShowMoreLess from "./ReactShowMoreLess";
import { deviceTypes } from "../../DeviceTypes";

export const Alldevices = (props) => {
  let history = useHistory();
  const [allDevicesList, setAllDevicesList] = useState([]);
  const [allDevicesListForTable, setAllDevicesListForTable] = useState([]);
  const [selectedDevicesArray, setSelectedDevicesArray] = useState([]);

  /////// For Toast show
  const [showToast, setShowToast] = useState(false);
  const [deletedLength, setDeletedLength] = useState(0);
  //const [sortField, setSortField] = useState("devid");
  const [sortField, setSortField] = useState("deff");
  const [sortValue, setSortValue] = useState("asc");
  const [theDevice, setTheDevice] = useState(undefined);
  const [showDeleteOneModal, setShowDeleteOneModal] = useState(false);
  const [showDeleteSelectedModal, setShowDeleteSelectedModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  //For disabled buttons
  let disabledSelected = selectedDevicesArray.length < 1 ? true : false;

  let user = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => {
    setIsMobile(window.innerWidth > 981 ? false : true);
    if (user == null) return null;
    getAvailableDevices();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    makieAllDevicesListForTable();
    // eslint-disable-next-line
  }, [allDevicesList, props.onlineStatus]);

  useEffect(() => {
    getAvailableDevices();
    // eslint-disable-next-line
  }, [props.allDevicesLength, sortField, sortValue]);

  const getAvailableDevices = () => {
    axios
      .post(`${DEVICE_SERVER}/getavailabledevices`, {
        userid: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          let tempDeviceList = data.data.devices;
          for (let i in tempDeviceList) {
            if (props.onlineStatus[tempDeviceList[i].id] !== undefined)
              tempDeviceList[i].deviceStatus =
                props.onlineStatus[tempDeviceList[i].id];
          }
          if (sortField === "deff" && sortValue === "asc")
            tempDeviceList.sort(
              (a, b) => ((b.deviceStatus == "offline") ? 0 : 1) - ((a.deviceStatus == "offline") ? 0 : 1) || b._count.trapEventNotifications - a._count.trapEventNotifications 
            );
          else if (sortField === "devid" && sortValue === "asc")
            tempDeviceList.sort(
              (a, b) => parseFloat(a.deviceCode) - parseFloat(b.deviceCode)
            );
          else if (sortField === "devid" && sortValue === "desc")
            tempDeviceList.sort(
              (a, b) => parseFloat(b.deviceCode) - parseFloat(a.deviceCode)
            );
          else if (sortField === "activity" && sortValue === "asc")
            tempDeviceList.sort((a, b) => a._count.trapEventNotifications - b._count.trapEventNotifications);
          else if (sortField === "activity" && sortValue === "desc")
            tempDeviceList.sort((a, b) => b._count.trapEventNotifications - a._count.trapEventNotifications);
          else if (sortField === "owner" && sortValue === "asc")
            tempDeviceList.sort(
              (a, b) => parseFloat(a.ownerId) - parseFloat(b.ownerId)
            );
          else if (sortField === "owner" && sortValue === "desc")
            tempDeviceList.sort(
              (a, b) => parseFloat(b.ownerId) - parseFloat(a.ownerId)
            );
          setAllDevicesList(tempDeviceList);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch((error) => {
        console.log("Oops, request failed!");
      });
  };

  const makieAllDevicesListForTable = () => {
    let tempDeviceListForTable = JSON.parse(JSON.stringify(allDevicesList));
    for (let i in allDevicesList) {
      if (props.onlineStatus[tempDeviceListForTable[i].id] !== undefined)
        tempDeviceListForTable[i].deviceStatus =
          props.onlineStatus[tempDeviceListForTable[i].id];
    }
    setAllDevicesListForTable(tempDeviceListForTable);
  };

  const onClickDeleteSeleted = () => {
    props.updateAllDeviceListLength(selectedDevicesArray.length);
    selectedDevicesArray.forEach((row) => {
      if (user.id === row.owner) {
        axios
          .post(`${DEVICE_SERVER}/removeonedevice`, {
            deviceid: row.id,
            owner: row.owner,
          })
          .then((data) => {
            if (data.data.success) {
              //state right stone
              setAllDevicesList((allDevicesList) => {
                let k = allDevicesList.findIndex(
                  (device) => device.id === row.id
                );
                if (k > -1) {
                  return [
                    ...allDevicesList.slice(0, k),
                    ...allDevicesList.slice(k + 1, allDevicesList.length),
                  ];
                } else {
                  return allDevicesList;
                }
              });
              setDeletedLength(selectedDevicesArray.length);
            } else {
              console.log("unsuccess");
            }
          })
          .catch(() => {
            console.log("Oops, request failed!");
          });
      } else {
        axios
          .post(`${SHAREDDEVICE_SERVER}/removebydeviceidto`, {
            deviceid: row.id,
            sharedto: user.id,
          })
          .then((data) => {
            if (data.data.success) {
              //state right stone
              setAllDevicesList((allDevicesList) => {
                let k = allDevicesList.findIndex(
                  (device) => device.id === row.id
                );
                if (k > -1) {
                  return [
                    ...allDevicesList.slice(0, k),
                    ...allDevicesList.slice(k + 1, allDevicesList.length),
                  ];
                } else {
                  return allDevicesList;
                }
              });
              setDeletedLength(selectedDevicesArray.length);
            } else {
              console.log("unsuccess");
            }
          })
          .catch(() => {
            console.log("Oops, request failed!");
          });
      }
    });
    setSelectedDevicesArray([]);
    setShowToast(true);
    setShowDeleteSelectedModal(false);
  };

  const onClickDeleteOne = () => {
    if (user.id === theDevice.ownerId) {
      axios
        .post(`${DEVICE_SERVER}/removeonedevice`, {
          deviceid: theDevice.id,
          owner: theDevice.ownerId,
        })
        .then((data) => {
          if (data.data.success) {
            getAvailableDevices();
            setDeletedLength(1);
            props.updateAllDeviceListLength(1);
            setShowToast(true);
            setShowDeleteOneModal(false);
          } else {
            console.log("unsuccess");
          }
        })
        .catch(() => {
          console.log("Oops, request failed!");
        });
    } else {
      axios
        .post(`${SHAREDDEVICE_SERVER}/removebydeviceidto`, {
          deviceid: theDevice.id,
          sharedto: user.id,
        })
        .then((data) => {
          if (data.data.success) {
            setAllDevicesList((allDevicesList) => {
              let k = allDevicesList.findIndex(
                (device) => device.id === theDevice.id
              );
              if (k > -1) {
                return [
                  ...allDevicesList.slice(0, k),
                  ...allDevicesList.slice(k + 1, allDevicesList.length),
                ];
              } else {
                return allDevicesList;
              }
            });
            setDeletedLength(1);
            props.updateAllDeviceListLength(1);
            setShowToast(true);
            setShowDeleteOneModal(false);
          } else {
            console.log("unsuccess");
          }
        })
        .catch(() => {
          console.log("Oops, request failed!");
        });
    }
  };

  ////////////////////////////////////////----------Table Config--------/////////////////////////////////////////////////
  const idHeaderFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        ID <FaSortAmountDownAlt />
      </div>
    );
  };


  function StatusHelper(props) {
    let status = props.currentStatus;

    if (status === "online") {
      return (
        <FiWifi style={{ color: "#27AE60", width: "16px", height: "16px" }} />
      );
    } else if (status === "offline") {
      return (
        <FiWifiOff
          style={{ color: "#BDBDBD", width: "16px", height: "16px" }}
        />
      );
    } else {
      return null;
    }

  }

  function ActivityHelper(props) {
    let activity = props.currentActivity;

    if (activity === null || activity == 0) {
      return (
        <Badge
          variant="secondary"
          style={{
            fontSize: "18px",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
        >
          0
        </Badge>
      );
    } else if (activity > 0) {
      return (
        <Badge variant="danger" style={{ fontSize: "18px" }}>
          {activity}
        </Badge>
      );
    }
  }

  const idFormatter = (cell, row, rowIndex, formatExtraData) => {
    let divBorder = row.showonmap ? "1px solid #27AE60" : "1px solid #BDBDBD";
    let divBackgroundColor = row.showonmap ? "#27AE60" : "white";
    let divColor = row.showonmap ? "white" : "black";
    let model;
    model = `${deviceTypes[row.model]} (${row.model})`;

    let shortVersion = (
      <Row>
        <Col xs={8}>
          <p
            style={{
              textAlign: "left",
              marginBottom: 0,
            }}
          >
            <strong>Model: </strong>{model}<br/>
            <strong>Name: </strong>{row.deviceName}<br/>
          </p>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={6}
              style={{
                paddingInline: 0,
                textAlign: "end",
              }}
            >
              <ActivityHelper currentActivity={row._count.trapEventNotifications}/>
            </Col>
            <Col xs={6}
              style={{
                paddingInline: "8px",
                textAlign: "start",
              }}
            >
              <StatusHelper currentStatus={row.deviceStatus}/>
            </Col>
          </Row>
          <Row
            style={{
              marginRight: "-5px",
              marginLeft: "-28px",
              marginTop: "2px",
            }}
          >
            <Col xs={12}>
              <p>
                <strong>ID: </strong>{row.deviceCode}<br/>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    let fullVersion = (
      <Row>
        <Col xs={8}>
          <p
            style={{
              textAlign: "left",
              marginBottom: 0,
            }}
          >
            <strong>Model: </strong>{model}<br/>
            <strong>Name: </strong>{row.deviceName}<br/>
            <strong>Owner: </strong>{row.owner.firstName} {row.owner.lastName}<br/>
            <strong>Address: </strong>{row.address}<br/>
            <strong>Position: </strong>{row.position}<br/>
          </p>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={6}
              style={{
                paddingInline: 0,
                textAlign: "end",
              }}
            >
              <ActivityHelper currentActivity={row._count.trapEventNotifications}/>
            </Col>
            <Col xs={6}
              style={{
                paddingInline: "8px",
                textAlign: "start",
              }}
            >
              <StatusHelper currentStatus={row.deviceStatus}/>
            </Col>
          </Row>
          <Row
            style={{
              marginRight: "-5px",
              marginLeft: "-28px",
              marginTop: "2px",
            }}
          >
            <Col xs={12}>
              <p>
                <strong>ID: </strong>{row.deviceCode}<br/>
              </p>
            </Col>
          </Row>
          <Row>
            <Col 
              xs={6}
              style={{
                paddingRight: "2px",
              }}
            >
              <Button
                className="actionBtn"
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  borderRadius: "4px",
                  border: divBorder,
                  background: divBackgroundColor,
                  color: divColor,
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (user.id === row.owner) {
                    axios
                      .post(`${DEVICE_SERVER}/setshowonmapbydeviceid`, {
                        deviceid: row.id,
                        owner: row.ownerId,
                      })
                      .then((data) => {
                        if (data.data.success) {
                          getAvailableDevices();
                        } else {
                          console.log("unsuccess");
                        }
                      })
                      .catch(() => {
                        console.log("Oops, request failed!");
                      });
                  } else {
                    axios
                      .post(`${SHAREDDEVICE_SERVER}/setshowonmap`, {
                        deviceid: row.id,
                        sharedto: user.id,
                      })
                      .then((data) => {
                        if (data.data.success) {
                          getAvailableDevices();
                        } else {
                          console.log("unsuccess");
                        }
                      })
                      .catch(() => {
                        console.log("Oops, request failed!");
                      });
                  }
                }}
              >
                {row.showOnMap ? <FaEye /> : <FaEyeSlash color="disabled" />}
              </Button>
            </Col>
            <Col
              xs={6}
              style={{
                paddingLeft: "2px",
              }}
            >
              <Button 
                variant="outline-secondary"
                className="actionBtn"
                style={{
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTheDevice(row);
                  setShowDeleteOneModal(true);
                }}
              >
                <FaTrash />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    if (isMobile) {
      return (
        <ReactShowMoreLess
          shortVersion={shortVersion}
          fullVersion={fullVersion}
          readMoreText={"Show more ▼"}
          readLessText={"Show less ▲"}
          readMoreClassName="read-more-less--more"
          readLessClassName="read-more-less--less"
        />
      );
    } else {
      return (
        <span>{row.deviceCode}</span>
      );
    }

  };

  const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (isMobile) {
      return null;
    } else {
      return (
        <span>{row.deviceName}</span>
      );
    }
  };

  const addressFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
        isMobile ? null : <span>{row.address}</span>
    );
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      if (cell === "online") {
        return (
          <FiWifi style={{ color: "#27AE60", width: "16px", height: "16px" }} />
        );
      } else if (cell === "offline") {
        return (
          <FiWifiOff
            style={{ color: "#BDBDBD", width: "16px", height: "16px" }}
          />
        );
      }
    } else {
      return null;
    }
  };

  const activityFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      /*if (cell === null) {
        return (
          <Badge
            variant="secondary"
            style={{
              fontSize: "18px",
              color: "#333333",
              backgroundColor: "#E0E0E0",
            }}
          >
            0
          </Badge>
        );
      } else if (cell > 0) {
        return (
          <Badge variant="danger" style={{ fontSize: "18px" }}>
            {cell}
          </Badge>
        );
      }*/
     if (cell) {
      return (
        <Badge variant="danger" style={{ fontSize: "18px" }}>
          {cell}
        </Badge>
      );
     } else {
      return (
        <Badge
          variant="secondary"
          style={{
            fontSize: "18px",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
        >
          0
        </Badge>
      );
     }
    } else {
      return null;
    }
  };

  const positionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
        isMobile ? null : <span>{row.position}</span>
    );
  };

  const ownerFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
        isMobile ? null : <span>{row.owner.firstName} {row.owner.lastName}</span>
    );
  };

  const showonmapFormatter = (cell, row, rowIndex, formatExtraData) => {
    let divBorder = cell ? "1px solid #27AE60" : "1px solid #BDBDBD";
    let divBackgroundColor = cell ? "#27AE60" : "white";
    let divColor = cell ? "white" : "black";

    if (!isMobile) {
      return (
        <>
          <Button
            style={{
              textAlign: "center",
              cursor: "pointer",
              lineHeight: "normal",
              borderRadius: "4px",
              border: divBorder,
              background: divBackgroundColor,
              color: divColor,
            }}
          >
            {cell ? <FaEye /> : <FaEyeSlash color="disabled" />}
          </Button>
        </>
      );
    } else {
      return null;
    }
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      return (
        <Button variant="outline-secondary" className="actionBtn">
          <FaTrash />
        </Button>
      );
    } else {
      return null;
    }
  };

  const modelFormatter = (cell, row, rowIndex, formatExtraData) => {
    let model;
    model = `${deviceTypes[cell]} (${cell})`;
    return (
        isMobile ? null : <span>{model}</span>
    );
  };

  const columns = [
    {
      dataField: "deviceStatus",
      text: "Status",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      style: { cursor: "pointer" },
      formatter: statusFormatter,
      //classes: "tenWidth",
      classes: (isMobile ? null : "tenWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let own = row.owner === user.id ? true : false;
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: own,
            },
          });
        },
      },
    },
    {
      dataField: "_count.trapEventNotifications",
      text: "Activity",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      style: { cursor: "pointer" },
      formatter: activityFormatter,
      //classes: "tenWidth",
      classes: (isMobile ? null : "tenWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let own = row.owner === user.id ? true : false;
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: own,
            },
          });
        },
      },
    },
    {
      dataField: "deviceCode",
      text: "Device ID",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", width: "calc(100% - 35px)" } : { cursor: "pointer" }),
      headerFormatter: idHeaderFormatter,
      formatter: idFormatter,
      //classes: (isMobile ? "centerWMargin" : "idWidth"),
      classes: (isMobile ? null : "idWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let own = row.owner === user.id ? true : false;
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: own,
            },
          });
        },
      },
    },
    {
      dataField: "model",
      text: "Model",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: modelFormatter,
      //classes: "modelWidth",
      classes: (isMobile ? null : "modelWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let own = row.owner === user.id ? true : false;
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: own,
            },
          });
        },
      },
    },
    {
      dataField: "deviceName",
      text: "Device name",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: nameFormatter,
      classes: "fullWidth nameWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let own = row.owner === user.id ? true : false;
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: own,
            },
          });
        },
      },
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: addressFormatter,
      classes: "fullWidth addressWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let own = row.owner === user.id ? true : false;
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: own,
            },
          });
        },
      },
    },
    {
      dataField: "position",
      text: "Position",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: positionFormatter,
      classes: "halfWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let own = row.owner === user.id ? true : false;
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: own,
            },
          });
        },
      },
    },
    {
      dataField: "owner.firstName",
      text: "Owner",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: ownerFormatter,
      classes: "halfWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let own = row.owner === user.id ? true : false;
          history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: own,
            },
          });
        },
      },
    },
    {
      dataField: "showOnMap",
      text: "On map",
      formatter: showonmapFormatter,
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      //classes: "mapWidth",
      classes: (isMobile ? "realHalfWidth" : "mapWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (user.id === row.owner) {
            axios
              .post(`${DEVICE_SERVER}/setshowonmapbydeviceid`, {
                deviceid: row.id,
                owner: row.owner,
              })
              .then((data) => {
                if (data.data.success) {
                  getAvailableDevices();
                } else {
                  console.log("unsuccess");
                }
              })
              .catch(() => {
                console.log("Oops, request failed!");
              });
          } else {
            axios
              .post(`${SHAREDDEVICE_SERVER}/setshowonmap`, {
                deviceid: row.id,
                sharedto: user.id,
              })
              .then((data) => {
                if (data.data.success) {
                  getAvailableDevices();
                } else {
                  console.log("unsuccess");
                }
              })
              .catch(() => {
                console.log("Oops, request failed!");
              });
          }
        },
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      classes: (isMobile ? "realHalfWidth" : null),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setTheDevice(row);
          setShowDeleteOneModal(true);
        },
      },
    },
  ];

  const selectRow = {
    mode: "checkbox",
    bgColor: "#EAF6FC",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedDevicesArray((selectedDevicesArray) => {
        if (isSelect) {
          return [...selectedDevicesArray, row];
        } else {
          let k = selectedDevicesArray.findIndex((temp) => temp.id === row.id);
          return [
            ...selectedDevicesArray.slice(0, k),
            ...selectedDevicesArray.slice(k + 1, selectedDevicesArray.length),
          ];
        }
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      setSelectedDevicesArray((selectedDevicesArray) => {
        if (isSelect) {
          return [...allDevicesList];
        } else {
          return [];
        }
      });
    },
  };

  const pnoptions = {
    hideSizePerPage: true,
  };

  const onCloseToast = () => {
    setShowToast(false);
    setDeletedLength(0);
  };

  const onChangeSortField = (e) => {
    setSortField(e.target.value);
  };

  const onChangeSortValue = (e) => {
    setSortValue(e.target.value);
  };

  return (
    <div className="sub_content">
      <Toast
        delay={3000}
        autohide
        show={showToast}
        onClose={onCloseToast}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          paddingLeft: 20,
          zIndex: 8,
          width: 400,
          borderTop: "4px solid #F47721",
        }}
      >
        <Toast.Body>{deletedLength} devices deleted.</Toast.Body>
      </Toast>
      <div className="sub_title">All devices</div>
      <div className="sub_body">
        <Form.Group>
          <Button
            variant="outline-dark"
            className="controlBtn"
            disabled={disabledSelected}
            onClick={() => {
              setShowDeleteSelectedModal(true);
            }}
          >
            <FaTrash style={{ marginRight: "6px" }} />
            Delete selected
          </Button>
        </Form.Group>
        <Form.Group className="sortArea">
          <Row>
            <Col xs={2} style={{ textAlign: "right", padding: "8px 0" }}>
              <Form.Label>Sort by</Form.Label>
            </Col>
            <Col xs={5}>
              <Form.Control
                onChange={(e) => {
                  onChangeSortField(e);
                }}
                as="select"
              >
                <option value="devid">ID</option>
                <option value="owner">Owner</option>
                <option value="activity">Activity</option>
              </Form.Control>
            </Col>
            <Col xs={5}>
              <Form.Control
                onChange={(e) => {
                  onChangeSortValue(e);
                }}
                as="select"
              >
                <option value="asc">ascending</option>
                <option value="desc">descending</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
        <BootstrapTable
          keyField="id"
          data={allDevicesListForTable}
          classes="tableWithMobile"
          columns={columns}
          bordered={false}
          striped={true}
          selectRow={selectRow}
          pagination={paginationFactory(pnoptions)}
        />
        {theDevice === undefined ? null : (
          <Modal
            show={showDeleteOneModal}
            onHide={() => {
              setShowDeleteOneModal(false);
            }}
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>Delete device</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <p>
                    Are you sure want to delete device {theDevice.deviceCode} -{" "}
                    {theDevice.deviceName}?
                  </p>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Button
                    className="normalButton"
                    onClick={() => {
                      onClickDeleteOne();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="cancelButton"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setShowDeleteOneModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        )}
        {!selectedDevicesArray.length ? null : (
          <Modal
            show={showDeleteSelectedModal}
            onHide={() => {
              setShowDeleteSelectedModal(false);
            }}
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>Delete devices</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <p>
                    Are you sure want to delete {selectedDevicesArray.length}{" "}
                    devices?
                  </p>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Button
                    className="normalButton"
                    onClick={() => {
                      onClickDeleteSeleted();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="cancelButton"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setShowDeleteSelectedModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </div>
  );
};