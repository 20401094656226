import React, { useState, useEffect } from "react";
import MenuBar from "../MenuBar";
import axios from "axios";
import { FiWifi, FiWifiOff } from "react-icons/fi";
import { FaCircle } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import { DEVICE_SERVER } from "../../Config";
import NavBar from "../NavBar";
import { DevicesMap } from "./DevicesMap";
import {
  getWs,
  registerEventHandler,
  unRegisterEventHandler,
} from "../../../common";
// import Map from "./Map";

import { useHistory } from "react-router-dom";
let isDeviceIDAsc = false;
let isDeviceNameAsc = false;
let isActivityAsc = false;

export const DevicesMapPage = (props) => {
  let history = useHistory();
  const [devicelists, setDevicelists] = useState([]);
  const [deviceListWithStatus, setDeviceListWithStatus] = useState([]);
  // const [deviceListForMap, setDeviceListForMap] = useState([]);
  const [activeCount, setActiveCount] = useState(0);

  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");

  // let ws = getWs();
  let user = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => {
    getDeviceList();

    if (window.innerWidth < 981) {
      setMainClass("body_panel col-sm-12");
      setPropShowMenu(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // getWebSocket();
    let ws = getWs();
    if (ws !== null) {
      const users = JSON.parse(window.localStorage.getItem("user"));
      const userId = users.id;
      const browserId = window.localStorage.getItem("browserId");

      let deviceIds = [];
      devicelists.forEach((device) => {
        deviceIds.push(device.id);
      });
      let json = {
        senderType: "browser",
        userId: userId,
        browserId: browserId,
        receiver: "server",
        deviceIds: deviceIds,
        message: "registerBrowser",
        page: "devicelist",
      };
      registerEventHandler("devicelist", onmessage, json);
    }

    return () => {
      unRegisterEventHandler("devicelist");
    };
    // eslint-disable-next-line
  }, [devicelists]);

  const getDeviceList = () => {
    axios
      .post(`${DEVICE_SERVER}/getavailabledevices`, {
        userid: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          // add current status to the device info
          let tempActiveCount = 0;
          let tempDeviceList = [];
          data.data.devices.forEach((device) => {
            if (device.showOnMap) {
              device.lat = parseFloat(device.lat);
              device.lng = parseFloat(device.lng);
              // nostatedevicelists[device.deviceid] = device;
              if (device._count.trapEventNotifications) tempActiveCount++;
              tempDeviceList.push(device);
            }
          });

          setDevicelists(tempDeviceList);
          setActiveCount(tempActiveCount);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onMsgRegisterBrowser = (status) => {
    let tempDeviceList = JSON.parse(JSON.stringify(devicelists)); // temp devicelist for right list
    let tempDeviceListForMap = JSON.parse(JSON.stringify(devicelists)); // temp devicelist for map

    console.log(status);

    for (let i in devicelists) {
      tempDeviceList[i].deviceStatus = status[tempDeviceList[i].id];

      if (tempDeviceListForMap[i]._count.trapEventNotifications) {
        tempDeviceListForMap[i].deviceStatus = "active";
      } else {
        tempDeviceListForMap[i].deviceStatus =
          status[tempDeviceListForMap[i].id];
      }
    }

    tempDeviceList.sort(
      (a, b) => (a.deviceStatus == "offline") - (b.deviceStatus == "offline") || b._count.trapEventNotifications - a._count.trapEventNotifications
    );
    setDeviceListWithStatus(tempDeviceList);
    // setDeviceListForMap(tempDeviceListForMap);
  };

  const onmessage = (res) => {
    // Handle server message
    if (res.message === "registerBrowser") {
      console.log("registerBrowser----------------");
      console.log(res.status);
      onMsgRegisterBrowser(res.status);
      // }
    } else if (res.message === "deviceConnected") {
      console.log("deviceConnected");
    } else if (res.message === "deviceDisconnected") {
      console.log("deviceDisconnected");
    } else if (res.message === "deviceStatus") {
      getDeviceList();
    } else if (res.message === "newEvent") {
      getDeviceList();
    } else if (res.message === "statusReq") {
    }
  };

  const makeDeviceTable = () => {
    let table = [];
    for (let i in deviceListWithStatus) {
      table.push(
        <div
          key={i}
          onClick={() => {
            onClickDeviceLink(
              deviceListWithStatus[i].id,
              user.id === deviceListWithStatus[i].ownerId ? true : false
            );
          }}
          className="row"
          style={{
            paddingTop: "8px",
            paddingBottom: "8px",
            fontWeight: "bold",
            textAlign: "center",
            boxShadow: "inset 0px -1px 0px #E0E0E0",
            cursor: "pointer",
          }}
        >
          <div className="col-sm-3 mapDevList">
            {deviceListWithStatus[i].deviceCode}
          </div>
          <div className="col-sm-1 mapDevList">
            {deviceListWithStatus[i].deviceStatus === "online" ? (
              <FiWifi
                style={{
                  color: "#27AE60",
                  width: "16px",
                  height: "16px",
                  margin: "5px",
                }}
              />
            ) : (
              <FiWifiOff
                style={{
                  color: "#BDBDBD",
                  width: "16px",
                  height: "16px",
                  margin: "5px",
                }}
              />
            )}
          </div>
          <div className="col-sm-2 mapDevList">
            {deviceListWithStatus[i]._count.trapEventNotifications === 0 ? (
              <Badge
                variant="secondary"
                style={{
                  fontSize: "18px",
                  color: "#333333",
                  backgroundColor: "#E0E0E0",
                }}
              >
                0
              </Badge>
            ) : (
              <Badge variant="danger" style={{ fontSize: "18px" }}>
                {deviceListWithStatus[i]._count.trapEventNotifications}
              </Badge>
            )}
          </div>
          <div className="col-sm-6 mapDevList">
            {deviceListWithStatus[i].deviceName}
          </div>
        </div>
      );
    }
    return table;
  };

  const onClickDeviceLink = (deviceid, own) => {
    history.push({
      pathname: "/device/" + deviceid,
      state: {
        devid: deviceid,
        own: own,
      },
    });
  };

  const getOnlineCount = () => {
    let onlineCount = 0;
    deviceListWithStatus.forEach((device) => {
      if (device.deviceStatus === "online") onlineCount++;
    });
    return onlineCount;
  };

  const getOfflineCount = () => {
    let offlineCount = 0;
    deviceListWithStatus.forEach((device) => {
      if (device.deviceStatus === "offline") offlineCount++;
    });
    return offlineCount;
  };

  const onClickDeviceID = () => {
    let tempDeviceList = [...deviceListWithStatus];
    if (isDeviceIDAsc) {
      tempDeviceList.sort(
        (a, b) => parseFloat(b.deviceCode) - parseFloat(a.deviceCode)
      );
      isDeviceIDAsc = false;
    } else {
      isDeviceIDAsc = true;
      tempDeviceList.sort(
        (a, b) => parseFloat(a.deviceCode) - parseFloat(b.deviceCode)
      );
    }
    setDeviceListWithStatus(tempDeviceList);
  };

  const onClickActivity = () => {
    let tempDeviceList = [...deviceListWithStatus];
    if (isActivityAsc) {
      tempDeviceList.sort((a, b) => b._count.trapEventNotifications - a._count.trapEventNotifications);

      isActivityAsc = false;
    } else {
      tempDeviceList.sort((a, b) => a._count.trapEventNotifications - b._count.trapEventNotifications);
      isActivityAsc = true;
    }
    setDeviceListWithStatus(tempDeviceList);
  };

  const onClickDeviceName = () => {
    let tempDeviceList = [...deviceListWithStatus];
    if (isDeviceNameAsc) {
      tempDeviceList.sort((a, b) => {
        if (a.deviceName > b.deviceName) return -1;
        if (a.deviceName < b.deviceName) return 1;
        return 0;
      });
      isDeviceNameAsc = false;
    } else {
      tempDeviceList.sort((a, b) => {
        if (a.deviceName < b.deviceName) return -1;
        if (a.deviceName > b.deviceName) return 1;
        return 0;
      });
      isDeviceNameAsc = true;
    }
    setDeviceListWithStatus(tempDeviceList);
  };

  //////////////////////////// Hamburger menu  ////////////////////////////////////////////////
  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu && window.innerWidth > 981
      ? setMainClass("body_panel col-sm-10")
      : setMainClass("body_panel col-sm-12");
  };

  return (
    <>
      <NavBar updateHamburger={updateHamburger} />
      <div className="mainpanel container-fluid">
        <div className="row">
          {propShowMenu ? <MenuBar /> : null}
          <div className={mainClass}>
            <div className="page_title" style={{ display: "flex" }}>
              Devices Map
            </div>
            <div className="page_content row">
              <div className="col-sm-9 mapArea">
                {/* {devicelists.length > 0 && Object.keys(JSON.parse(devicelists)).length > 0 ? */}
                {deviceListWithStatus.length > 0 ? (
                  <DevicesMap
                    // center={markerPosition}
                    devicelists={deviceListWithStatus}
                    mapContainerStyle={{
                      height: "700px",
                      width: "100%",
                    }}
                  />
                ) : (
                  <div>You have not any devices to show on map</div>
                )}
              </div>
              <div
                className="col-sm-3 listArea"
                style={{ padding: 0, height: "700px", overflowY: "scroll" }}
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6 devdetailleft">
                      Visible devices
                    </div>
                    <div
                      className="col-sm-6 devdetailright"
                      style={{ paddingLeft: "30px" }}
                    >
                      {deviceListWithStatus.length}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 devdetailleft">
                      Activity - Check
                    </div>
                    <div
                      className="col-sm-6 devdetailright"
                      style={{ color: "#EB5757" }}
                    >
                      <FaCircle
                        style={{ width: "16px", height: "16px", margin: "5px" }}
                      />
                      {activeCount}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 devdetailleft">Online</div>
                    <div className="col-sm-6 devdetailright">
                      <FiWifi
                        style={{
                          color: "#27AE60",
                          width: "16px",
                          height: "16px",
                          margin: "5px",
                        }}
                      />
                      {getOnlineCount()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 devdetailleft">Offline</div>
                    <div className="col-sm-6 devdetailright">
                      <FiWifiOff
                        style={{
                          color: "#BDBDBD",
                          width: "16px",
                          height: "16px",
                          margin: "5px",
                        }}
                      />
                      {getOfflineCount()}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      marginTop: "30px",
                      fontWeight: "bold",
                      textAlign: "center",
                      boxShadow: "inset 0px -1px 0px #E0E0E0",
                    }}
                  >
                    <div
                      className="col-sm-3 mapDevList"
                      style={{ cursor: "pointer" }}
                      onClick={() => onClickDeviceID()}
                    >
                      Device ID
                    </div>
                    <div className="col-sm-1 mapDevList"></div>
                    <div
                      className="col-sm-2 "
                      style={{ paddingLeft: "0px", cursor: "pointer" }}
                      onClick={() => onClickActivity()}
                    >
                      Activity
                    </div>
                    <div
                      className="col-sm-6 mapDevList"
                      style={{ cursor: "pointer" }}
                      onClick={() => onClickDeviceName()}
                    >
                      Device Name
                    </div>
                  </div>
                  {makeDeviceTable()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
