import React, { useState, useEffect } from "react";
import { Form, Button, Toast, Col } from "react-bootstrap";
import MenuBar from "../MenuBar";
import axios from "axios";
import { PRODUCT_SERVER } from "../../Config";
import { FILZERDOC_SERVER } from "../../Config";
import { TINYMCE_EDITOR_KEY } from "../../Config";
import NavBar from "../NavBar";

import { Editor } from "@tinymce/tinymce-react";

export const EditProduct = (props) => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");

  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");

  /////////////////////////////////
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [spec, setSpec] = useState("");
  const [support, setSupport] = useState("");
  const [showOnHome, setShowOnHome] = useState(false);
  const [productInfo, setProductInfo] = useState(undefined);
  const [pictures, setPictures] = useState([]);
  const [defaultImageArray, setDefaultImageArray] = useState(undefined);

  const [pdfs, setPdfs] = useState([]);
  const [urlPdfs, setUrlPdfs] = useState("");

  useEffect(() => {
    getProductInfo();
    // eslint-disable-next-line
  }, []);

  const getProductInfo = () => {
    axios
      .post(`${PRODUCT_SERVER}/getproductbyid`, {
        productid: props.location.state.productid,
      })
      .then((data) => {
        if (data.data.success) {
          let product = data.data.product;
          setProductInfo(product);
          setProductName(product.productName);
          setProductDescription(product.description);
          setSpec(product.spec);
          setSupport(product.support);
          setShowOnHome(product.showOnHome);

          if (product.imageUrls !== null && product.imageUrls !== undefined) {
            // get array from imageurl string
            let imageUrlArray = product.imageUrls.split(",");
            imageUrlArray.splice(-1);

            // add "/product_pics" to every element in array.
            let tempArray = [];
            for (let i in imageUrlArray) {
              tempArray.push("/product_pics/" + imageUrlArray[i]);
            }
            setDefaultImageArray(tempArray);
            // setPictures(tempArray);
          }
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onChangeProductName = (e) => {
    setProductName(e.target.value);
  };

  const onChangeShowOnHome = (e) => {
    setShowOnHome(e.target.checked);
  };

  const handleDescChange = (content) => {
    setProductDescription(content);
  };

  const handleSpecChange = (content) => {
    setSpec(content);
  };

  const handleSupportChange = (content) => {
    setSupport(content);
  };

  const onChangePictures = (e) => {
    let fileObj = [...pictures, ...e.target.files];
    let tempArr = [];
    for (let i = 0; i < fileObj.length; i++) {
      tempArr.push(URL.createObjectURL(fileObj[i]));
    }
    setPictures(fileObj);
  };

  const onClickCloseDefault = (url) => {
    let temp = [...defaultImageArray];
    let removeIndex = temp.findIndex((ele) => ele === url);
    let filtered = temp.filter((e, index) => index !== removeIndex);
    setDefaultImageArray(filtered);
  };

  const onClickCloseImage = (picture) => {
    let temp = [...pictures];
    let removeIndex = temp.findIndex((ele) => ele === picture);
    let filtered = temp.filter((e, index) => index !== removeIndex);
    setPictures(filtered);
  };

  const onFormSubmit = (e) => {
    setAddErrorMessage("");
    e.preventDefault();
    if (productName === "") {
      setAddErrorMessage("Product name should be input.");
      return;
    }

    const myData = new FormData();

    let defaultImage = [];
    if (defaultImageArray !== undefined && defaultImageArray !== [])
      for (let ele of defaultImageArray) {
        defaultImage.push(ele.replace("/product_pics/", ""));
      }

    myData.append("id", productInfo.id);
    myData.append("productName", productName);
    myData.append("productDescription", productDescription);
    myData.append("spec", spec);
    myData.append("support", support);
    myData.append("defaultImage", defaultImage);
    myData.append("showOnHome", showOnHome);

    for (let picture of pictures) {
      myData.append("myPictures[]", picture);
    }

    axios
      .post(`${PRODUCT_SERVER}/updateproductwithfile`, myData, {})
      .then((data) => {
        if (data.data.success) {
          setToastText(data.data.message);
          setShowToast(true);
        } else {
          if (data.data.error.name === "SequelizeUniqueConstraintError")
            setAddErrorMessage("Product name should be unique.");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onChangePdf = (e) => {
    setPdfs(e.target.files);
  };

  const onClickGetPdfUrl = () => {
    const myData = new FormData();
    for (let pdf of pdfs) {
      myData.append("myPdfs[]", pdf);
    }
    axios
      .post(`${FILZERDOC_SERVER}/getpdfurl`, myData, {})
      .then((data) => {
        if (data.data.success) {
          let arrNames = data.data.pdfNames.split(",");
          arrNames.splice(-1);
          let arrUrlPdfs = [];
          for (let i = 0; i < arrNames.length; i++) {
            arrUrlPdfs.push(
              <span key={i}>
                /product_pdfs/{arrNames[i]}
                <br />
              </span>
            );
          }
          setUrlPdfs(arrUrlPdfs);
        } else {
          console.log("Oops, can't get url of pdf");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  ////////////////////////////// Hamburger menu  ////////////////////////////////////////////////
  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu
      ? setMainClass("body_panel col-sm-10")
      : setMainClass("body_panel col-sm-12");
  };

  return productInfo === undefined ? null : (
    <>
      <NavBar updateHamburger={updateHamburger} />
      {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
      <div className="mainpanel container-fluid">
        <div className="row">
          {propShowMenu ? <MenuBar /> : null}

          <Toast
            delay={3000}
            autohide
            show={showToast}
            onClose={() => setShowToast(false)}
            style={{
              position: "fixed",
              top: 10,
              right: 10,
              paddingLeft: 20,
              zIndex: 8,
              width: 400,
              borderTop: "4px solid #F47721",
            }}
          >
            <Toast.Body>{toastText}</Toast.Body>
          </Toast>
          <div className={mainClass}>
            <div className="page_title">Edit product</div>
            <div className="page_content">
              <Form onSubmit={onFormSubmit}>
                {addErrorMessage && (
                  <label>
                    <p
                      style={{
                        color: "#EB5757",
                        fontSize: "14px",
                        marginTop: "-10px",
                      }}
                    >
                      {addErrorMessage}
                    </p>
                  </label>
                )}
                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Product name</Form.Label>
                    <Form.Control
                      value={productName}
                      name="productName"
                      onChange={(e) => {
                        onChangeProductName(e);
                      }}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Editor
                    apiKey={TINYMCE_EDITOR_KEY}
                    init={{
                      selector: "textarea",
                      height: 270,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table contextmenu paste code",
                      ],
                      toolbar:
                        "undo redo | insert | styleselect | bold italic underline| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                      // content_css: [
                      //   "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i",
                      //   "//www.tinymce.com/css/codepen.min.css",
                      // ],
                      theme: "silver",
                      convert_urls: false,
                    }}
                    //initialValue={productDescription}
                    value={productDescription}
                    onEditorChange={(e) => handleDescChange(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Specs</Form.Label>
                  <Editor
                    apiKey={TINYMCE_EDITOR_KEY}
                    init={{
                      selector: "textarea",
                      height: 270,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table contextmenu paste code",
                      ],
                      toolbar:
                        "undo redo | insert | styleselect | bold italic underline| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                      theme: "silver",
                      convert_urls: false,
                    }}
                    //initialValue={spec}
                    value={spec}
                    onEditorChange={(e) => handleSpecChange(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Support</Form.Label>
                  <Editor
                    apiKey={TINYMCE_EDITOR_KEY}
                    init={{
                      selector: "textarea",
                      height: 270,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table contextmenu paste code",
                      ],
                      toolbar:
                        "undo redo | insert | styleselect | bold italic underline| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
                      theme: "silver",
                      convert_urls: false,
                    }}
                    //initialValue={support}
                    value={support}
                    onEditorChange={(e) => handleSupportChange(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <label className="btnLabel" htmlFor="imagefiles">
                    Choose images
                  </label>
                  <span style={{ marginLeft: "10px" }}>
                    Note: Use image size 640*480.
                  </span>
                  <input
                    type="file"
                    id="imagefiles"
                    style={{ visibility: "hidden" }}
                    accept="image/png, image/jpeg, image/gif"
                    onChange={(e) => {
                      onChangePictures(e);
                    }}
                    multiple
                  />
                  <div className="productimagelist">
                    {(defaultImageArray || []).map((url) => (
                      <div key={url} className="productimage">
                        <div
                          className="closeimage"
                          onClick={() => {
                            onClickCloseDefault(url);
                          }}
                        >
                          &times;
                        </div>
                        <img src={url} alt="productImage" />
                      </div>
                    ))}
                    {(pictures || []).map((picture) => (
                      <div
                        key={URL.createObjectURL(picture)}
                        className="productimage"
                        onClick={() => {
                          onClickCloseImage(picture);
                        }}
                      >
                        <div className="closeimage">&times;</div>
                        <img
                          src={URL.createObjectURL(picture)}
                          alt="productImage"
                        />
                      </div>
                    ))}
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Row>
                    <label className="btnLabel" htmlFor="pdffiles">
                      Choose pdf files
                    </label>
                    <input
                      id="pdffiles"
                      type="file"
                      style={{ visibility: "hidden" }}
                      multiple
                      accept="application/pdf"
                      onChange={(e) => {
                        onChangePdf(e);
                      }}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Button
                      className="normalButton"
                      type="submit"
                      style={{ float: "right", marginTop: "10px" }}
                      onClick={() => {
                        onClickGetPdfUrl();
                      }}
                    >
                      Get pdf url
                    </Button>
                    <div style={{ paddingLeft: "10px" }}>{urlPdfs}</div>
                  </Form.Row>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Show on Homepage</Form.Label>
                  <Form.Check
                    type="checkbox"
                    id="autoClear"
                    checked={showOnHome}
                    onChange={(e) => {
                      onChangeShowOnHome(e);
                    }}
                    label="Show this device on Homepage."
                  />
                </Form.Group>
                <Button className="normalButton" type="submit">
                  Save
                </Button>
                <Button
                  className="cancelButton"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    props.history.goBack();
                  }}
                >
                  Back
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
