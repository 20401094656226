import React, { useRef, useEffect, useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import axios from "axios";
import { SETTING_SERVER } from "../../Config";
import { eventDescriptions } from "../../DeviceEvents";
export const CanvasModal = (props) => {
  const canvasModalRef = useRef(null);
  const [showingImageDate, setShowingImageDate] = useState("");
  const [showingImageEvent, setShowingImageEvent] = useState("");
  const [userTimezone, setuserTimezone] = useState("America/Halifax");

  useEffect(() => {
    getUserTimezone();
    getImageEventandDate(props.createdAt, props.event, props.deviceModel);
    updateModalCanvas();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getImageEventandDate(props.createdAt, props.event, props.deviceModel);
  }, [userTimezone]);

  const getUserTimezone = () => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    axios
      .post(`${SETTING_SERVER}/getorcreatebyuserid`, {
        userId: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          if ("timeZone" in data.data.setting) {
            if (data.data.setting.timeZone != null) {
              setuserTimezone(data.data.setting.timeZone);
            }
          }
        }
      })
  }

  const updateModalCanvas = () => {
    const canvas = canvasModalRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = props.filename;
    let arrBox = props.box.split(",");
    let arrClass = props.class.split(",");
    let chunksArray = [];
    for (let i = 0; i < arrBox.length; i += 4) {
      chunksArray.push(arrBox.slice(i, i + 4));
    }
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 1;
      for (let item of chunksArray) {
        ctx.beginPath();
        ctx.font = "20px sans-serif";
        let randomColor =
          "#" +
          (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
        ctx.strokeStyle = randomColor;
        let index = chunksArray.indexOf(item);
        let xPos = parseInt(item[0]);
        xPos = xPos.toFixed();
        let yPos = parseInt(item[1]);
        yPos = yPos.toFixed();
        let xLen = parseInt(item[2]) - parseInt(item[0]);

        xLen = xLen.toFixed();
        let yLen = parseInt(item[3]) - parseInt(item[1]);

        yLen = yLen.toFixed();
        ctx.rect(xPos, yPos, xLen, yLen);
        ctx.fillStyle = randomColor;
        ctx.fillText(arrClass[index], xPos, yPos - 2);
        ctx.stroke();
      }
    };
  };

  const getImageDate = (dateTime) => {
    return dateTime.toLocaleString('en-US',
                 {
                  month: 'short', 
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                  timeZone: userTimezone
                });
  };

  const getEventDescription = (event, deviceModel) => {
    let eventDescription = '';
    if (event in eventDescriptions) {
      eventDescription = eventDescriptions[event];
    }
    if (deviceModel in eventDescriptions) {
      if (event in eventDescriptions[deviceModel]) {
        eventDescription = eventDescriptions[deviceModel][event];
      }
    }
    return eventDescription;
  };

  const getImageEventandDate = (createdAt, event, deviceModel) => {
    let dateTime = new Date(createdAt);
    let imageDate = getImageDate(dateTime);
    let eventDescription = getEventDescription(event, deviceModel);
    setShowingImageDate(imageDate);
    setShowingImageEvent(eventDescription);
  };

  return (
    <Modal
      show={true}
      onHide={() => {
        props.onClose();
      }}
      size="lg"
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>Device log</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0" }}>
        <Container>
          <Row>
            <Col className="text-center text-secondary">
              {showingImageDate}
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              {showingImageEvent}
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                padding: "0px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
                paddingBottom: "10px",
              }}
            >
              <canvas
                className="picModal"
                width={640}
                height={480}
                ref={canvasModalRef}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
