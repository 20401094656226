import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import FooterBar from "../FooterBar";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { PRODUCT_SERVER } from "../../Config";
import MenuBar from "../MenuBar";

export const ProductPage = () => {
  const [productList, setProductList] = useState(undefined);
  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");
  const [loggedIn, setLoggedIn] = useState(false);
  let history = useHistory();
  let user = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => {
    checkLoggedIn();
    getProductList();
    // eslint-disable-next-line
  }, []);

  const checkLoggedIn = () => {
    user === undefined || user === null
      ? setLoggedIn(false)
      : setLoggedIn(true);

    user === undefined || user === null
      ? setMainClass("body_panel col-sm-12")
      : setMainClass("body_panel col-sm-10");
  };

  const getProductList = () => {
    axios
      .post(`${PRODUCT_SERVER}/getallproducts`, {})
      .then((data) => {
        if (data.data.success) {
          setProductList(data.data.products);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getAllProducts = () => {
    let items = [];
    for (let i in productList) {
      let imageSrc;
      if (
        productList[i].imageUrls !== undefined &&
        productList[i].imageUrls !== null
      ) {
        let imageUrlArray = productList[i].imageUrls.split(",");
        imageUrlArray.splice(-1);
        imageSrc = "/product_pics/" + imageUrlArray[0];
      }

      items.push(
        <div
          key={i}
          className="products"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push({
              pathname: "/productdetail/" + productList[i].id,
              state: {
                productid: productList[i].id,
              },
            });
          }}
        >
          {productList[i].imageUrls !== undefined &&
          productList[i].imageUrls !== null ? (
            <img alt="mousetrap" src={imageSrc} />
          ) : null}
          <span style={{ width: "290px" }}>{productList[i].productName}</span>
        </div>
      );
    }
    return items;
  };

  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu && window.innerWidth > 981
    ? setMainClass("body_panel col-sm-10")
    : setMainClass("body_panel col-sm-12");
  };

  return (
    <>
      <NavBar updateHamburger={updateHamburger} />
      <div className="mainpanel container-fluid">
        <div className="row">
          {(propShowMenu && loggedIn) ? <MenuBar /> : null}
          <div className={mainClass} style={{padding: 0}}>
            <div className="myMain">
              {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
              <div className="terms">
                <div className="terms_main">
                  <div className="rect"></div>
                  <h1 className="termstitle">Products</h1>
                  <div className="productscontent">
                    <div className="productlist">
                      {productList === undefined ? null : getAllProducts()}
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <FooterBar />
          </div>
        </div>
      </div>
    </>
  );
};