import React, { useState, useReducer, useEffect } from "react";
import { Form, Col, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { DEVICE_SERVER } from "../../Config";
import { SETTING_SERVER } from "../../Config";
import { Autocomplete } from "@react-google-maps/api";

import AddressMap from "./AddressMap";

function AddNewDevice(props) {
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      deviceid: "",
      localip: "",
      devicename: "",
      position: "",
    }
  );

  const [adderrormessage, setAddErrorMessage] = useState("");
  const [addsuccessmessage, setAddSuccessMessage] = useState("");

  const [address, setAddress] = useState("Toronto, ON, Canada");
  const [markerPosition, setMarkerPosition] = useState({
    lat: 43.842466,
    lng: -79.423213,
  });

  const [autocomplete, setAutocomplete] = useState(null);

  let user = JSON.parse(window.localStorage.getItem("user"));

  const getSetting = () => {
    axios
      .post(`${SETTING_SERVER}/getorcreatebyuserid`, {
        userId: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          //let settings = data.data.setting[0];
          let settings = data.data.setting;
          if(settings.useDefaultAddress) {
            setAddress(settings.defaultAddress);
            setMarkerPosition({
              lat: parseFloat(settings.lat),
              lng: parseFloat(settings.lng),
            });
          } else {
            setAddress("");
          }
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  useEffect(() => {
    getSetting();
  }, []);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      let place = autocomplete.getPlace();
      let address = place.formatted_address,
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();

      setAddress(address);
      setMarkerPosition({
        lat: latValue,
        lng: lngValue,
      });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    setUserInput({ [name]: e.target.value });
  };

  const initErrorMessages = () => {
    setAddErrorMessage((adderrormessage) => "");
    setAddSuccessMessage((addsuccessmessage) => "");
  };

  const onSubmitAddNewDevice = () => {
    initErrorMessages();
    let users = JSON.parse(window.localStorage.getItem("user"));

    axios
      .post(`${DEVICE_SERVER}/adddevice`, {
        owner: users.id,
        deviceid: userInput.deviceid,
        devicename: userInput.devicename,
        address: address,
        position: userInput.position,
        lat: markerPosition.lat,
        lng: markerPosition.lng,
      })
      .then((data) => {
        if (!data.data.success) {
          setAddErrorMessage((adderrormessage) => data.data.message);
        } else if (data.data.success) {
          setAddSuccessMessage((addsuccessmessage) => data.data.message);
          props.updateDeviceList(data.data.device);
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onCloseModal = () => {
    setUserInput({ deviceid: "", devicename: "", address: "", position: "" });
    initErrorMessages();
    props.onClickShowAddDeviceModal();
  };

  return (
    <>
      <Modal show={props.show} onHide={onCloseModal}>
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Add new device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {adderrormessage && (
            <label>
              <p
                style={{
                  color: "#EB5757",
                  fontSize: "14px",
                  marginTop: "-10px",
                }}
              >
                {adderrormessage}
              </p>
            </label>
          )}
          {addsuccessmessage && (
            <label>
              <p
                style={{ color: "green", fontSize: "14px", marginTop: "-10px" }}
              >
                {addsuccessmessage}
              </p>
            </label>
          )}
          <Form.Row>
            <Form.Group as={Col} sm={6}>
              <Form.Label>Device ID</Form.Label>
              <Form.Control
                value={userInput.deviceid}
                name="deviceid"
                onChange={handleChange}
              />
            </Form.Group>
            {/* <Form.Group as={Col} sm={6}>
              <Form.Label>Local IP</Form.Label>
              <Form.Control value={userInput.localip} name='localip' readOnly />
            </Form.Group> */}
          </Form.Row>

          <Form.Group>
            <Form.Label>Device name</Form.Label>
            <Form.Control
              value={userInput.devicename}
              name="devicename"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Address</Form.Label>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <input
                type="text"
                placeholder="Enter your device address"
                style={{
                  width: "100%",
                  // height: '40px',
                  height: "calc(1.5em + .75rem + 2px)",
                  padding: ".75rem",
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#495057",
                  backgroundColor: "#fff",
                  backgroundClip: "paddingBox",
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  transition:
                    "borderColor .15s easeInOut,boxShadow .15s easeInOut",
                }}
                defaultValue={address}
              />
            </Autocomplete>
          </Form.Group>

          <Form.Group>
            <AddressMap
              center={markerPosition}
              mapContainerStyle={{
                width: "100%",
                height: "200px",
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Position</Form.Label>
            <Form.Control
              value={userInput.position}
              name="position"
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer
          style={{ borderTop: "none", justifyContent: "flex-start" }}
        >
          <Button className="normalButton" onClick={onSubmitAddNewDevice}>
            Add device
          </Button>
          <Button className="cancelButton" onClick={onCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddNewDevice;