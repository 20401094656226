import React, { useState, useReducer, useEffect } from "react";
import NavBar from "../NavBar";
import { Form, Button, Toast } from "react-bootstrap";
import FooterBar from "../FooterBar";
import axios from "axios";
import { CONTACTUS_SERVER } from "../../Config";
import MenuBar from "../MenuBar";

export const ContactusPage = (props) => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");
  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");
  const [loggedIn, setLoggedIn] = useState(false);
  let user = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => {
    checkLoggedIn();
    if (props.location.state !== undefined && props.location.state.fromRD) {
      setUserInput({ subject: "FilzerTech - Demo Request" });
      setUserInput({ message: "I would like to get a Demo of the Traps." });
    }
    // eslint-disable-next-line
  }, []);

  const checkLoggedIn = () => {
    user === undefined || user === null
      ? setLoggedIn(false)
      : setLoggedIn(true);

    user === undefined || user === null
      ? setMainClass("body_panel col-sm-12")
      : setMainClass("body_panel col-sm-10");
  };

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      email: "",
      subject: "",
      message: "",
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    setUserInput({ [name]: e.target.value });
  };

  const onSendMessage = () => {
    axios
      .post(`${CONTACTUS_SERVER}/sendmessage`, {
        name: userInput.name,
        email: userInput.email,
        subject: userInput.subject,
        message: userInput.message,
      })
      .then((data) => {
        if (!data.data.success) {
          setToastText("Unable to send message.");
          setShowToast(true);
          setUserInput({ name: "", email: "", subject: "", message: "" });
        } else if (data.data.success) {
          setToastText("Successfully sent message.");
          setShowToast(true);
          setUserInput({ name: "", email: "", subject: "", message: "" });
        }
      })
      .catch(() => {
        setToastText("Unable to send message.");
        setShowToast(true);
        setUserInput({ name: "", email: "", subject: "", message: "" });
        console.log("Oops, request failed!");
      });
  };

  const onCloseToast = () => {
    setShowToast(false);
  };

  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu && window.innerWidth > 981
    ? setMainClass("body_panel col-sm-10")
    : setMainClass("body_panel col-sm-12");
  };

  return (
    <>
      <NavBar updateHamburger={updateHamburger}/>
      <div className="mainpanel container-fluid">
        <div className="row">
          {(propShowMenu && loggedIn) ? <MenuBar /> : null}
          <div className={mainClass} style={{padding: 0}}>
            <div className="myMain">
              {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
              <Toast
                delay={3000}
                autohide
                show={showToast}
                onClose={onCloseToast}
                style={{
                  position: "absolute",
                  top: 70,
                  right: 10,
                  paddingLeft: 20,
                  zIndex: 8,
                  width: 400,
                  borderTop: "4px solid #F47721",
                }}
              >
                <Toast.Body>{toastText}</Toast.Body>
              </Toast>
              <div className="terms">
                <div className="contactus_main">
                  <h1 className="contactusTitle">Contact us</h1>
                  <div className="contactusBody">
                    <span>
                      We try hard to get back to all emails within 24 hrs (business
                      days - weekends we take off).
                      <br />
                      If we don't get back to you - make sure you gave us your correct
                      email address!
                    </span>
                    <Form>
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          value={userInput.name}
                          name="name"
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          value={userInput.email}
                          name="email"
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          value={userInput.subject}
                          name="subject"
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          value={userInput.message}
                          name="message"
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Button className="normalButton" onClick={onSendMessage}>
                        Send message
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <FooterBar />
          </div>
        </div>
      </div>
    </>
  );
};