import React, { useState } from "react";
import { registerUser } from "../../../actions/user.actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Icon,
  // InlineIcon
} from "@iconify/react";
import eyeIcon from "@iconify/icons-mdi/eye";
import eyeOff from "@iconify/icons-mdi/eye-off";

import NavBar from "../NavBar";
import FooterBar from "../FooterBar";

import { Form, Input, Button, Checkbox } from "antd";

function SignupPage(props) {
  const dispatch = useDispatch();

  const [checkterms, setCheckTerms] = useState(false);
  // const [signupError, setSignupError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleCheckTerms = () => {
    setCheckTerms(!checkterms);
  };

  //sign up for shared device from email
  let base64string1;
  let decodedString1;
  let decodedString2;
  if (window.location.search) {
    let search = window.location.search;
    let _params = new URLSearchParams(search);
    base64string1 = _params.get("uu");
    //let bufferObj1 = Buffer.from(base64string1, "base64");
    //decodedString1 = bufferObj1.toString("utf8"); //email

    //deviceid of shared from other's invite
    let base64string2 = _params.get("di");
    let bufferObj2 = Buffer.from(base64string2, "base64");
    decodedString2 = bufferObj2.toString("utf8");
  }

  const handleShowPassword = (isShow) => {
    setShowPassword(isShow);
  };

  return (
    <>
      <NavBar />
      <div className="myMain">
        <Formik
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
            checkterms: false,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email is invalid")
              .required("Email is required"),
            password: Yup.string()
              .min(8, "Password must be at least 8 characters")
              .matches(
                /(?=.*[a-zA-Z])(?=.*\d)/,
                "Password must be at least 8 characters with one capital and one number."
              )
              .required("Password is required"),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("password"), null], "Passwords must match")
              .required("Confirm Password is required"),
            // checkterms: Yup.boolean()
            //   .oneOf([true], "You must accept the terms and conditions");
          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log("clicked signup");
            setTimeout(() => {
              let dataToSubmit = {
                email: values.email,
                password: values.password,
                deviceid: decodedString2, //sign up for shared device from email
                uu: base64string1,
              };

              dispatch(registerUser(dataToSubmit)).then((response) => {
                if (response.payload === undefined) {
                  props.history.push({
                    pathname: "/verificationrequiredpage",
                    state: {
                      email: dataToSubmit["email"],
                    },
                  });
                } else if (response.payload.success) {
                  props.history.push({
                    pathname: "/thankssignuppage",
                    state: {
                      email: dataToSubmit["email"],
                    },
                  });
                }
              });

              setSubmitting(false);
            }, 500);
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <>
                <div className="loginpage">
                  <div className="mainlogin" style={{ height: "680px" }}>
                    <div className="rect" style={{}}></div>
                    <h2
                      style={{
                        paddingTop: "30px",
                        paddingLeft: "30px",
                        fontSize: "36px",
                      }}
                    >
                      Sign up
                    </h2>
                    <Form
                      style={{
                        minWidth: "375px",
                        padding: "30px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                      onSubmit={handleSubmit}
                    >
                      {/* {signupError && (
                        <div style={{ color: "red" }}>{signupError}</div>
                      )} */}
                      <Form.Item
                        required
                        validateStatus={
                          errors.email && touched.email ? "error" : "success"
                        }
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <label
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          Email
                        </label>
                        <Input
                          id="email"
                          placeholder="Enter your Email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            borderRadius: "4px",
                          }}
                          className={
                            errors.email && touched.email
                              ? "text-input error"
                              : "text-input"
                          }
                        />
                        {errors.email && touched.email && (
                          <div className="input-feedback">{errors.email}</div>
                        )}
                      </Form.Item>

                      <Form.Item
                        required
                        validateStatus={
                          errors.password && touched.password
                            ? "error"
                            : "success"
                        }
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <label
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          Password
                        </label>
                        <Input
                          id="password"
                          placeholder="Enter your password"
                          type={showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            borderRadius: "4px",
                          }}
                          className={
                            errors.password && touched.password
                              ? "text-input error"
                              : "text-input"
                          }
                          suffix={
                            showPassword ? (
                              <Icon
                                onClick={() => {
                                  handleShowPassword(false);
                                }}
                                icon={eyeIcon}
                                width="19"
                              />
                            ) : (
                              <Icon
                                onClick={() => {
                                  handleShowPassword(true);
                                }}
                                icon={eyeOff}
                                width="19"
                              />
                            )
                          }
                        />
                        {errors.password && touched.password && (
                          <div className="input-feedback">
                            {errors.password}
                          </div>
                        )}
                      </Form.Item>

                      <Form.Item
                        required
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <label
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                          }}
                        >
                          Re-enter Password
                        </label>
                        <Input
                          id="confirmPassword"
                          placeholder="Re-enter password to confirm"
                          type={showPassword ? "text" : "password"}
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            borderRadius: "4px",
                          }}
                          className={
                            errors.confirmPassword && touched.confirmPassword
                              ? "text-input error"
                              : "text-input"
                          }
                        />
                        {errors.confirmPassword && touched.confirmPassword && (
                          <div className="input-feedback">
                            {errors.confirmPassword}
                          </div>
                        )}
                      </Form.Item>

                      <Form.Item>
                        <Checkbox
                          id="checkterms"
                          onClick={handleCheckTerms}
                          checked={checkterms}
                        >
                          I agree to FilzerTech{" "}
                          <Link
                            to="/term"
                            style={{
                              color: "#F47721",
                            }}
                          >
                            Term of service
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/privacypolicy"
                            style={{
                              color: "#F47721",
                            }}
                          >
                            {" "}
                            Privacy polocy.
                          </Link>
                        </Checkbox>
                        {!checkterms && touched.checkterms && (
                          <div className="input-feedback">
                            You must accept the terms and conditions
                          </div>
                        )}
                        <Button
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                          style={{
                            minWidth: "100%",
                            backgroundColor: "#F47721",
                            borderRadius: "4px",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            marginTop: "30px",
                          }}
                        >
                          Sign up
                        </Button>
                        <div
                          className="rect"
                          style={{
                            margin: "30px 0px",
                          }}
                        ></div>
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Already have an account?
                        </label>
                        <a
                          style={{
                            color: "#F47721",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          href="/signin"
                        >
                          Login now!
                        </a>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
      <FooterBar />
    </>
  );
}

export default SignupPage;
