import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import { Tabs, Tab } from "react-bootstrap";
import FooterBar from "../FooterBar";
import { PRODUCT_SERVER } from "../../Config";
import axios from "axios";
import MenuBar from "../MenuBar";

import { useParams } from "react-router-dom";

export const ProductDetailPage = (props) => {
  const [productInfo, setProductInfo] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [imageUrlArray, setImageUrlArray] = useState(undefined);
  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");
  const [loggedIn, setLoggedIn] = useState(false);
  let user = JSON.parse(window.localStorage.getItem("user"));

  const productIdParam = useParams().id;

  useEffect(() => {
    checkLoggedIn();
    getProductInfo();
    // eslint-disable-next-line
  }, []);

  const checkLoggedIn = () => {
    user === undefined || user === null
      ? setLoggedIn(false)
      : setLoggedIn(true);

    user === undefined || user === null
      ? setMainClass("body_panel col-sm-12")
      : setMainClass("body_panel col-sm-10");
  };

  const getProductInfo = () => {
    axios
      .post(`${PRODUCT_SERVER}/getproductbyid`, {
        //productid: props.location.state.productid,
        productid: productIdParam,
      })
      .then((data) => {
        if (data.data.success) {
          setProductInfo(data.data.product);

          // get array from imageurl string
          let imageUrlArray = data.data.product.imageUrls.split(",");
          imageUrlArray.splice(-1);
          setImageUrlArray(imageUrlArray);

          if (imageUrlArray.length > 0) setSelectedImageUrl(imageUrlArray[0]); //set first tiled image
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getThumbs = () => {
    let item = [];
    for (let i in imageUrlArray) {
      let imageUrl = imageUrlArray[i];
      item.push(
        <img
          key={i}
          alt="product thumb"
          style={{ width: "25%", cursor: "pointer", padding: "2px" }}
          src={"/product_pics/" + imageUrl}
          onClick={() => setSelectedImageUrl(imageUrl)}
        />
      );
    }
    return item;
  };

  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu && window.innerWidth > 981
    ? setMainClass("body_panel col-sm-10")
    : setMainClass("body_panel col-sm-12");
  };

  return productInfo === null ? null : (
    <>
      <NavBar updateHamburger={updateHamburger}/>
      <div className="mainpanel container-fluid">
        <div className="row">
          {(propShowMenu && loggedIn) ? <MenuBar /> : null}
          <div className={mainClass} style={{padding: 0}}>
            <div className="myMain">
              {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
              <div className="terms">
                <div className="terms_main">
                  <h1 className="termstitle">{productInfo.productName}</h1>
                  <div
                    className="productscontent"
                    style={{ height: "600px", overflowY: "scroll" }}
                  >
                    <div className="productlist">
                      <div className="col-sm-5">
                        <div className="tile">
                          <img
                            alt="Product tile"
                            src={"/product_pics/" + selectedImageUrl}
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="thumbs">
                          {imageUrlArray === undefined ? null : getThumbs()}
                        </div>
                      </div>
                      <div className="col-sm-7">
                        <Tabs defaultActiveKey="desc" transition={false}>
                          <Tab
                            eventKey="desc"
                            title="Description"
                            tabClassName="sub_menus"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: productInfo.description,
                              }}
                            />
                          </Tab>
                          <Tab eventKey="spec" title="Specs" tabClassName="sub_menus">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: productInfo.spec,
                              }}
                            />
                          </Tab>
                          <Tab
                            eventKey="support"
                            title="Support"
                            tabClassName="sub_menus"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: productInfo.support,
                              }}
                            />
                          </Tab>
                        </Tabs>
                        {/* <>
                          <span className="prodSepName">Description</span>
                          <div className="prodSepLine"></div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: productInfo.description,
                            }}
                          />
                          />
                        </> */}
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <FooterBar />
          </div>
        </div>
      </div>
    </>
  );
};