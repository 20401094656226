import { WS_SERVER_URL } from "./components/Config";

let ws = null;
let userId = null;
let browserId = null;
let deviceId = null;

let callbacks = {};
let toSendJson = {};
let toSend = false;
let isSockCbRegistered = false;
let sockCbPageName = "";

const getID = () => {
  // Math.random should be unique because of its seeding algorithm.
  return Math.floor(Math.random() * 10e8);
  // return '_' + Math.random().toString(36).substr(2, 9);
};

export const getIsSockCbRegistered = (pageName, deviceid) => {
  if (sockCbPageName === pageName) {
    if (deviceid === null) return isSockCbRegistered;
    else {
      if (deviceid === deviceId) return isSockCbRegistered;
      else deviceId = deviceid;
    }
  }
  return false;
};

export const setIsSockCbRegistered = (pageName, isRegistered) => {
  sockCbPageName = pageName;
  isSockCbRegistered = isRegistered;
};

export const sendJsonByWebSocket = (conn, deviceId, json) => {
  json.userId = userId;
  json.browserId = browserId;
  json.receiver = deviceId;
  if (conn.readyState === WebSocket.OPEN) {
    conn.send(JSON.stringify(json));
  } else console.log("WebSocket's readyState is not OPEN");
};

export const getWs = () => {
  if (ws === null) {
    const users = JSON.parse(window.localStorage.getItem("user"));
    if (users === null || users === undefined) return null;
    userId = users.id;
    browserId = getID();
    window.localStorage.setItem("browserId", browserId);

    const request_url = `${WS_SERVER_URL}/path?userid=${userId}&browserid=${browserId}`;

    ws = new WebSocket(request_url, "filzertech");

    ws.onopen = () => {
      console.log(`Connected to ${request_url}`);
      let json = {
        userId: userId,
        browserId: browserId,
        receiver: "server",
        message: "browserConnected",
      };
      ws.send(JSON.stringify(json));
      if (toSend) {
        ws.send(JSON.stringify(toSendJson));
        toSend = false;
      }
    };

    ws.onclose = (reasonCode, description) => {
      console.log("WebSocket closed: reasonCode=" + reasonCode);
      ws = null;
      setTimeout(() => {
        getWs();
      }, 1000);
    };

    ws.onmessage = (message) => {
      Object.keys(callbacks).forEach((page) => {
        if (page === "camera" && typeof message.data === "object")
          callbacks[page](message);
      });
      if (typeof message.data === "string") {
        var resJson = JSON.parse(message.data); // Parse JSON message
        Object.keys(callbacks).forEach((page) => {
          // Handle server message
          // if (resJson.message === "takePicture" && page != "camera") {
          //   delete resJson.row.image;
          // }
          if (typeof message.data !== "object") callbacks[page](resJson);
        });
      }
    };
  }
  return ws;
};

export const registerEventHandler = (page, cb, json) => {
  callbacks[page] = cb;
  let ws = getWs();
  if (ws.readyState === WebSocket.OPEN) ws.send(JSON.stringify(json));
  else {
    toSend = true;
    toSendJson = json;
  }
};

export const unRegisterEventHandler = (page, json = null) => {
  if (callbacks[page]) delete callbacks[page];
  if (json !== null) {
    if (ws.readyState === WebSocket.OPEN) ws.send(JSON.stringify(json));
  }
};
