import React, { useState, useEffect } from "react";
// import { Redirect, useHistory } from "react-router-dom";
import {
  Tabs,
  Tab,
  Form,
  Col,
  Button,
  Toast,
  Container,
  Row,
  Modal,
  Badge,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import MenuBar from "../MenuBar";
import AddNewDevice from "./AddNewDevice";
import EditDevice from "./EditDevice";
import { BsPlus } from "react-icons/bs";
import { MdEdit, MdShare, MdClose } from "react-icons/md";
import {
  FaTrash,
  FaSortAmountDownAlt,
  FaEyeSlash,
  FaEye,
} from "react-icons/fa";
import { FiWifi, FiWifiOff } from "react-icons/fi";
import BootstrapTable from "react-bootstrap-table-next";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import { DEVICE_SERVER } from "../../Config";
import { SHAREDDEVICE_SERVER } from "../../Config";
import NavBar from "../NavBar";

import { Sharedwithme } from "./Sharedwithme";
import { Sharedbyme } from "./Sharedbyme";
import { Alldevices } from "./Alldevices";

import {
  getWs,
  registerEventHandler,
  unRegisterEventHandler,
} from "../../../common";

import ReactShowMoreLess from "./ReactShowMoreLess";
import { deviceTypes } from "../../DeviceTypes";
import ShareDeviceModal from "./ShareDeviceModal";


function DevicelistPage(props) {
  const [theDevice, setTheDevice] = useState(undefined);
  const [selectedDevicesArray, setSelectedDevicesArray] = useState([]);

  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);
  const [showEditDeviceModal, setShowEditDeviceModal] = useState(false);
  const [devicelists, setDeviceLists] = useState([]);
  const [deviceListForTable, setDeviceListForTable] = useState([]);
  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");
  const [activeKey, setActiveKey] = useState("alldevices");
//  const [sortField, setSortField] = useState("devid");
  const [sortField, setSortField] = useState("deff");
  const [sortValue, setSortValue] = useState("asc");
  const [showDeleteSelectedModal, setShowDeleteSelectedModal] = useState(false);

  /////// For Toast show
  const [showToast, setShowToast] = useState(false);
  const [deletedLength, setDeletedLength] = useState(0);

  //For disabled buttons
  let disabledSelected = selectedDevicesArray.length < 1 ? true : false;

  //For shareddevice
  const [shareddevicelistlength, setSharedDeviceListLength] = useState([]);
  const [showShowSelectedModal, setShowShareSelectedModal] = useState(false);
  const [shareEmail, setShareEmail] = useState("");
  const [onlineStatus, setOnlineStatus] = useState({});
  const [allDevicesLength, setAllDevicesLength] = useState(0);
  const [allDevices, setAllDevices] = useState([]);
  const [sharedBymeDeviceListLength, setSharedBymeDeviceListLength] =
    useState(0);

  //sharedDeviceList4One for shared icon in action buttons
  const [showShareOneModal, setShowShareOneModal] = useState(false);
  const [sharedDeviceList4One, setSharedDeviceList4One] = useState("");
  const [shareDeviceError, setShareDeviceError] = useState("");
  const [shareDeviceSuccess, setShareDeviceSuccess] = useState("");
  const [showDeleteOneModal, setShowDeleteOneModal] = useState(false);

  let user = JSON.parse(window.localStorage.getItem("user"));
  // let history = useHistory();
  // if (user === null || user === undefined) history.push("/signin");
  // props.history.push("/signin");
  // const isMounted = useRef(true);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (user == null) return null;
    getAvailableDeviceList();
    getDeviceList();
    getSharedDeviceLength();
    getSharedBymeDeviceListLength();

    if (window.innerWidth < 981) {
      setMainClass("body_panel col-sm-12");
      setIsMobile(true);
      setPropShowMenu(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user == null) return null;
    getAvailableDeviceList();
    getSharedBymeDeviceListLength();
    // eslint-disable-next-line
  }, [devicelists]);

  useEffect(() => {
    if (user == null) return null;
    getDeviceList();
    // eslint-disable-next-line
  }, [sortField, sortValue]);

  useEffect(() => {
    // getWebSocket();
    let ws = getWs();
    if (ws !== null) {
      const users = JSON.parse(window.localStorage.getItem("user"));
      const browserId = window.localStorage.getItem("browserId");
      const userId = users.id;

      let deviceIds = [];
      allDevices.forEach((device) => {
        deviceIds.push(device.id);
      });
      let json = {
        senderType: "browser",
        userId: userId,
        browserId: browserId,
        receiver: "server",
        deviceIds: deviceIds,
        message: "registerBrowser",
        page: "devicelist",
      };
      registerEventHandler("devicelist", onmessage, json);
    }

    return () => {
      unRegisterEventHandler("devicelist");
    };
    // eslint-disable-next-line
  }, [allDevices]);

  const getDeviceList = () => {
    axios
      .post(`${DEVICE_SERVER}/getdevicebyowner`, {
        owner: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          let nostatedevicelists = data.data.devices;
          if (sortField === "deff" && sortValue === "asc")
            nostatedevicelists.sort(
              //(a, b) => a.devicestatus - b.devicestatus || a.unreadcount - b.unreadcount
              (a, b) => parseFloat(a.id) - parseFloat(b.id)
            );
          else if (sortField === "devid" && sortValue === "asc")
            nostatedevicelists.sort(
              (a, b) => parseFloat(a.id) - parseFloat(b.id)
            );
          else if (sortField === "devid" && sortValue === "desc")
            nostatedevicelists.sort(
              (a, b) => parseFloat(b.id) - parseFloat(a.id)
            );
          else if (sortField === "activity" && sortValue === "asc")
            nostatedevicelists.sort((a, b) => a._count.trapEventNotifications - b._count.trapEventNotifications);
          else if (sortField === "activity" && sortValue === "desc")
            nostatedevicelists.sort((a, b) => b._count.trapEventNotifications - a._count.trapEventNotifications);
          else if (sortField === "devicename" && sortValue === "asc")
            nostatedevicelists.sort((a, b) =>
              ("" + a.deviceName).localeCompare(b.deviceName)
            );
          else if (sortField === "devicename" && sortValue === "desc")
            nostatedevicelists.sort((a, b) =>
              ("" + b.deviceName).localeCompare(a.deviceName)
            );
          setDeviceLists(nostatedevicelists);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getSharedBymeDeviceListLength = () => {
    axios
      .post(`${DEVICE_SERVER}/getsharedbymedevice`, {
        owner: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          setSharedBymeDeviceListLength(data.data.devices.length);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getSharedDeviceLength = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/getshareddevicebyto`, {
        sharedto: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          setSharedDeviceListLength(data.data.shareddevices.length);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getAvailableDeviceList = () => {
    axios
      .post(`${DEVICE_SERVER}/getavailabledevices`, {
        userid: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          // add current status to the device info
          setAllDevicesLength(data.data.devices.length);
          setAllDevices(data.data.devices);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onmessage = (res) => {
    // Handle server message
    if (res.message === "registerBrowser") {
      let status = res.status;
      setOnlineStatus(status);

      let tempDeviceListForTable = JSON.parse(JSON.stringify(devicelists));
      for (let i in devicelists) {
        tempDeviceListForTable[i].deviceStatus =
          status[tempDeviceListForTable[i].id];
      }
      setDeviceListForTable(tempDeviceListForTable);
    } else if (res.message === "deviceConnected") {
      console.log("deviceConnected");
    } else if (res.message === "deviceDisconnected") {
      console.log("deviceDisconnected");
    } else if (res.message === "deviceStatus") {
      getAvailableDeviceList();
      // // getDeviceList();
      // let tempDeviceListForTable = JSON.parse(
      //   JSON.stringify(deviceListForTable)
      // );
      // for (let i in tempDeviceListForTable) {
      //   if (tempDeviceListForTable[i].deviceid === res.sender) {
      //     tempDeviceListForTable[i].devicestatus = res.status;
      //   }
      // }
      // console.log(tempDeviceListForTable);
      // let status = {};
      // status[res.sender] = res.status;
      // setOnlineStatus(status);
      // setDeviceListForTable(tempDeviceListForTable);
    } else if (res.message === "newEvent") {
      getAvailableDeviceList();
      // getDeviceList();
      // let news = newsBadge.innerHTML;
      // news = parseInt(news) || 0;
      // news = news + 1;
      // newsBadge.innerHTML = news.toString();
      // } else if (res.message === "statusReq") {
      //   var result = res.status;
    }
  };

  let title1 = "All devices(" + allDevicesLength + ")";
  let title2 = "My devices(" + devicelists.length + ")";
  let title3 = "Shared with me(" + shareddevicelistlength + ")";
  let title4 = "Shared by me(" + sharedBymeDeviceListLength + ")";

  const onClickShowAddDeviceModal = () => {
    setShowAddDeviceModal((showAddDeviceModal) => !showAddDeviceModal);
  };

  const onClickShowEditDeviceModal = () => {
    setShowEditDeviceModal((showEditDeviceModal) => !showEditDeviceModal);
  };

  //Update device list after Add new.
  const updateDeviceList = () => {
    getDeviceList();
  };

  //Update device list after Edit List
  const updateEditedDeviceList = () => {
    getDeviceList();
  };

  //Update device list after Delete List in Shared Device List
  const updateSharedDeviceListLength = (deletedshareddevicelength) => {
    // setSharedDeviceListLength(
    //   (shareddevicelistlength) =>
    //     shareddevicelistlength - deletedshareddevicelength
    // );
    getAvailableDeviceList();
    getDeviceList();
    getSharedDeviceLength();
    getSharedBymeDeviceListLength();
  };

  //Update device list after Delete List in AllDeviceList
  const updateAllDeviceListLength = () => {
    getAvailableDeviceList();
    getDeviceList();
    getSharedDeviceLength();
    getSharedBymeDeviceListLength();
  };

  //Update Sharedbycount from child's updates
  // const updateSharedBymeDeviceListLength = () => {
  //   getSharedBymeDeviceListLength();
  // };

  const onClickDeleteSeleted = () => {
    selectedDevicesArray.forEach((row) => {
      axios
        .post(`${DEVICE_SERVER}/removeonedevice`, {
          deviceid: row.deviceid,
          owner: row.owner,
        })
        .then((data) => {
          if (data.data.success) {
            getDeviceList();
            setDeletedLength(selectedDevicesArray.length);
            setShowToast(true);
          } else {
            console.log("unsuccess");
          }
        })
        .catch(() => {
          console.log("Oops, request failed!");
        });
    });
    setSelectedDevicesArray([]);
    setShowToast(true);
    setShowDeleteSelectedModal(false);
  };

  const onCloseShareModal = () => {
    // setShareDeviceError('');
    // setShareSelectedDeviceSuccess('');
    setShareEmail("");
    setShowShareSelectedModal(false);
  };

  const onCloseShareOneModal = () => {
    setShowShareOneModal(false);
  };

  const closeSharedDeviceModal = () => {
    setShowShareOneModal(false);
  };

  const handleShareEmailChange = (e) => {
    setShareEmail(e.target.value);
  };

  const onClickShareSeleted = () => {
    setShowShareSelectedModal(true);
  };

  const onClickShareOnSelected = () => {
    selectedDevicesArray.forEach((row) => {
      // setShareDeviceError('');
      // setShareSelectedDeviceSuccess('');
      axios
        .post(`${SHAREDDEVICE_SERVER}/addshareddevice`, {
          deviceid: row.id,
          owner: row.ownerId,
          email: shareEmail,
        })
        .then((data) => {
          if (data.data.success) {
            // setShareSelectedDeviceSuccess(data.data.message);
            getDeviceList();
            console.log("Success");
          } else {
            // setShareDeviceError(data.data.message);
            console.log("Unsuccess");
          }
        })
        .catch(() => {
          console.log("Oops, request failed!");
        });
    });
    setShowShareSelectedModal(false);
    getSharedBymeDeviceListLength();
    // setSelectedDevicesArray([]);
  };

  const getModalSharedDeviceList = (deviceid, owner) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/getbydeviceidowner`, {
        deviceid,
        owner,
      })
      .then((data) => {
        if (data.data.success) {
          setSharedDeviceList4One(data.data.shareddevices);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onClickShareOne = () => {
    setShareDeviceError("");
    setShareDeviceSuccess("");
    console.log(sharedDeviceList4One);
    axios
      .post(`${SHAREDDEVICE_SERVER}/addshareddevice`, {
        deviceid: theDevice.id,
        owner: theDevice.ownerId,
        email: shareEmail,
      })
      .then((data) => {
        if (data.data.success) {
          getModalSharedDeviceList(theDevice.id, theDevice.ownerId);
          setShareDeviceSuccess(data.data.message);
          getSharedBymeDeviceListLength();
        } else {
          setShareDeviceError(data.data.message);
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const createSharedList = () => {
    let items = [];
    for (let i in sharedDeviceList4One) {
      let shared = sharedDeviceList4One[i];
      let isPending =
        shared.sharedto < 0 || shared.status === "pending" ? "Pending" : "";
      let nameValue;
      if (shared.firstname === null && shared.lastname === null)
        nameValue = shared.email;
      else if (shared.firstname === null) nameValue = shared.lastname;
      else if (shared.lastname === null) nameValue = shared.firstname;
      else nameValue = shared.firstname + " " + shared.lastname;
      items.push(
        <Row key={i} className="sharemodallist">
          <Col sm={6}>{nameValue}</Col>
          <Col sm={3}>{isPending}</Col>
          <Col sm={3} className="devdetailright">
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                onClickRemoveShared4One(
                  shared.deviceid,
                  shared.sharedto,
                  shared.owner
                )
              }
            >
              <MdClose /> Remove
            </Button>
          </Col>
        </Row>
      );
    }
    return items;
  };

  const onClickRemoveShared4One = (deviceid, sharedto, owner) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/removebydeviceidsharedto`, {
        deviceid,
        sharedto,
      })
      .then((data) => {
        if (data.data.success) {
          getModalSharedDeviceList(deviceid, owner); //refresh modal
          getDeviceList();
          getSharedBymeDeviceListLength();
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onClickRemoveAll = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/removebydeviceidall`, {
        deviceid: theDevice.deviceid,
        owner: theDevice.owner,
      })
      .then((data) => {
        if (data.data.success) {
          // setSharedDeviceList([]);
          getDeviceList();
          setShowShareOneModal(false);
          getSharedBymeDeviceListLength();
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  ////////////////////////////////////////----------Table Config--------/////////////////////////////////////////////////
  const idHeaderFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        ID <FaSortAmountDownAlt />
      </div>
    );
  };

  function StatusHelper(props) {
    let status = props.currentStatus;

    if (status === "online") {
      return (
        <FiWifi style={{ color: "#27AE60", width: "16px", height: "16px" }} />
      );
    } else if (status === "offline") {
      return (
        <FiWifiOff
          style={{ color: "#BDBDBD", width: "16px", height: "16px" }}
        />
      );
    }

  }

  function ActivityHelper(props) {
    let activity = props.currentActivity;

    if (activity === null) {
      return (
        <Badge
          variant="secondary"
          style={{
            fontSize: "18px",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
        >
          0
        </Badge>
      );
    } else if (activity > 0) {
      return (
        <Badge variant="danger" style={{ fontSize: "18px" }}>
          {activity}
        </Badge>
      );
    }
  }

  const idFormatter = (cell, row, rowIndex, formatExtraData) => {
    let divBorder = row.showonmap ? "1px solid #27AE60" : "1px solid #BDBDBD";
    let divBackgroundColor = row.showonmap ? "#27AE60" : "white";
    let divColor = row.showonmap ? "white" : "black";
    let model;
    model = `${deviceTypes[row.model]} (${row.model})`;

    let shortVersion = (
      <Row>
        <Col xs={8}>
          <p
            style={{
              textAlign: "left",
              marginBottom: 0,
            }}
          >
            <strong>Model: </strong>{model}<br/>
            <strong>Name: </strong>{row.deviceName}<br/>
          </p>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={6}
              style={{
                paddingInline: 0,
                textAlign: "end",
              }}
            >
              <ActivityHelper currentActivity={row._count.trapEventNotifications}/>
            </Col>
            <Col xs={6}
              style={{
                paddingInline: "8px",
                textAlign: "start",
              }}
            >
              <StatusHelper currentStatus={row.deviceStatus}/>
            </Col>
          </Row>
          <Row
            style={{
              marginRight: "-5px",
              marginLeft: "-28px",
              marginTop: "2px",
            }}
          >
            <Col xs={12}>
              <p>
                <strong>ID: </strong>{row.deviceCode}<br/>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    let fullVersion = (
      <Row>
        <Col xs={8}>
          <p
            style={{
              textAlign: "left",
              marginBottom: 0,
            }}
          >
            <strong>Model: </strong>{model}<br/>
            <strong>Name: </strong>{row.deviceName}<br/>
            <strong>Owner: </strong>{row.owner.firstName} {row.owner.lastName}<br/>
            <strong>Address: </strong>{row.address}<br/>
            <strong>Position: </strong>{row.position}<br/>
          </p>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={6}
              style={{
                paddingInline: 0,
                textAlign: "end",
              }}
            >
              <ActivityHelper currentActivity={row._count.trapEventNotifications}/>
            </Col>
            <Col xs={6}
              style={{
                paddingInline: "8px",
                textAlign: "start",
              }}
            >
              <StatusHelper currentStatus={row.deviceStatus}/>
            </Col>
          </Row>
          <Row
            style={{
              marginRight: "-5px",
              marginLeft: "-28px",
              marginTop: "2px",
            }}
          >
            <Col xs={12}>
              <p>
                <strong>ID: </strong>{row.deviceCode}<br/>
              </p>
            </Col>
          </Row>
          <Row>
            <Col 
              xs={6}
              style={{
                paddingRight: "2px",
              }}
            >
              <Button
                className="actionBtn"
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  borderRadius: "4px",
                  border: divBorder,
                  background: divBackgroundColor,
                  color: divColor,
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  axios
                  .post(`${DEVICE_SERVER}/setshowonmapbydeviceid`, {
                    deviceid: row.id,
                    owner: row.ownerId,
                  })
                  .then((data) => {
                    if (data.data.success) {
                      getDeviceList();
                    } else {
                      console.log("unsuccess");
                    }
                  })
                  .catch(() => {
                    console.log("Oops, request failed!");
                  });
                }}
              >
                {row.showonmap ? <FaEye /> : <FaEyeSlash color="disabled" />}
              </Button>
            </Col>
            <Col
              xs={6}
              style={{
                paddingLeft: "2px",
              }}
            >
              <Button 
                variant="outline-secondary"
                className="actionBtn"
                style={{
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTheDevice(row);
                  setShowDeleteOneModal(true);
                }}
              >
                <FaTrash />
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "4px",
            }}
          >
            <Col 
              xs={6}
              style={{
                paddingRight: "2px",
              }}
            >
              <Button
                className="normalButton actionBtn"
                style={{
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTheDevice(row);
                  onClickShowEditDeviceModal();
                }}
              >
                <MdEdit />
              </Button>
            </Col>
            <Col
              xs={6}
              style={{
                paddingLeft: "2px",
              }}
            >
              <Button
                variant="outline-secondary"
                className="actionBtn"
                style={{
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTheDevice(row);
                  getModalSharedDeviceList(row.id, row.ownerId);
                  setShowShareOneModal(true);
                  setShareDeviceError("");
                  setShareDeviceSuccess("");
                  setShareEmail("");
                }}
              >
                <MdShare />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    if (isMobile) {
      return (
        <ReactShowMoreLess
          shortVersion={shortVersion}
          fullVersion={fullVersion}
          readMoreText={"Show more ▼"}
          readLessText={"Show less ▲"}
          readMoreClassName="read-more-less--more"
          readLessClassName="read-more-less--less"
        />
      );
    } else {
      return (
        <>
          <span>{row.deviceCode}</span>
        </>
      );
    }
  };

  const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.deviceName}</span> }
      </>
    );
  };

  const addressFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.address}</span> }
      </>
    );
  };

  const positionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.position}</span> }
      </>
    );
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      if (cell === "online") {
        return (
          <FiWifi style={{ color: "#27AE60", width: "16px", height: "16px" }} />
        );
      } else if (cell === "offline") {
        return (
          <FiWifiOff
            style={{ color: "#BDBDBD", width: "16px", height: "16px" }}
          />
        );
      }
    } else {
      return null;
    }
  };

  const activityFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      /*if (cell === null) {
        return (
          <Badge
            variant="secondary"
            style={{
              fontSize: "18px",
              color: "#333333",
              backgroundColor: "#E0E0E0",
            }}
          >
            0
          </Badge>
        );
      } else if (cell > 0) {
        return (
          <Badge variant="danger" style={{ fontSize: "18px" }}>
            {cell}
          </Badge>
        );
      }*/
      if (cell) {
        return (
          <Badge variant="danger" style={{ fontSize: "18px" }}>
            {cell}
          </Badge>
        );
       } else {
        return (
          <Badge
            variant="secondary"
            style={{
              fontSize: "18px",
              color: "#333333",
              backgroundColor: "#E0E0E0",
            }}
          >
            0
          </Badge>
        );
       }
    } else {
      return null;
    }
  };

  const ownerFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {isMobile ? null : <span>{row.owner.firstName} {row.owner.lastName}</span> }
      </>
    );
  };

  const showonmapFormatter = (cell, row, rowIndex, formatExtraData) => {
    let divBorder = cell ? "1px solid #27AE60" : "1px solid #BDBDBD";
    let divBackgroundColor = cell ? "#27AE60" : "white";
    let divColor = cell ? "white" : "black";

    if (!isMobile) {
      return (
        <>
          <Button
            style={{
              textAlign: "center",
              cursor: "pointer",
              lineHeight: "normal",
              borderRadius: "4px",
              border: divBorder,
              background: divBackgroundColor,
              color: divColor,
            }}
          >
            {cell ? <FaEye /> : <FaEyeSlash color="disabled" />}
          </Button>
        </>
      );
    } else {
      return null;
    }
  };

  const modelFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      let model;
      model = `${deviceTypes[cell]} (${cell})`;
      
      return (
        <span>{model}</span>
      );
    } else {
      return null;
    }
  };

  const actFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!isMobile) {
      return (
        <div className="actionFormat">
          <Button
            className="normalButton actionBtn"
            style={{ borderRadius: "4px 0px 0px 4px" }}
            onClick={() => {
              setTheDevice(row);
              onClickShowEditDeviceModal();
            }}
          >
            <MdEdit />
          </Button>
          <Button
            variant="outline-secondary"
            className="actionBtn"
            style={{
              borderLeft: "none",
              borderRight: "none",
              borderRadius: "0px",
            }}
            onClick={() => {
              setTheDevice(row);
              getModalSharedDeviceList(row.id, row.ownerId);
              setShowShareOneModal(true);
              setShareDeviceError("");
              setShareDeviceSuccess("");
              setShareEmail("");
            }}
          >
            <MdShare />
          </Button>
          <Button
            variant="outline-secondary"
            className="actionBtn"
            style={{ borderRadius: "0px 4px 4px 0px" }}
            onClick={() => {
              setTheDevice(row);
              setShowDeleteOneModal(true);
            }}
          >
            <FaTrash />
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  const columns = [
    {
      dataField: "deviceStatus",
      text: "Status",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      style: { cursor: "pointer" },
      formatter: statusFormatter,
      //classes: "tenWidth",
      classes: (isMobile ? null : "tenWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          props.history.push({
            pathname: "/device/" + row.id,
            // search: '?devid=' + row.deviceid,
            state: {
              devid: row.deviceid,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "_count.trapEventNotifications",
      text: "Activity",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      style: { cursor: "pointer" },
      formatter: activityFormatter,
      //classes: "tenWidth",
      classes: (isMobile ? null : "tenWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          props.history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "deviceCode",
      text: "ID",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", width: "calc(100% - 35px)" } : { cursor: "pointer" }),
      headerFormatter: idHeaderFormatter,
      formatter: idFormatter,
      //classes: "idWidth",
      classes: (isMobile ? null : "idWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          props.history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "model",
      text: "Model",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: modelFormatter,
      //classes: "modelWidth",
      classes: (isMobile ? null : "modelWidth"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          props.history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "deviceName",
      text: "Device name",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: nameFormatter,
      classes: "fullWidth nameWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          props.history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer", width: "100%" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: addressFormatter,
      classes: "fullWidth addressWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          props.history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "position",
      text: "Position",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: positionFormatter,
      classes: "halfWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          props.history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
            },
          });
        },
      },
    },
    {
      dataField: "owner",
      text: "Owner",
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      //style: { cursor: "pointer" },
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      formatter: ownerFormatter,
      classes: "halfWidth",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          props.history.push({
            pathname: "/device/" + row.id,
            state: {
              devid: row.id,
              own: true,
            },
          });
        },
      },
    },
    {
      dataField: "showOnMap",
      text: "On map",
      formatter: showonmapFormatter,
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
      //classes: "mapWidth",
      classes: (isMobile ? "realHalfWidth" : "mapWidth"),
      // headerAttrs: { width: 50 },
      // attrs: { width: 50, className: "EditRow" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          axios
            .post(`${DEVICE_SERVER}/setshowonmapbydeviceid`, {
              deviceid: row.id,
              owner: row.ownerId,
            })
            .then((data) => {
              if (data.data.success) {
                getDeviceList();
              } else {
                console.log("unsuccess");
              }
            })
            .catch(() => {
              console.log("Oops, request failed!");
            });
        },
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: actFormatter,
      style: (isMobile ? { cursor: "pointer", padding: 0 } : { cursor: "pointer" }),
    },
  ];

  const selectRow = {
    mode: "checkbox",
    bgColor: "#EAF6FC",
    style: { backgroundColor: "red" },
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedDevicesArray((selectedDevicesArray) => {
        if (isSelect) {
          return [...selectedDevicesArray, row];
        } else {
          let k = selectedDevicesArray.findIndex((temp) => temp.id === row.id);
          return [
            ...selectedDevicesArray.slice(0, k),
            ...selectedDevicesArray.slice(k + 1, selectedDevicesArray.length),
          ];
        }
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      setSelectedDevicesArray((selectedDevicesArray) => {
        if (isSelect) {
          return [...devicelists];
        } else {
          return [];
        }
      });
    },
  };

  const pnoptions = {
    hideSizePerPage: true,
  };

  const onCloseToast = () => {
    setShowToast(false);
    setDeletedLength(0);
  };

  const setTabKey = (e) => {
    setActiveKey(e.target.value);
  };

  const onChangeSortField = (e) => {
    setSortField(e.target.value);
  };

  const onChangeSortVaule = (e) => {
    setSortValue(e.target.value);
  };

  const onClickDeleteOneDev = () => {
    axios
      .post(`${DEVICE_SERVER}/removeonedevice`, {
        deviceid: theDevice.deviceid,
      })
      .then((data) => {
        if (data.data.success) {
          setShowDeleteOneModal(false);
          setDeletedLength(1);
          setShowToast(true);
          getDeviceList();
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  //////////////////////////// Hamburger menu  ////////////////////////////////////////////////
  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu && window.innerWidth > 981
      ? setMainClass("body_panel col-sm-10")
      : setMainClass("body_panel col-sm-12");
  };

  return (
    <>
      <NavBar updateHamburger={updateHamburger} />
      {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
      <div className="mainpanel container-fluid">
        <div className="row">
          {propShowMenu ? <MenuBar /> : null}

          <Toast
            delay={3000}
            autohide
            show={showToast}
            onClose={onCloseToast}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              paddingLeft: 20,
              zIndex: 8,
              width: 400,
              borderTop: "4px solid #F47721",
            }}
          >
            <Toast.Body>{deletedLength} devices deleted.</Toast.Body>
          </Toast>
          {/* <div className="body_panel col-sm-10"> */}
          <div className={mainClass}>
            <div className="page_title" style={{ display: "flex" }}>
              <div className="title_name">Device list</div>
              <div className="title_action">
                <Button
                  style={{ marginLeft: "20px" }}
                  className="normalButton"
                  onClick={onClickShowAddDeviceModal}
                >
                  <BsPlus style={{ marginRight: "6px" }} />
                  Add new device
                </Button>
              </div>
              { user != null ? (
                <AddNewDevice
                show={showAddDeviceModal}
                onClickShowAddDeviceModal={onClickShowAddDeviceModal}
                updateDeviceList={updateDeviceList}
                />
              ) : null}
            </div>
            <div className="page_content">
              <Form.Group className="devicelistdropdown">
                <Form.Control
                  // value={resolution}
                  onChange={(e) => {
                    setTabKey(e);
                  }}
                  as="select"
                >
                  <option value="alldevices">{title1}</option>
                  <option value="mydevices">{title2}</option>
                  <option value="sharewithme">{title3}</option>
                  <option value="sharebyme">{title4}</option>
                </Form.Control>
              </Form.Group>
              <Tabs
                // defaultActiveKey="alldevices"
                activeKey={activeKey}
                transition={false}
                className="devicelisttabs"
                onSelect={(e) => {
                  setActiveKey(e);
                }}
              >
                <Tab
                  eventKey="alldevices"
                  title={title1}
                  tabClassName="sub_menus"
                >
                  {Object.keys(onlineStatus).length > 0 &&
                  activeKey === "alldevices" ? (
                    <Alldevices
                      allDevicesLength={allDevicesLength}
                      onlineStatus={onlineStatus}
                      updateAllDeviceListLength={updateAllDeviceListLength}
                    />
                  ) : null}
                </Tab>
                <Tab
                  eventKey="mydevices"
                  title={title2}
                  tabClassName="sub_menus"
                >
                  {activeKey === "mydevices" ? (
                    <div className="sub_content">
                      <div className="sub_title">My devices</div>
                      <div className="sub_body">
                        <Form.Group>
                          <Button
                            variant="outline-dark"
                            className="controlBtn"
                            disabled={disabledSelected}
                            onClick={onClickShareSeleted}
                            style={{ marginRight: "10px" }}
                          >
                            <MdShare
                              style={{
                                marginRight: "6px",
                                height: "16px",
                                width: "16px",
                              }}
                            />
                            Share selected
                          </Button>
                          <Button
                            variant="outline-dark"
                            className="controlBtn"
                            disabled={disabledSelected}
                            onClick={() => {
                              setShowDeleteSelectedModal(true);
                            }}
                          >
                            <FaTrash style={{ marginRight: "6px" }} />
                            Delete selected
                          </Button>
                        </Form.Group>
                        <Modal
                          show={showShowSelectedModal}
                          onHide={onCloseShareModal}
                        >
                          <Modal.Header
                            closeButton
                            style={{ borderBottom: "none" }}
                          >
                            <Modal.Title>
                              {/* Share {selectedDevicesArray.length} devices */}
                              Share selected devices
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Container>
                              <Row>
                                <Col sm={9} className="">
                                  <Form.Control
                                    placeholder="Enter email address"
                                    value={shareEmail}
                                    onChange={handleShareEmailChange}
                                  />
                                </Col>
                                <Col sm={3} className="devdetailright">
                                  <Button
                                    className="normalButton"
                                    onClick={onClickShareOnSelected}
                                  >
                                    Share
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Modal.Body>
                        </Modal>

                        <Form.Group className="sortArea">
                          <Row>
                            <Col
                              xs={2}
                              style={{
                                textAlign: "right",
                                padding: "8px 0px",
                              }}
                            >
                              <Form.Label>Sort by </Form.Label>
                            </Col>
                            <Col xs={5}>
                              <Form.Control
                                onChange={(e) => {
                                  onChangeSortField(e);
                                }}
                                as="select"
                              >
                                <option value="devid">ID</option>
                                <option value="activity">Activity</option>
                                <option value="devicename">Device name</option>
                              </Form.Control>
                            </Col>
                            <Col xs={5} style={{ padding: "0 0 0 2px" }}>
                              <Form.Control
                                onChange={(e) => {
                                  onChangeSortVaule(e);
                                }}
                                as="select"
                              >
                                <option value="asc">ascending</option>
                                <option value="desc">descending</option>
                              </Form.Control>
                            </Col>
                          </Row>
                        </Form.Group>
                        <BootstrapTable
                          keyField="id"
                          classes="tableWithMobile"
                          data={deviceListForTable}
                          columns={columns}
                          bordered={false}
                          striped={true}
                          selectRow={selectRow}
                          pagination={paginationFactory(pnoptions)}
                          // wrapperClasses="table-responsive"
                        />
                        {theDevice === undefined ? null : (
                          <EditDevice
                            show={showEditDeviceModal}
                            editdevice={theDevice}
                            onClickShowEditDeviceModal={
                              onClickShowEditDeviceModal
                            }
                            updateEditedDeviceList={updateEditedDeviceList}
                          />
                        )}
                        {theDevice === undefined ? null : (
                          <Modal
                            show={showDeleteOneModal}
                            onHide={() => {
                              setShowDeleteOneModal(false);
                            }}
                          >
                            <Modal.Header
                              closeButton
                              style={{ borderBottom: "none" }}
                            >
                              <Modal.Title>Delete device</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Container>
                                <Row>
                                  <p>
                                    Are you sure want to delete device{" "}
                                    {theDevice.deviceCode} -{" "}
                                    {theDevice.deviceName}?
                                  </p>
                                </Row>
                                <Row style={{ marginTop: "10px" }}>
                                  <Button
                                    className="normalButton"
                                    onClick={() => {
                                      onClickDeleteOneDev();
                                    }}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    className="cancelButton"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => {
                                      setShowDeleteOneModal(false);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Row>
                              </Container>
                            </Modal.Body>
                          </Modal>
                        )}

                        {!selectedDevicesArray.length ? null : (
                          <Modal
                            show={showDeleteSelectedModal}
                            onHide={() => {
                              setShowDeleteSelectedModal(false);
                            }}
                          >
                            <Modal.Header
                              closeButton
                              style={{ borderBottom: "none" }}
                            >
                              <Modal.Title>Delete devices</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Container>
                                <Row>
                                  <p>
                                    Are you sure want to delete{" "}
                                    {selectedDevicesArray.length} devices?
                                  </p>
                                </Row>
                                <Row style={{ marginTop: "10px" }}>
                                  <Button
                                    className="normalButton"
                                    onClick={() => {
                                      onClickDeleteSeleted();
                                    }}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    className="cancelButton"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => {
                                      setShowDeleteSelectedModal(false);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Row>
                              </Container>
                            </Modal.Body>
                          </Modal>
                        )}

                        {theDevice === undefined ? null : (
                        <ShareDeviceModal
                        show={showShareOneModal}
                        onClose={closeSharedDeviceModal}
                        deviceId={theDevice.id}
                        ownerId={theDevice.ownerId}
                        />
                        )}
                      </div>
                    </div>
                  ) : null}
                </Tab>
                <Tab
                  eventKey="sharewithme"
                  title={title3}
                  tabClassName="sub_menus"
                >
                  {Object.keys(onlineStatus).length > 0 &&
                  activeKey === "sharewithme" ? (
                    <Sharedwithme
                      onlineStatus={onlineStatus}
                      updateSharedDeviceListLength={
                        updateSharedDeviceListLength
                      }
                    />
                  ) : null}
                </Tab>
                <Tab
                  eventKey="sharebyme"
                  title={title4}
                  tabClassName="sub_menus"
                >
                  {Object.keys(onlineStatus).length > 0 &&
                  activeKey === "sharebyme" ? (
                    <Sharedbyme
                      onlineStatus={onlineStatus}
                      updateAllDeviceListLength={updateAllDeviceListLength}
                      // updateSharedBymeDeviceListLength={
                      //   updateSharedBymeDeviceListLength
                      // }
                    />
                  ) : null}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // }
}

export default DevicelistPage;