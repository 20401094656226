import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

function AddressMap(props) {
  /* eslint-disable no-unused-vars */
  const [map, setMap] = React.useState(null);
  const [isGoogleMapsAPILoaded, setIsGoogleMapsAPILoaded] = React.useState(
    false
  );

  const onLoad = React.useCallback(function callback(map) {
    setIsGoogleMapsAPILoaded(true);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={props.mapContainerStyle}
      center={props.center}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {isGoogleMapsAPILoaded && <Marker position={props.center} />}
    </GoogleMap>
  );
}
AddressMap.defaultProps = {
  center: {
    lat: 43.842466,
    lng: -79.423213,
  },
};

export default React.memo(AddressMap);
