import React, { useState, useEffect } from "react";
import { Form, Col, Button, Toast } from "react-bootstrap";
import MenuBar from "../MenuBar";
import axios from "axios";
import { SETTING_SERVER, DEVICE_SERVER, TIMEZONE_SERVER } from "../../Config";
import NavBar from "../NavBar";

import { getWs, sendJsonByWebSocket } from "../../../common";

import AddressMap from "./AddressMap";
import { Autocomplete } from "@react-google-maps/api";

export const SettingsPage = () => {
  const [resolution, setResolution] = useState("");
  const [tempUnit, setTempUnit] = useState("");
  const [autoClear, setAutoClear] = useState(false);
  const [emailNoti, setEmailNoti] = useState(false);
  const [useDefaultAddress, setUseDefaultAddress] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState({
    lat: 43.842466,
    lng: -79.423213,
  });
  const [timeZone, setTimeZone] = useState("");
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");
  const [allDevices, setAllDevices] = useState([]);
  let user = JSON.parse(window.localStorage.getItem("user"));

  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");

  useEffect(() => {
    getSetting();
    getTimeZones();
    getAvailableDeviceList();
    if (window.innerWidth < 981) {
      setMainClass("body_panel col-sm-12");
      setPropShowMenu(false);
    }
    // eslint-disable-next-line
  }, []);

  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autocomplete) => {
    /* ac : autocomplete object variable */
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      let place = autocomplete.getPlace();
      let address = place.formatted_address,
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      console.log(address);

      setDefaultAddress(address);
      setMarkerPosition({
        lat: latValue,
        lng: lngValue,
      });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const getTimeZones = () => {
    axios
      .get(`${TIMEZONE_SERVER}/all`)
      .then((data) => {
        if (data.data.success) {
          let timeZones = data.data.timeZones;
          setTimeZoneList(timeZones);
        }
      });
  };

  const getSetting = () => {
    axios
      .post(`${SETTING_SERVER}/getorcreatebyuserid`, {
        userId: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          //let settings = data.data.setting[0];
          let settings = data.data.setting;
          setResolution(settings.resolution);
          setTempUnit(settings.tempUnit);
          setAutoClear(settings.autoClear);
          setEmailNoti(settings.emailNoti);
          setUseDefaultAddress(settings.useDefaultAddress);
          if (settings.defaultAddress != null)
            setDefaultAddress(settings.defaultAddress);
          if (settings.lat != null && settings.lng != null)
            setMarkerPosition({
              lat: parseFloat(settings.lat),
              lng: parseFloat(settings.lng),
            });
          if (settings.timeZone != null)
            setTimeZone(settings.timeZone);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getAvailableDeviceList = () => {
    axios
      .post(`${DEVICE_SERVER}/getavailabledevices`, {
        userid: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          setAllDevices(data.data.devices);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  // const onChangeResolution = (e) => {
  //   setResolution(e.target.value);
  // };

  const onChangeTempUnit = (e) => {
    setTempUnit(e.target.id);
  };

  const onChangeAutoClear = (e) => {
    setAutoClear(e.target.checked);
  };

  const onChangeEmailNoti = (e) => {
    setEmailNoti(e.target.checked);
  };
  
  const onChangeUseDefaultAddress = (e) => {
    setUseDefaultAddress(e.target.checked);
  };
  
  const onChangeDefaultAddress = (e) => {
    setDefaultAddress(e.target.value);
  };

  const onChangeTimeZone = (e) => {
    setTimeZone(e.target.value);
  };

  const onClickUpdate = () => {
    axios
      .post(`${SETTING_SERVER}/updateone`, {
        userid: user.id,
        resolution,
        tempUnit,
        autoClear,
        emailNoti,
        useDefaultAddress,
        defaultAddress,
        lat: markerPosition.lat,
        lng: markerPosition.lng,
        timeZone,
      })
      .then((data) => {
        if (data.data.success) {
          let ws = getWs();
          let json = {
            message: "cameraResChange",
            imageSize: resolution,
          };
          allDevices.forEach((device) => {
            sendJsonByWebSocket(ws, device.id, json);
          });
          setToastText("Successfully changed settings.");
          setShowToast(true);
        } else {
          setToastText("Unable to change settings.");
          setShowToast(true);
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        setToastText("Unable to change settings.");
        setShowToast(true);
        console.log("Oops, request failed!");
      });
  };

  const onClickCancel = () => {
    getSetting();
  };

  const onCloseToast = () => {
    setShowToast(false);
  };

  //////////////////////////// Hamburger menu  ////////////////////////////////////////////////
  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu && window.innerWidth > 981
      ? setMainClass("body_panel col-sm-10")
      : setMainClass("body_panel col-sm-12");
  };

  return (
    <>
      <NavBar updateHamburger={updateHamburger} />
      {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
      <div className="mainpanel container-fluid">
        <div className="row">
          {propShowMenu ? <MenuBar /> : null}
          <Toast
            delay={3000}
            autohide
            show={showToast}
            onClose={onCloseToast}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              paddingLeft: 20,
              zIndex: 8,
              width: 400,
              borderTop: "4px solid #F47721",
            }}
          >
            <Toast.Body>{toastText}</Toast.Body>
          </Toast>
          <div className={mainClass}>
            <div className="page_title">Settings</div>
            <div className="page_content">
              <div className="sub_content">
                <div className="sub_title">Filzertech default setting</div>
                <div className="sub_body">
                  <Form style={{ color: "#333333" }}>
                    {/* <Form.Row>
                      <Form.Group as={Col} sm={4}>
                        <Form.Label>Default photo resolution</Form.Label>
                        <Form.Control
                          value={resolution}
                          onChange={(e) => {
                            onChangeResolution(e);
                          }}
                          as="select"
                        >
                          <option value="0">640*480</option>
                          <option value="1">800*600</option>
                          <option value="2">1024*768</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row> */}
                    <Form.Row>
                      <Form.Group as={Col} sm={6}>
                        <Form.Label>Default measurement unit</Form.Label>
                        <Form.Check
                          type="radio"
                          name="tempUnit"
                          id="celsius"
                          value="celsius"
                          checked={tempUnit === "celsius"}
                          label="Celsius (C)"
                          onChange={(e) => {
                            onChangeTempUnit(e);
                          }}
                        />
                        <Form.Check
                          type="radio"
                          name="tempUnit"
                          id="fahrenheit"
                          value="fahrenheit"
                          checked={tempUnit === "fahrenheit"}
                          label="Fahrenheit (F)"
                          onChange={(e) => {
                            onChangeTempUnit(e);
                          }}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={6}>
                        <Form.Label>Auto clear data</Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="autoClear"
                          // className="custom-checkbox"
                          checked={autoClear}
                          onChange={(e) => {
                            onChangeAutoClear(e);
                          }}
                          label="Auto delete device logs after 90 days"
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={6}>
                        <Form.Label>Email notification</Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="emailNoti"
                          // className="custom-checkbox"
                          checked={emailNoti}
                          onChange={(e) => {
                            onChangeEmailNoti(e);
                          }}
                          label="Email me when PIR (infrared) sensor activated."
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={6}>
                        <Form.Label>Use default address</Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="useDefaultAddress"
                          checked={useDefaultAddress}
                          onChange={(e) => {
                            onChangeUseDefaultAddress(e);
                          }}
                          label="Set this address by default for each new added device (check box to add a default address)."
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={6}>
                        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        <Form.Control
                          value={defaultAddress}
                          disabled={!useDefaultAddress}
                          onChange={(e) => {
                            onChangeDefaultAddress(e);
                          }}
                        />
                        </Autocomplete>
                        <AddressMap
                          center={markerPosition}
                          mapContainerStyle={{
                            width: "100%",
                            height: "200px",
                          }}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={6}>
                        <Form.Label>Time zone</Form.Label>
                        <Form.Control
                          value={timeZone}
                          id="timeZone"
                          onChange={(e) => {
                            onChangeTimeZone(e);
                          }}
                          as="select"
                          >
                            {timeZoneList.map((tz, i) => <option value={tz.tzName}>{tz.tzName}</option>)}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Button
                      className="normalButton"
                      onClick={() => {
                        onClickUpdate();
                      }}
                      style={{ marginRight: "8px" }}
                    >
                      Update
                    </Button>
                    <Button
                      className="cancelButton"
                      onClick={() => {
                        onClickCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};