import React, { useState, useEffect } from "react";
import { Button, Toast } from "react-bootstrap";
import axios from "axios";
import { FILZERDOC_SERVER } from "../../Config";
import { PRODUCT_SERVER } from "../../Config";

let arrImageNames = [];
let arrPdfNames = [];
let regPdf = /product_pdfs/g;
let regImage = /product_pics/g;

export const Dump = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");
  const [addErrorMessage, setAddErrorMessage] = useState("");

  useEffect(() => {
    getAllDocContents();
    // eslint-disable-next-line
  }, []);

  const getAllDocContents = () => {
    axios
      .post(`${FILZERDOC_SERVER}/getalldoccontent`)
      .then(async (data) => {
        if (data.data.success) {
          for (let doc of data.data.filzerdoc) {
            let resultImage = [];
            let resultPdf = [];
            while ((resultImage = regImage.exec(doc.content))) {
              arrImageNames.push(
                doc.content.substr(resultImage.index + 13, 23)
              ); //ex: src="product_pics/IMAGE-1609982452165.jpg"
            }
            while ((resultPdf = regPdf.exec(doc.content))) {
              arrPdfNames.push(doc.content.substr(resultPdf.index + 13, 21)); //ex: src="product_pdfs/PDF-1609982452165.pdf"
            }
          }
          await getAllProductContents();
        } else {
          if (data.data.error.name === "SequelizeUniqueConstraintError")
            setAddErrorMessage("Product name should be unique..");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getAllProductContents = () => {
    axios
      .post(`${PRODUCT_SERVER}/getallproductcontent`)
      .then((data) => {
        if (data.data.success) {
          for (let product of data.data.product) {
            if (product.imageUrls !== null) {
              let tempArrImage = product.imageUrls.split(",");
              tempArrImage.splice(-1);
              arrImageNames = [...arrImageNames, ...tempArrImage];

              let descPdf = [];
              let specPdf = [];
              let supportPdf = [];
              while ((descPdf = regPdf.exec(product.description))) {
                arrPdfNames.push(
                  product.description.substr(descPdf.index + 13, 21)
                ); //ex: src="product_pdfs/PDF-1609982452165.pdf"
              }
              while ((specPdf = regPdf.exec(product.spec))) {
                arrPdfNames.push(product.spec.substr(specPdf.index + 13, 21)); //ex: src="product_pdfs/PDF-1609982452165.pdf"
              }
              while ((supportPdf = regPdf.exec(product.support))) {
                arrPdfNames.push(
                  product.support.substr(supportPdf.index + 13, 21)
                ); //ex: src="product_pdfs/PDF-1609982452165.pdf"
              }
            }
          }
        } else {
          if (data.data.error.name === "SequelizeUniqueConstraintError")
            setAddErrorMessage("Product name should be unique..");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onClickRemove = () => {
    axios
      .post(`${FILZERDOC_SERVER}/removeunused`, { arrImageNames, arrPdfNames })
      .then((data) => {
        if (data.data.success) {
          setToastText("Unused files are removed successfully.");
          setShowToast(true);
        } else {
          console.log("Error in removing...");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  return (
    <>
      <div className="sub_content">
        <div className="sub_title">Remove unused images and pdf files</div>
        <div className="sub_body">
          <Toast
            delay={3000}
            autohide
            show={showToast}
            onClose={() => setShowToast(false)}
            style={{
              position: "fixed",
              top: 10,
              right: 10,
              paddingLeft: 20,
              zIndex: 8,
              width: 400,
              borderTop: "4px solid #F47721",
            }}
          >
            <Toast.Body>{toastText}</Toast.Body>
          </Toast>

          {addErrorMessage && (
            <label>
              <p
                style={{
                  color: "#EB5757",
                  fontSize: "14px",
                  marginTop: "-10px",
                }}
              >
                {addErrorMessage}
              </p>
            </label>
          )}

          <Button
            className="normalButton"
            type="submit"
            style={{ marginTop: "10px" }}
            onClick={() => {
              onClickRemove();
            }}
          >
            Remove
          </Button>
        </div>
      </div>
    </>
  );
};
