import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import FooterBar from "../FooterBar";
import axios from "axios";
import { PRODUCT_SERVER } from "../../Config";
import { useHistory } from "react-router-dom";
import MenuBar from "../MenuBar";

function HomePage() {
  const [productList, setProductList] = useState(undefined);
  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");
  const [loggedIn, setLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  let history = useHistory();
  let user = JSON.parse(window.localStorage.getItem("user"));
  

  useEffect(() => {
    checkLoggedIn();
    getProductList();

    if (window.innerWidth < 981) {
      setMainClass("body_panel col-sm-12");
      setIsMobile(true);
      setPropShowMenu(false);
    }

    //if (loggedIn) {
    //  setMainClass("body_panel col-sm-10");
    //} else {
    //  setMainClass("body_panel col-sm-12");
    //}

  }, []);

  const checkLoggedIn = () => {
    user === undefined || user === null
      ? setLoggedIn(false)
      : setLoggedIn(true);

    user === undefined || user === null
      ? setMainClass("body_panel col-sm-12")
      : setMainClass("body_panel col-sm-10");
  };

  const getProductList = () => {
    axios
      .post(`${PRODUCT_SERVER}/getallproducts`, {})
      .then((data) => {
        if (data.data.success) {
          let tempProductList = [];
          let products = data.data.products;
          for (let i in products) {
            if (products[i].showOnHome && tempProductList.length < 3)
              tempProductList.push(products[i]);
          }
          setProductList(tempProductList);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getAllProducts = () => {
    let items = [];
    for (let i in productList) {
      if (productList[i].imageurls !== null) {
        let imageUrlArray = productList[i].imageUrls.split(",");
        imageUrlArray.splice(-1);
        let imageSrc = "/product_pics/" + imageUrlArray[0];
        items.push(
          <div
            key={i}
            className="products"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push({
                pathname: "/productdetail/" + productList[i].id,
                state: {
                  productid: productList[i].id,
                },
              });
            }}
          >
            <img alt="mousetrap" src={imageSrc} />
            <span>{productList[i].productName}</span>
          </div>
        );
      }
    }
    return items;
  };

  const updateHamburger = (childShowMenu) => {
    if (loggedIn) {
      setPropShowMenu(childShowMenu);
      childShowMenu && window.innerWidth > 981
      ? setMainClass("body_panel col-sm-10")
      : setMainClass("body_panel col-sm-12");
    } else {
      setMainClass("body_panel col-sm-12");
    }

  };

  return (
    <>
      <NavBar updateHamburger={updateHamburger} />
      {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}

      <div className="mainpanel container-fluid">
        <div className="row">
          {(propShowMenu && loggedIn) ? <MenuBar /> : null}

          <div className={mainClass} style={{padding: 0}}>

            <div
              className="mainup"
              // style={{ backgroundImage: `url("home page image.png")` }}
            >
              <div className="mainup_text">
                <h1>Intelligent Devices by FilzerTech</h1>
                <span>MAKING PEST MONITORING AND CONTROL EASY</span>
              </div>
              <img src="home page image.png" alt="homepage" />
              {/* <div className="mainup_img">
                <img alt="mousetrap" src="home page image.png"></img>
              </div> */}
            </div>
            <div className="main_down">
              <div className="productlisttitle" style={{ paddingBottom: "30px" }}>
                Featuring
              </div>
              <div className="productlist">
                {productList === undefined ? null : getAllProducts()}
              </div>
            </div>
            <FooterBar />

          </div>

        </div>
      </div>

    </>
  );
}

export default HomePage;