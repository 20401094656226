import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Button,
  Container,
  Row,
  Modal,
  Badge,
} from "react-bootstrap";
import { FaEyeSlash, FaEye } from "react-icons/fa";
// import { FiWifi, FiWifiOff } from "react-icons/fi";
import { MdClose, MdShare } from "react-icons/md";

import axios from "axios";
import AddressMap from "./AddressMap";
import { DEVICE_SERVER } from "../../Config";
import { SHAREDDEVICE_SERVER } from "../../Config";
import { deviceTypes } from "../../DeviceTypes";

import ShareDeviceModal from "./ShareDeviceModal";

export const DeviceDetail = (props) => {
  const [deviceInfo, setDeviceInfo] = useState(props.deviceInfo);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareDeviceError, setShareDeviceError] = useState("");
  const [shareDeviceSuccess, setShareDeviceSuccess] = useState("");
  const [sharedDeviceList, setSharedDeviceList] = useState([]);
  const [shareEmail, setShareEmail] = useState("");

  useEffect(() => {
    getSharedDeviceList();
    // eslint-disable-next-line
  }, []);

  const getSharedDeviceList = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/getbydeviceidowner`, {
        deviceid: deviceInfo.id,
        owner: deviceInfo.ownerId,
      })
      .then((data) => {
        if (data.data.success) {
          setSharedDeviceList(data.data.shareddevices);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const toggleShowOnMap = () => {
    axios
      .post(`${DEVICE_SERVER}/setshowonmapbydeviceid`, {
        deviceid: deviceInfo.id,
        owner: deviceInfo.ownerId,
      })
      .then((data) => {
        if (data.data.success) {
          let temp = JSON.parse(JSON.stringify(deviceInfo));
          temp.showOnMap = !temp.showOnMap;
          setDeviceInfo((deviceInfo) => temp);
          props.updateEditedDeviceList(temp);
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onShowShareModal = () => {
    setShowShareModal(true);
  };

  const onCloseShareModal = () => {
    setShareDeviceError("");
    setShareDeviceSuccess("");
    setShareEmail("");
    setShowShareModal(false);
  };

  const closeSharedDeviceModal = () => {
    setShowShareModal(false);
  };

  const onClickRemove = (deviceid, sharedto) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/removebydeviceidsharedto`, {
        deviceid,
        sharedto,
      })
      .then((data) => {
        if (data.data.success) {
          setSharedDeviceList((sharedDeviceList) => {
            let k = sharedDeviceList.findIndex(
              (device) => device.sharedto === sharedto
            );
            if (k > -1) {
              return [
                ...sharedDeviceList.slice(0, k),
                ...sharedDeviceList.slice(k + 1, sharedDeviceList.length),
              ];
            } else {
              return sharedDeviceList;
            }
          });
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onClickRemoveAll = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/removebydeviceidall`, {
        deviceid: deviceInfo.deviceid,
        owner: deviceInfo.owner,
      })
      .then((data) => {
        if (data.data.success) {
          setSharedDeviceList([]);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const createSharedList = () => {
    let items = [];
    for (let i in sharedDeviceList) {
      let shared = sharedDeviceList[i];
      //let isPending = shared.sharedto > 0 ? "" : "Pending";
      const sharedDeviceStatuses = {
        "accept": "Accepted",
        "pending": "Pending",
        "decline": "",
        "block": "",
        "notallow": "",
      };
      let sharedStatus = sharedDeviceStatuses[shared.status];
      let nameValue;
      if (shared.sharedWithUser != undefined && shared.sharedWithUser != null) {
        if (shared.sharedWithUser.firstName === null && shared.sharedWithUser.lastName === null)
          nameValue = shared.sharedWithUserEmail;
        else if (shared.sharedWithUser.firstName === null) nameValue = shared.sharedWithUser.lastName;
        else if (shared.sharedWithUser.lastName === null) nameValue = shared.sharedWithUser.firstName;
        else nameValue = `${shared.sharedWithUser.firstName} ${shared.sharedWithUser.lastName}`;
      }
      /*if (shared.sharedWithUser.firstName === null && shared.sharedWithUser.lastName === null)
        nameValue = shared.sharedWithUserEmail;
      else if (shared.sharedWithUser.firstName === null) nameValue = shared.sharedWithUser.lastName;
      else if (shared.sharedWithUser.lastName === null) nameValue = shared.sharedWithUser.firstName;*/
      items.push(
        <Row
          key={i}
          className="sharemodallist"
          style={{ marginTop: "10px", paddingBottom: "10px" }}
        >
          <Col xs={6}>{nameValue}</Col>
          <Col xs={3}>{sharedStatus}</Col>
          <Col xs={3} className="devdetailright">
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => onClickRemove(shared.deviceId, shared.sharedWithUserId)}
            >
              <MdClose /> Remove
            </Button>
          </Col>
        </Row>
      );
    }
    return items;
  };

  const handleShareEmailChange = (e) => {
    setShareEmail(e.target.value);
  };

  const onClickShare = () => {
    setShareDeviceError("");
    setShareDeviceSuccess("");
    axios
      .post(`${SHAREDDEVICE_SERVER}/addshareddevice`, {
        deviceid: deviceInfo.id,
        owner: deviceInfo.ownerId,
        email: shareEmail,
      })
      .then((data) => {
        if (data.data.success) {
          setShareDeviceSuccess(data.data.message);
        } else {
          setShareDeviceError(data.data.message);
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  let ipLink = "http://" + deviceInfo.localIp;

  const getModel = (devInfo) => {
    let returnValue;
    returnValue = `${deviceTypes[devInfo.model]} (${devInfo.model})`;
    return returnValue;
  };

  return (
    <div className="sub_content">
      <div className="sub_title">Device Detail</div>
      <div className="sub_body">
        <Container>
          {/* <Row>
            <Col xs={2} className="devdetailleft">
              Status
            </Col>
            <Col xs={8} className="devdetailright">
              {props.isDeviceConnected ? (
                <>
                  <FiWifi
                    style={{
                      color: "#27AE60",
                      width: "16px",
                      height: "16px",
                      margin: "5px",
                    }}
                  />
                  <span style={{ color: "#27AE60" }}>Online</span>
                </>
              ) : (
                <>
                  <FiWifiOff
                    style={{
                      color: "#BDBDBD",
                      width: "16px",
                      height: "16px",
                      margin: "5px",
                    }}
                  />
                  <span style={{ color: "#BDBDBD" }}>Offline</span>
                </>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}> 
            <Col xs={2} className="devdetailleft">
              Active
            </Col>
            <Col xs={8} className="devdetailright">
              {props.autoCount}
            </Col>
          </Row>*/}
          <Row style={{ marginTop: "16px" }}>
            <Col xs={2} className="devdetailleft">
              ID
            </Col>
            <Col xs={8} className="devdetailright">
              {deviceInfo.deviceCode}
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col xs={2} className="devdetailleft">
              Device name
            </Col>
            <Col xs={8} className="devdetailright">
              {deviceInfo.deviceName}
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col xs={2} className="devdetailleft">
              Local IP address
            </Col>
            <Col xs={6} className="devdetailright">
              <a href={ipLink} target="_blank" rel="noopener noreferrer">
                {deviceInfo.localIp}
              </a>
              <br />
              <span style={{ color: " #828282" }}>
                *Local IP address link to device -only works when your computer
                or mobile phone is on same router/network as device.
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col xs={2} className="devdetailleft">
              Model
            </Col>
            <Col xs={8} className="devdetailright">
              {getModel(deviceInfo)}
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col xs={2} className="devdetailleft">
              Owner
            </Col>
            <Col xs={8} className="devdetailright">
              {deviceInfo.owner.firstName} {deviceInfo.owner.lastName}
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col xs={2} className="devdetailleft">
              Firmware
            </Col>
            <Col xs={8} className="devdetailright">
              {deviceInfo.firmware}
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col xs={2} className="devdetailleft">
              Address
            </Col>
            <Col xs={8} className="devdetailright">
              {deviceInfo.address}
              <br />
            </Col>
          </Row>
          <Row>
            <Col xs={2}></Col>

            <AddressMap
              className="col-8"
              center={{
                lat: parseFloat(props.deviceInfo.lat),
                lng: parseFloat(props.deviceInfo.lng),
              }}
              mapContainerStyle={{
                width: window.innerWidth > 981 ? "66%" : "100%",
                height: "60vh",
                margin: "14px 0px 14px 0px",
                borderRadius: "6px",
              }}
            />
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col xs={2} className="devdetailleft">
              Position
            </Col>
            <Col xs={8} className="devdetailright">
              {deviceInfo.position}
            </Col>
          </Row>
          {!props.own ? null : (
            <Row style={{ marginTop: "16px" }}>
              <Col xs={2} className="devdetailleft">
                Show on map
              </Col>
              <Col xs={8} className="devdetailright">
                {deviceInfo.showOnMap ? (
                  <Button variant="success" onClick={toggleShowOnMap}>
                    <FaEye /> Show
                  </Button>
                ) : (
                  <Button variant="outline-dark" onClick={toggleShowOnMap}>
                    <FaEyeSlash /> Hide
                  </Button>
                )}
              </Col>
            </Row>
          )}

          {!props.own ? null : (
            <Row style={{ marginTop: "16px" }}>
              <Col xs={2} className="devdetailleft">
                Sharing
              </Col>
              <Col
                xs={8}
                className="devdetailright"
                style={{ display: "flex" }}
              >
                <Button
                  style={{
                    backgroundColor: "#F47721",
                    borderColor: "#F47721",
                    color: "white",
                  }}
                  onClick={onShowShareModal}
                >
                  <MdShare /> Share
                </Button>
                <div
                  style={{
                    width: "2px",
                    margin: "0px 10px",
                    borderLeft: "1px solid #E0E0E0",
                  }}
                ></div>
                <Badge
                  variant="secondary"
                  style={{
                    width: "34px",
                    paddingTop: "14px",
                    color: "#333333",
                    backgroundColor: "#E0E0E0",
                    borderRadius: "50%",
                  }}
                >
                  {sharedDeviceList.length}
                </Badge>
                {/* <div style={{ width: '34px', background: '#F2F2F2', borderRadius: '50%' }}>{sharedDeviceList.length}</div> */}
              </Col>
            </Row>
          )}
        </Container>

        
        {deviceInfo === undefined ? null : (
        <ShareDeviceModal
        show={showShareModal}
        onClose={closeSharedDeviceModal}
        deviceId={deviceInfo.id}
        ownerId={deviceInfo.ownerId}
        />
        )}

        {/*<Modal show={showShareModal} onHide={onCloseShareModal}>
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title>Shared device</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {shareDeviceError && (
              <label>
                <p
                  style={{
                    color: "#EB5757",
                    fontSize: "14px",
                    marginTop: "-10px",
                  }}
                >
                  {shareDeviceError}
                </p>
              </label>
            )}
            {shareDeviceSuccess && (
              <label>
                <p
                  style={{
                    color: "green",
                    fontSize: "14px",
                    marginTop: "-10px",
                  }}
                >
                  {shareDeviceSuccess}
                </p>
              </label>
            )}
            <Container>
              <Row>
                <Col xs={9} className="">
                  <Form.Control
                    placeholder="Enter email address"
                    value={shareEmail}
                    onChange={handleShareEmailChange}
                  />
                </Col>
                <Col xs={3} className="devdetailright">
                  <Button className="normalButton" onClick={onClickShare}>
                    Share
                  </Button>
                </Col>
              </Row>
              <Row className="sharemodallist" style={{ marginTop: "20px" }}>
                <Col xs={6}>Shared with</Col>
                <Col xs={3} className="devdetailright">
                  Status
                </Col>
                <Col xs={3}></Col>
              </Row>
              {createSharedList()}
            </Container>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "none", justifyContent: "flex-end" }}
          >
            <Col xs={4} className="devdetailright">
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={onClickRemoveAll}
              >
                <MdClose /> Remove all
              </Button>
            </Col>
          </Modal.Footer>
        </Modal>*/}
      </div>
    </div>
  );
};
