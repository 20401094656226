import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Button,
  Toast,
  Container,
  Row,
  Modal,
  Badge,
} from "react-bootstrap";
import { MdEdit, MdShare, MdClose } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import { SHAREDDEVICE_SERVER } from "../../Config";
import { getWs, sendJsonByWebSocket } from "../../../common";

function DeviceReportModal(props) {
  // props.sharedDevices
  // props.show
  // pros.onHide
  //const [sharedDevices, setSharedDevices] = useState(props.sharedDevices);

  // props.deviceId
  // props.ownerId
  // props.show
  // TODO: props.onUpdate
  const [deviceId, setDeviceId] = useState(props.deviceId);
  const [ownerId, setOwnerId] = useState(props.ownerId);
  const [showShareModal, setShowShareModal] = useState(false);
  const [detailedStatusData, setDetailedStatusData] = useState(new Object());
  const [reportDatetime, setReportDatetime] = useState(new Date());

  useEffect(() => {
    //getSharedDeviceList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let message = props.socketMsg;

    if (!message.data) {
      var res = message;
      if (res.message === "detailedStatusReq") {
        var detailedStatus = res.detailedStatus;
        if (detailedStatus.received) {
          delete detailedStatus.received;
          setDetailedStatusData(detailedStatus);
          setReportDatetime(new Date());
        }
      }
    }
  }, [props.socketMsg]);

  useEffect(() => {
    setShowShareModal(props.show);
    /*if (props.show) getSharedDeviceList();
    console.log('props.show changed to:', props.show);*/
    // eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    setDeviceId(props.deviceId);
    //getSharedDeviceList();
    // eslint-disable-next-line
  }, [props.deviceId]);

  useEffect(() => {
    setOwnerId(props.ownerId);
    //getSharedDeviceList();
    // eslint-disable-next-line
  }, [props.ownerId]);

  const onCloseShareModal = () => {
    /*setShareDeviceError("");
    setShareDeviceSuccess("");
    setShareEmail("");*/
    props.onClose();
  };

  const onClickRequestReport = () => {
    let command = "detailedStatusReq";
    var json = {
      message: command,
    };
    sendJsonByWebSocket(getWs(), props.deviceId, json);
    console.log("Get report clicked");
  };

  const createReportList = () => {
    let items = [];
    for (const [key, value] of Object.entries(detailedStatusData)) {
      items.push(
        <Row
          key={key}
          className="sharemodallist"
          style={{ marginTop: "10px", paddingBottom: "10px" }}
        >
          <Col xs={8}>{key}</Col>
          <Col xs={4}>{value ? '✅': '❌'}</Col>
        </Row>
      );
    }
    return items;
  };

  return (
    <Modal show={showShareModal} onHide={onCloseShareModal}>
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>Device report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="justify-content-md-center">
            <Col md="auto" className="sharedShareBtn">
              <Button className="normalButton" size="lg" onClick={onClickRequestReport}>
                Request report
              </Button>
            </Col>
          </Row>
          
          {Object.keys(detailedStatusData).length != 0 && (
            <Row className="justify-content-md-center">
              <p className="lead mt-3" style={{ marginTop: "20px" }}>
                Report generated on: {reportDatetime.toLocaleString()}
              </p>
            </Row>
          )}

          <Row
            className="sharemodallistheader"
          >
            <Col sm={8} className="feature">
              Feature
            </Col>
            <Col sm={4} className="status">
              Status
            </Col>
          </Row>
          {createReportList()}
        </Container>
      </Modal.Body>
      <Modal.Footer
        style={{ borderTop: "none", justifyContent: "flex-end" }}
      >

      </Modal.Footer>
    </Modal>
  );
}

export default DeviceReportModal;
