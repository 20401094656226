import React from "react";
import {
  GoogleMap,
  MarkerClusterer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { FiWifi, FiWifiOff } from "react-icons/fi";
import { Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const options = {
  imageExtension: "png",
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  // You must have m1.png m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  gridSize: 10,
  //maxZoom: 1,
};

export const DevicesMap = (props) => {
  /* eslint-disable no-unused-vars */
  const [map2, setMap] = React.useState(null);
  const [isGoogleMapsAPILoaded, setIsGoogleMapsAPILoaded] =
    React.useState(false);
  const [center, setCenter] = React.useState(props.center);
  const [showInfoWindow, setShowInfoWindow] = React.useState(false);
  const [activeId, setActiveId] = React.useState("");

  let history = useHistory();

  const onLoad = React.useCallback(function callback(map) {
    setIsGoogleMapsAPILoaded(true);

    let devicelists = props.devicelists;
    if (devicelists.length >= 2) {
      const bounds = new window.google.maps.LatLngBounds();
      for (let i in devicelists) {
        bounds.extend(
          new window.google.maps.LatLng(devicelists[i].lat, devicelists[i].lng)
        );
      }
      map.fitBounds(bounds);
      setMap(map);
    } else if (devicelists.length === 1) {
      let loc = {
        lat: devicelists[0].lat,
        lng: devicelists[0].lng,
      };
      setCenter(loc);
    }
    // eslint-disable-next-line
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const createMarkers = (clusterer) => {
    let user = JSON.parse(window.localStorage.getItem("user"));

    let markers = [];
    let devicelists = props.devicelists;

    let idCountArray = {};
    for (let i in devicelists) {
      let deviceInfo = devicelists[i];
      if (idCountArray[deviceInfo.lat + "," + deviceInfo.lng] === undefined)
        idCountArray[deviceInfo.lat + "," + deviceInfo.lng] = 1;
      else {
        let j = idCountArray[deviceInfo.lat + "," + deviceInfo.lng];
        idCountArray[deviceInfo.lat + "," + deviceInfo.lng] = j + 1;
      }
    }

    for (let deviceId in devicelists) {
      let deviceInfo = devicelists[deviceId];
      let loc;
      if (idCountArray[deviceInfo.lat + "," + deviceInfo.lng] > 1) {
        loc = {
          lat: deviceInfo.lat + (Math.random() - 0.5) / 30000,
          lng: deviceInfo.lng + (Math.random() - 0.5) / 30000,
        };
      } else {
        loc = {
          lat: deviceInfo.lat,
          lng: deviceInfo.lng,
        };
      }

      let icon = { scaledSize: new window.google.maps.Size(34, 48) };
      if (deviceInfo._count.trapEventNotifications) icon.url = "marker/red.png";
      else if (deviceInfo.deviceStatus === "online")
        icon.url = "marker/green.png";
      else if (deviceInfo.deviceStatus === "offline")
        icon.url = "marker/gray.png";

      /*markers.push(
        <Marker
          key={deviceId}
          position={loc}
          clusterer={clusterer}
        >

        </Marker>
      );*/

      markers.push(
        <Marker
          key={deviceId}
          position={loc}
          icon={icon}
          clusterer={clusterer}
          zIndex={99}
          animation={window.google.maps.Animation.DROP}
          onMouseOver={() => {
            setShowInfoWindow(true);
            setActiveId(deviceId);
          }}
          onMouseOut={() => {
            setShowInfoWindow(false);
            setActiveId("");
          }}
          onClick={() => {
            let own = user.id === deviceInfo.ownerId ? true : false;
            history.push({
              pathname: "/device/" + deviceInfo.id,
              state: {
                devid: deviceInfo.id,
                own: own,
              },
            });
          }}
        >
          {activeId === deviceId && showInfoWindow && (
            <InfoWindow>
              <>
                <span className="classbold">ID:</span>
                <span> {deviceInfo.deviceCode}</span>
                <br />
                <span className="classbold">Name:</span>
                <span> {deviceInfo.deviceName}</span>
                <br />
                {deviceInfo.deviceStatus === "online" ? (
                  <FiWifi
                    style={{
                      color: "#27AE60",
                      width: "16px",
                      height: "16px",
                      margin: "5px",
                    }}
                  />
                ) : (
                  <FiWifiOff
                    style={{
                      color: "#BDBDBD",
                      width: "16px",
                      height: "16px",
                      margin: "5px",
                    }}
                  />
                )}
                {deviceInfo._count.trapEventNotifications === 0 ? (
                  <Badge
                    variant="secondary"
                    style={{
                      fontSize: "18px",
                      color: "#333333",
                      backgroundColor: "#E0E0E0",
                    }}
                  >
                    0
                  </Badge>
                ) : (
                  <Badge variant="danger" style={{ fontSize: "18px" }}>
                    {deviceInfo._count.trapEventNotifications}
                  </Badge>
                )}
              </>
            </InfoWindow>
          )}
        </Marker>
      );
    }
    return markers;
  };

  return (
    <GoogleMap
      mapContainerStyle={props.mapContainerStyle}
      center={center}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {isGoogleMapsAPILoaded && (
        <MarkerClusterer options={options}>
          {(clusterer) => createMarkers(clusterer)}
        </MarkerClusterer>
      )}
    </GoogleMap>
  );
};

DevicesMap.defaultProps = {
  center: {
    lat: 43.842466,
    lng: -79.423213,
  },
};
