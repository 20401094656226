import React, { useState, useEffect } from "react";
import { Form, Button, Toast, Container, Row, Modal } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import {
  FaTrash,
  // FaSortAmountDownAlt,
  FaEyeSlash,
  FaEye,
} from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import { PRODUCT_SERVER } from "../../Config";
import { useHistory } from "react-router-dom";

export const ProductList = () => {
  let history = useHistory();
  const [productList, setProductList] = useState([]);

  const [showDeleteOneModal, setShowDeleteOneModal] = useState(false);
  const [theProduct, setTheProduct] = useState(undefined);

  const [showDeleteSelectedModal, setShowDeleteSelectedModal] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  let disabledProductSelected = selectedList.length < 1 ? true : false;

  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");

  useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line
  }, []);

  const getAllProducts = () => {
    axios
      .post(`${PRODUCT_SERVER}/getallproducts`, {})
      .then((data) => {
        if (data.data.success) {
          setProductList(data.data.products);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onOpenAddNewProduct = () => {
    history.push("/addproduct");
  };

  const showonhomeFormatter = (cell, row, rowIndex, formatExtraData) => {
    let divBorder = cell ? "1px solid #27AE60" : "1px solid #BDBDBD";
    let divBackgroundColor = cell ? "#27AE60" : "white";
    let divColor = cell ? "white" : "black";
    return (
      <Button
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
          borderRadius: "4px",
          border: divBorder,
          background: divBackgroundColor,
          color: divColor,
        }}
      >
        {cell ? <FaEye /> : <FaEyeSlash color="disabled" />}
      </Button>
    );
  };

  const imageFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (cell === undefined || cell === null) return null;
    let pos = cell.indexOf(",");
    let firstURL = "";
    if (pos > -1) firstURL = "/product_pics/" + cell.slice(0, pos);

    return pos === -1 ? null : (
      <img
        alt="productimage"
        src={firstURL}
        style={{ width: "100px", height: "66px" }}
      />
    );
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="actionFormat">
        <Button
          className="normalButton actionBtn"
          style={{ borderRadius: "4px 0px 0px 4px" }}
          onClick={() => {
            history.push({
              pathname: "/editproduct",
              state: {
                productid: row.id,
              },
            });
          }}
        >
          <MdEdit />
        </Button>

        <Button
          variant="outline-secondary"
          className="actionBtn"
          style={{ borderRadius: "0px 4px 4px 0px" }}
          onClick={() => {
            setTheProduct(row);
            setShowDeleteOneModal(true);
          }}
        >
          <FaTrash />
        </Button>
      </div>
    );
  };

  const columns = [
    {
      dataField: "productName",
      text: "Name",
      headerStyle: { color: "#F47721", cursor: "pointer" },
      style: { cursor: "pointer" },
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/productdetail/" + row.id,
            state: {
              productid: row.id,
            },
          });
        },
      },
    },
    {
      dataField: "imageUrls",
      text: " ",
      headerStyle: { cursor: "pointer" },
      style: { cursor: "pointer" },
      formatter: imageFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push({
            pathname: "/productdetail/" + row.id,
            state: {
              productid: row.id,
            },
          });
        },
      },
    },
    {
      dataField: "showOnHome",
      text: "On home",
      formatter: showonhomeFormatter,
      sort: true,
      headerStyle: { color: "#F47721", cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          axios
            .post(`${PRODUCT_SERVER}/setshowonhome`, {
              id: row.id,
              productname: row.productname,
            })
            .then((data) => {
              if (data.data.success) {
                getAllProducts();
              } else {
                console.log("unsuccess");
              }
            })
            .catch(() => {
              console.log("Oops, request failed!");
            });
        },
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    bgColor: "#EAF6FC",
    style: { backgroundColor: "red" },
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedList((selectedList) => {
        if (isSelect) {
          return [...selectedList, row];
        } else {
          let k = selectedList.findIndex((temp) => temp.id === row.id);
          return [
            ...selectedList.slice(0, k),
            ...selectedList.slice(k + 1, selectedList.length),
          ];
        }
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      setSelectedList((selectedList) => {
        if (isSelect) {
          return [...productList];
        } else {
          return [];
        }
      });
    },
  };

  const pnoptions = {
    hideSizePerPage: true,
  };

  const onClickDeleteOne = () => {
    axios
      .post(`${PRODUCT_SERVER}/deleteoneproduct`, {
        id: theProduct.id,
        productname: theProduct.productname,
      })
      .then((data) => {
        if (data.data.success) {
          setShowToast(true);
          setToastText("Product deleted successfully.");
          getAllProducts();
          setShowDeleteOneModal(false);
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onClickDeleteSelected = () => {
    let ids = [];
    for (let i in selectedList) {
      ids.push(selectedList[i].id);
    }

    axios
      .post(`${PRODUCT_SERVER}/deleteselectedproducts`, {
        ids,
      })
      .then((data) => {
        if (data.data.success) {
          setShowToast(true);
          setToastText("Selected products deleted successfully.");
          getAllProducts();
          setShowDeleteSelectedModal(false);
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  return (
    <div className="sub_content">
      <div className="sub_title">Product list</div>
      <div className="sub_body">
        <Toast
          delay={3000}
          autohide
          show={showToast}
          onClose={() => setShowToast(false)}
          style={{
            position: "fixed",
            top: 10,
            right: 10,
            paddingLeft: 20,
            zIndex: 8,
            width: 400,
            borderTop: "4px solid #F47721",
          }}
        >
          <Toast.Body>{toastText}</Toast.Body>
        </Toast>

        <Form.Group>
          <Button
            variant="outline-dark"
            className="controlBtn"
            disabled={disabledProductSelected}
            onClick={() => {
              setShowDeleteSelectedModal(true);
            }}
          >
            <FaTrash style={{ marginRight: "6px" }} />
            Delete selected
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            className="normalButton"
            onClick={() => {
              onOpenAddNewProduct();
            }}
          >
            <BsPlus style={{ marginRight: "6px" }} />
            Add new product
          </Button>
        </Form.Group>
        <BootstrapTable
          keyField="id"
          data={productList}
          columns={columns}
          bordered={false}
          striped={true}
          selectRow={selectRow}
          pagination={paginationFactory(pnoptions)}
        />
        {theProduct === undefined ? null : (
          <Modal
            show={showDeleteOneModal}
            onHide={() => {
              setShowDeleteOneModal(false);
            }}
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>Delete product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <p>
                    Are you sure want to delete product {theProduct.productname}
                    ?
                  </p>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Button
                    className="normalButton"
                    onClick={() => {
                      onClickDeleteOne();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="cancelButton"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setShowDeleteOneModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        )}

        {disabledProductSelected ? null : (
          <Modal
            show={showDeleteSelectedModal}
            onHide={() => {
              setShowDeleteSelectedModal(false);
            }}
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>Delete products</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <p>Are you sure want to delete selected products?</p>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Button
                    className="normalButton"
                    onClick={() => {
                      onClickDeleteSelected();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="cancelButton"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setShowDeleteSelectedModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </div>
  );
};
