import React, { useEffect, useState } from "react";

import NavBar from "../NavBar";
import FooterBar from "../FooterBar";
import axios from "axios";
import { FILZERDOC_SERVER } from "../../Config";
import MenuBar from "../MenuBar";

function AboutPage() {
  const [content, setContent] = useState(undefined);
  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");
  const [loggedIn, setLoggedIn] = useState(false);
  let user = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => {
    checkLoggedIn();
    getContent();
    // eslint-disable-next-line
  }, []);

  const checkLoggedIn = () => {
    user === undefined || user === null
      ? setLoggedIn(false)
      : setLoggedIn(true);

    user === undefined || user === null
      ? setMainClass("body_panel col-sm-12")
      : setMainClass("body_panel col-sm-10");
  };

  const getContent = () => {
    axios
      .post(`${FILZERDOC_SERVER}/getorcreatebytag`, {
        tag: "about",
      })
      .then((data) => {
        if (data.data.success) {
          setContent(data.data.filzerdoc.content);
        } else {
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu && window.innerWidth > 981
    ? setMainClass("body_panel col-sm-10")
    : setMainClass("body_panel col-sm-12");
  };

  return (
    <>
      <NavBar updateHamburger={updateHamburger} />
      <div className="mainpanel container-fluid">
        <div className="row">
          {(propShowMenu && loggedIn) ? <MenuBar /> : null}
          <div className={mainClass} style={{padding: 0}}>
            <div className="myMain">
              {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
              <div className="terms">
                <div className="terms_main">
                  <div
                    className="termstext"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                </div>
              </div>
            </div>
            <FooterBar />
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutPage;