import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Button,
  Toast,
  Container,
  Row,
  Modal,
  Badge,
} from "react-bootstrap";
import { MdEdit, MdShare, MdClose } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import { SHAREDDEVICE_SERVER } from "../../Config";

function ShareDeviceModal(props) {
  // props.sharedDevices
  // props.show
  // pros.onHide
  //const [sharedDevices, setSharedDevices] = useState(props.sharedDevices);

  // props.deviceId
  // props.ownerId
  // props.show
  // TODO: props.onUpdate
  const [deviceId, setDeviceId] = useState(props.deviceId);
  const [ownerId, setOwnerId] = useState(props.ownerId);
  const [sharedDevices, setSharedDevices] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareEmail, setShareEmail] = useState("");
  const [shareDeviceError, setShareDeviceError] = useState("");
  const [shareDeviceSuccess, setShareDeviceSuccess] = useState("");
  

  useEffect(() => {
    //getSharedDeviceList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShowShareModal(props.show);
    if (props.show) getSharedDeviceList();
    console.log('props.show changed to:', props.show);
    // eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    setDeviceId(props.deviceId);
    //getSharedDeviceList();
    // eslint-disable-next-line
  }, [props.deviceId]);

  useEffect(() => {
    setOwnerId(props.ownerId);
    //getSharedDeviceList();
    // eslint-disable-next-line
  }, [props.ownerId]);

  const onCloseShareModal = () => {
    /*setShareDeviceError("");
    setShareDeviceSuccess("");
    setShareEmail("");*/
    props.onClose();
  };

  const onClickShare = () => {
    setShareDeviceError("");
    setShareDeviceSuccess("");
    axios
      .post(`${SHAREDDEVICE_SERVER}/addshareddevice`, {
        deviceid: deviceId,
        owner: ownerId,
        email: shareEmail,
      })
      .then((data) => {
        if (data.data.success) {
          getSharedDeviceList();
          setShareDeviceSuccess(data.data.message);
          props.updateSharedBymeDeviceListLength(); //refresh sharedbyme tag number
        } else {
          setShareDeviceError(data.data.message);
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const handleShareEmailChange = (e) => {
    setShareEmail(e.target.value);
  };

  const createSharedList = () => {
    let items = [];
    for (let i in sharedDevices) {
      let shared = sharedDevices[i];
      const sharedDeviceStatuses = {
        "accept": "Accepted",
        "pending": "Pending",
        "decline": "",
        "block": "",
        "notallow": "",
      };
      let sharedStatus = sharedDeviceStatuses[shared.status];
      let nameValue;
      if (shared.sharedWithUser != undefined && shared.sharedWithUser != null) {
        if (shared.sharedWithUser.firstName === null && shared.sharedWithUser.lastName === null)
          nameValue = shared.sharedWithUserEmail;
        else if (shared.sharedWithUser.firstName === null) nameValue = shared.sharedWithUser.lastName;
        else if (shared.sharedWithUser.lastName === null) nameValue = shared.sharedWithUser.firstName;
        else nameValue = `${shared.sharedWithUser.firstName} ${shared.sharedWithUser.lastName}`;
      }
      items.push(
        <Row
          key={i}
          className="sharemodallist"
          style={{ marginTop: "10px", paddingBottom: "10px" }}
        >
          <Col xs={5}>{nameValue} ({shared.sharedWithUserEmail})</Col>
          <Col xs={3}>{sharedStatus}</Col>
          <Col xs={4} className="devdetailright">
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => onClickRemove(shared.deviceId, shared.sharedWithUserId, shared.id)}
            >
              <MdClose /> Remove
            </Button>
          </Col>
        </Row>
      );
    }
    return items;
  };

  const onClickRemove = (deviceid, sharedto, sharedDeviceId) => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/removebyid`, {
        id: sharedDeviceId,
      })
      .then((data) => {
        if (data.data.success) {
          getSharedDeviceList(); //refresh modal
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });

    /*axios
      .post(`${SHAREDDEVICE_SERVER}/removebydeviceidsharedto`, {
        deviceid,
        sharedto,
      })
      .then((data) => {
        if (data.data.success) {
          getSharedDeviceList(); //refresh modal
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });*/
  };

  const onClickRemoveAll = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/removebydeviceidall`, {
        deviceid: deviceId,
        owner: ownerId,
      })
      .then((data) => {
        if (data.data.success) {
          setSharedDevices([]);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getSharedDeviceList = () => {
    axios
      .post(`${SHAREDDEVICE_SERVER}/getbydeviceidowner`, {
        deviceid: deviceId,
        owner: ownerId,
      })
      .then((data) => {
        if (data.data.success) {
          setSharedDevices(data.data.shareddevices);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  return (
    <Modal show={showShareModal} onHide={onCloseShareModal}>
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>Shared device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {shareDeviceError && (
          <label>
            <p
              style={{
                color: "#EB5757",
                fontSize: "14px",
                marginTop: "-10px",
              }}
            >
              {shareDeviceError}
            </p>
          </label>
        )}
        {shareDeviceSuccess && (
          <label>
            <p
              style={{
                color: "green",
                fontSize: "14px",
                marginTop: "-10px",
              }}
            >
              {shareDeviceSuccess}
            </p>
          </label>
        )}
        <Container>
          <Row>
            <Col sm={9} className="sharedEmailInput">
              <Form.Control
                placeholder="Enter email address"
                value={shareEmail}
                onChange={handleShareEmailChange}
              />
            </Col>
            <Col sm={3} className="sharedShareBtn">
              <Button className="normalButton" onClick={onClickShare}>
                Share
              </Button>
            </Col>
          </Row>
          <Row
            className="sharemodallistheader"
            style={{ marginTop: "20px" }}
          >
            <Col sm={5} className="sharedUser">
              Shared with
            </Col>
            <Col sm={3} className="sharedRemove">
              Status
            </Col>
            <Col xs={4}></Col>
          </Row>
          {createSharedList()}
        </Container>
      </Modal.Body>
      <Modal.Footer
        style={{ borderTop: "none", justifyContent: "flex-end" }}
      >
        <Col sm={4} className="sharedRemove">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => {
              onClickRemoveAll();
            }}
          >
            <MdClose /> Remove all
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default ShareDeviceModal;
