import React, { useState, useEffect } from "react";
import { Form, Col, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { DEVICE_SERVER } from "../../Config";
import { Autocomplete } from "@react-google-maps/api";

import AddressMap from "./AddressMap";

function EditDevice(props) {
  const [editdevice, setEditDevice] = useState(props.editdevice);
  // let editdevice = props.editdevice;

  useEffect(() => {
    setEditDevice(props.editdevice);
    setDeviceName(props.editdevice.deviceName);
    setPosition(props.editdevice.position);
    setAddress(props.editdevice.address);
    setMarkerPosition({
      lat: parseFloat(props.editdevice.lat),
      lng: parseFloat(props.editdevice.lng),
    });
  }, [props.editdevice]);

  // const [userInput, setUserInput] = useReducer(
  //   (state, newState) => ({ ...state, ...newState }),
  //   {
  //     devicename: editdevice.devicename, position: editdevice.position,
  //   }
  // );

  const [adderrormessage, setAddErrorMessage] = useState("");
  const [addsuccessmessage, setAddSuccessMessage] = useState("");

  const [address, setAddress] = useState(editdevice.address);
  const [deviceName, setDeviceName] = useState(editdevice.deviceName);
  const [position, setPosition] = useState(editdevice.position);

  const [markerPosition, setMarkerPosition] = useState({
    lat: parseFloat(editdevice.lat),
    lng: parseFloat(editdevice.lng),
  });

  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autocomplete) => {
    /* ac : autocomplete object variable */
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      let place = autocomplete.getPlace();
      let address = place.formatted_address,
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      console.log(address);

      setAddress(address);
      setMarkerPosition({
        lat: latValue,
        lng: lngValue,
      });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleDevicenameChange = (e) => {
    setDeviceName(e.target.value);
  };

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const initErrorMessages = () => {
    setAddErrorMessage((adderrormessage) => "");
    setAddSuccessMessage((addsuccessmessage) => "");
  };
  const onSubmitUpdateDevice = () => {
    initErrorMessages();
    let users = JSON.parse(window.localStorage.getItem("user"));

    axios
      .post(`${DEVICE_SERVER}/eidtdevice`, {
        ownerId: users.id,
        deviceId: editdevice.id,
        localIp: editdevice.localIp,
        deviceName: deviceName,
        address: address,
        lat: markerPosition.lat,
        lng: markerPosition.lng,
        position: position,
      })
      .then((data) => {
        if (!data.data.success) {
          setAddErrorMessage((adderrormessage) => data.data.message);
        } else if (data.data.success) {
          console.log("success");
          setAddSuccessMessage((addsuccessmessage) => data.data.message);
          props.updateEditedDeviceList(data.data.device);
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const onCloseModal = () => {
    initErrorMessages();
    props.onClickShowEditDeviceModal();
  };

  return (
    <>
      <Modal show={props.show} onHide={onCloseModal}>
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Edit device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {adderrormessage && (
            <label>
              <p
                style={{
                  color: "#EB5757",
                  fontSize: "14px",
                  marginTop: "-10px",
                }}
              >
                {adderrormessage}
              </p>
            </label>
          )}
          {addsuccessmessage && (
            <label>
              <p
                style={{ color: "green", fontSize: "14px", marginTop: "-10px" }}
              >
                {addsuccessmessage}
              </p>
            </label>
          )}
          <Form.Row>
            <Form.Group as={Col} sm={6}>
              <Form.Label>Device ID</Form.Label>
              <Form.Control
                readOnly={true}
                value={editdevice.deviceCode}
                name="deviceid"
              />
            </Form.Group>
            <Form.Group as={Col} sm={6}>
              <Form.Label>Local IP</Form.Label>
              <Form.Control
                readOnly={true}
                value={editdevice.localIp}
                name="localip"
              />
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Device name</Form.Label>
            <Form.Control
              value={deviceName}
              name="deviceName"
              onChange={handleDevicenameChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Address</Form.Label>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <input
                type="text"
                placeholder="Enter your device address"
                style={{
                  width: "100%",
                  // height: '40px',
                  height: "calc(1.5em + .75rem + 2px)",
                  padding: ".75rem",
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#495057",
                  backgroundColor: "#fff",
                  backgroundClip: "paddingBox",
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  transition:
                    "borderColor .15s easeInOut,boxShadow .15s easeInOut",
                }}
                defaultValue={address}
              />
            </Autocomplete>
          </Form.Group>
          <Form.Group>
            <AddressMap
              center={markerPosition}
              mapContainerStyle={{
                width: "100%",
                height: "200px",
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Position</Form.Label>
            <Form.Control
              value={position}
              name="position"
              onChange={handlePositionChange}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer
          style={{ borderTop: "none", justifyContent: "flex-start" }}
        >
          <Button className="normalButton" onClick={onSubmitUpdateDevice}>
            Update
          </Button>
          <Button className="cancelButton" onClick={onCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditDevice;
