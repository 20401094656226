export const deviceTypes = {
    A1: 'Super Animal Cage',
    A2: 'Super Rat Trap',
    B1: 'Mouse Trap',
    C1: 'Snap Rat Trap',
    C2: 'Excluder',
    C3: 'Multi-Catch Mouse Trap',
    C4: 'Bug Monitor',
    C5: 'Smart Cage Addon',
    C6: 'Bait Station',
    E1: 'Snap Rat Trap',
    E2: 'Excluder',
    E3: 'Multi-Catch Mouse Trap',
    E4: 'Bug Monitor',
    E5: 'Smart Cage Addon',
    E6: 'Bait Station',
    E7: 'Dual Snap Mouse Trap',
    E8: 'Trail Cam',
    E9: 'Bird Feeder',
    F1: 'Super Animal Cage',
    F2: 'Super Rat Trap',
};