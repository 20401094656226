import React, { useState, useEffect } from "react";
import { Tabs, Tab, Button, ButtonGroup, Badge, Form } from "react-bootstrap";
import MenuBar from "../MenuBar";
import EditDevice from "./EditDevice";
import { MdEdit } from "react-icons/md";
import { FiWifi, FiWifiOff } from "react-icons/fi";
// import { FaTrash } from "react-icons/fa";
import axios from "axios";
import { DEVICE_SERVER } from "../../Config";
import { TRAPHISTORY_SERVER } from "../../Config";

import { CameraControl } from "./CameraControl";
import { DeviceDetail } from "./DeviceDetail";
import NavBar from "../NavBar";
import { THP } from "./THP";
import { Schedule } from "./Schedule";
import { deviceTypes } from "../../DeviceTypes";

import { DebugEvents } from "./DebugEvents";

import { useParams } from "react-router-dom";

import {
  getWs,
  registerEventHandler,
  unRegisterEventHandler,
  sendJsonByWebSocket,
} from "../../../common";

export const DevicePage = (props) => {
  let user = JSON.parse(window.localStorage.getItem("user"));

  const deviceIdParam = parseInt(useParams().id);
  const [isOwner, setIsOwner] = useState(false);

  const [initNav, setInitNav] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState([]);
  const [showEditDeviceModal, setShowEditDeviceModal] = useState(false);
  const [isDeviceConnected, setIsDeviceConnected] = useState(false);
  const [autoCount, setAutoCount] = useState(0);

  const [propShowMenu, setPropShowMenu] = useState(true);
  const [mainClass, setMainClass] = useState("body_panel col-sm-10");
  const [keyTab, setKeyTab] = useState("");
  const [socketMsg, setSocketMsg] = useState(undefined);

  useEffect(() => {
    // if (user === null || user === undefined) props.history.push('/signin');

    if (window.innerWidth < 981) {
      setMainClass("body_panel col-sm-12");
      setPropShowMenu(false);
    }

    if (user == null) return;

    if (isNaN(deviceIdParam)) return;

    axios
      .post(`${DEVICE_SERVER}/getdevicebydeviceid`, {
        deviceid: deviceIdParam,
      })
      .then((data) => {
        if (data.data.success) {
          if (!data.data.device) {
            props.history.push("/devicelist");
            return;
          }
          let deviceinfo = data.data.device;
          setIsOwner(deviceinfo.ownerId == user.id);
          setDeviceInfo(deviceinfo);
          markTraphistoriesAsRead();
          setKeyTab("camera");
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    ////////////////////////////////////////////////////////////////
    // WebSocket
    let ws = getWs();
    const users = JSON.parse(window.localStorage.getItem("user"));
    const browserId = window.localStorage.getItem("browserId");
    if (users == null) return;
    const userId = users.id;
    if (ws !== null) {
      let json = {
        userId: userId,
        browserId: browserId,
        receiver: "server",
        deviceIds: [deviceIdParam],
        message: "registerBrowser",
        page: keyTab,
      };
      registerEventHandler(keyTab, onmessage, json);
    }

    return () => {
      let jsonUnReg = {
        userId: userId,
        browserId: browserId,
        receiver: "server",
        deviceIds: [deviceIdParam],
        message: "unregisterBrowser",
        page: keyTab,
      };
      unRegisterEventHandler(jsonUnReg);
    };
    // eslint-disable-next-line
  }, [keyTab]);

  const onmessage = (message) => {
    if (
      (message.message === "registerBrowser" &&
        typeof message.status === "string") ||
      message.message === "deviceStatus"
    ) {
      setIsDeviceConnected(message.status === "online" ? true : false);
      let json = {
        message: "statusReq",
      };
      sendJsonByWebSocket(getWs(), deviceIdParam, json);
    }

    setSocketMsg(message);
  };

  const markTraphistoriesAsRead = () => {
    axios
      .post(`${TRAPHISTORY_SERVER}/markasreadbydeviceiduserid`, {
        deviceid: deviceIdParam,
        userid: user.id,
      })
      .then((data) => {
        setInitNav((initNav) => !initNav);
      });
  };

  const onClickShowEditDeviceModal = () => {
    setShowEditDeviceModal((showEditDeviceModal) => !showEditDeviceModal);
  };

  const updateEditedDeviceList = (editeddevice) => {
    setDeviceInfo(editeddevice);
    // props.history.push("/devicelist");
  };

  // const deleteDevice = () => {
  //   axios
  //     .post(`${DEVICE_SERVER}/removeonedevice`, {
  //       deviceid: deviceInfo.deviceid,
  //       owner: deviceInfo.owner,
  //     })
  //     .then((data) => {
  //       if (data.data.success) {
  //         props.history.push("/devicelist");
  //       } else {
  //         console.log("unsuccess");
  //       }
  //     })
  //     .catch(() => {
  //       console.log("Oops, request failed!");
  //     });
  // };

  const updateNoti = () => {
    console.log("update notification");
    setInitNav((initNav) => !initNav);
  };

  const updateDeviceConnected = (isConnected) => {
    setIsDeviceConnected(isConnected);
  };

  const updateAutoCount = (autoCountFromCam) => {
    setAutoCount(autoCountFromCam);
  };

  //////////////////////////// Hamburger menu  ////////////////////////////////////////////////
  const updateHamburger = (childShowMenu) => {
    setPropShowMenu(childShowMenu);
    childShowMenu && window.innerWidth > 981
      ? setMainClass("body_panel col-sm-10")
      : setMainClass("body_panel col-sm-12");
  };

  // if (user === null || user === undefined) {
  //     return <Redirect to='/signin' />;
  // }else{

  const onSelectTab = (e) => {
    setKeyTab(e);
  };

  const onChangeDropdown = (e) => {
    setKeyTab(e.target.value);
  };

  const getModelAndDeviceDetail = (devInfo) => {
    let returnValue;
    returnValue = `${deviceTypes[devInfo.model]} (${devInfo.model}) - ${devInfo.deviceName}`;
    return returnValue;
  };

  return deviceInfo === [] ? null : (
    <>
      <NavBar initNav={initNav} updateHamburger={updateHamburger} />
      <div className="mainpanel container-fluid">
        <div className="row">
          {propShowMenu ? <MenuBar /> : null}
          <div className={mainClass}>
            <div className="page_title" style={{ display: "flex" }}>
              <div className="title_name">
                {isDeviceConnected ? (
                  <FiWifi
                    style={{
                      color: "#27AE60",
                      width: "18px",
                      margin: "5px",
                    }}
                  />
                ) : (
                  <FiWifiOff
                    style={{
                      color: "#BDBDBD",
                      width: "18px",
                      margin: "5px",
                    }}
                  />
                )}
                <Badge
                  variant="secondary"
                  style={{
                    paddingTop: "10px",
                    color: "#333333",
                    backgroundColor: "#E0E0E0",
                    marginRight: "8px",
                  }}
                >
                  {autoCount}
                </Badge>
                {getModelAndDeviceDetail(deviceInfo)}
              </div>
              <div className="title_action">
                {props.location.state != undefined ? (
                    !isOwner ? null : (
                    <ButtonGroup size="sm" style={{ marginLeft: "20px" }}>
                      <Button
                        className="normalButton"
                        onClick={onClickShowEditDeviceModal}
                      >
                        <MdEdit style={{ marginRight: "6px" }} />
                        Edit device
                      </Button>
                      {/* <Button
                        style={{
                          background: "white",
                          fontWeight: "bold",
                          color: "#333333",
                          border: "1px solid #828282",
                        }}
                        onClick={deleteDevice}
                      >
                        <FaTrash style={{ marginRight: "6px" }} />
                        Delete device
                      </Button> */}
                    </ButtonGroup>
                  )
                )
                : null}
                {deviceInfo === undefined ? null : (
                  <EditDevice
                    show={showEditDeviceModal}
                    editdevice={deviceInfo}
                    onClickShowEditDeviceModal={onClickShowEditDeviceModal}
                    updateEditedDeviceList={updateEditedDeviceList}
                  />
                )}
              </div>
            </div>
            <div className="page_content">
              <Form.Group className="devicelistdropdown">
                <Form.Control
                  // value={resolution}
                  onChange={(e) => {
                    onChangeDropdown(e);
                  }}
                  as="select"
                >
                  <option value="camera">Camera control</option>
                  <option value="detail">Device detail</option>
                  {deviceInfo.model === "A1" &&
                    <option value="thp">Temperature, Humidity</option>
                  }
                  {(String(deviceInfo.model).includes("E") || String(deviceInfo.model).includes("F")) &&
                    <option value="thp">Temperature, Humidity</option>
                  }
                  <option value="schedule">Schedule</option>
                </Form.Control>
              </Form.Group>

              <Tabs
                activeKey={keyTab}
                transition={false}
                className="devicelisttabs"
                onSelect={(e) => {
                  onSelectTab(e);
                }}
              >
                <Tab
                  eventKey="camera"
                  title="Camera control"
                  tabClassName="sub_menus"
                >
                  {
                    deviceIdParam != undefined ? (
                    deviceIdParam === undefined ||
                    //   deviceInfo === [] ||
                    socketMsg === undefined ||
                    keyTab !== "camera" ? null : (
                      <CameraControl
                        deviceId={deviceIdParam}
                        own={isOwner}
                        deviceModel={deviceInfo.model}
                        // deviceModel="B1"
                        socketMsg={socketMsg}
                        updateNoti={updateNoti}
                        updateDeviceConnected={updateDeviceConnected}
                        updateAutoCount={updateAutoCount}
                      />
                    )
                  )
                  : null}
                </Tab>
                <Tab
                  eventKey="detail"
                  title="Device detail"
                  tabClassName="sub_menus"
                >
                  {deviceInfo === undefined ||
                  deviceInfo.length < 1 ||
                  keyTab !== "detail" ? null : (
                    <DeviceDetail
                      deviceInfo={deviceInfo}
                      own={isOwner}
                      isDeviceConnected={isDeviceConnected}
                      autoCount={autoCount}
                      updateEditedDeviceList={updateEditedDeviceList}
                    />
                  )}
                </Tab>
                {deviceInfo.model !== "A1" ? null : (
                  <Tab
                    eventKey="thp"
                    title="Temperature, Humidity"
                    tabClassName="sub_menus"
                  >
                    {
                    deviceIdParam === undefined ||
                    socketMsg === undefined ||
                    keyTab !== "thp" ? null : (
                      <THP
                        socketMsg={socketMsg}
                        deviceId={deviceIdParam}
                      />
                    )}
                  </Tab>
                )}
                {(
                  String(deviceInfo.model).includes("E") ||
                  String(deviceInfo.model).includes("F")
                  ) ? (
                  <Tab
                    eventKey="thp"
                    title="Temperature, Humidity"
                    tabClassName="sub_menus"
                  >
                    {
                    deviceIdParam === undefined ||
                    socketMsg === undefined ||
                    keyTab !== "thp" ? null : (
                      <THP
                        socketMsg={socketMsg}
                        deviceId={deviceIdParam}
                      />
                    )}
                  </Tab>
                ) : null}
                {(
                  String(deviceInfo.model).includes("C") ||
                  String(deviceInfo.model).includes("A") ||
                  String(deviceInfo.model).includes("E") ||
                  String(deviceInfo.model).includes("F")
                  ) &&
                  socketMsg !== undefined && (
                    <Tab
                      eventKey="schedule"
                      title="Scheduled pictures"
                      tabClassName="sub_menus"
                    >
                      {keyTab === "schedule" && (
                        <Schedule
                          deviceId={deviceIdParam}
                          socketMsg={socketMsg}
                          own={isOwner}
                        />
                      )}
                    </Tab>
                  )}
                  <Tab
                    eventKey="debugEvents"
                    title="System logs"
                    tabClassName="sub_menus"
                  >
                    {keyTab === "debugEvents" && (
                      <DebugEvents
                      socketMsg={socketMsg}
                      deviceInfo={deviceInfo}
                      own={isOwner}
                      deviceId={deviceIdParam}
                      />
                    )}
                  </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};