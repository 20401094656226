import React from "react";
import axios from "axios";
import { USER_SERVER } from "../../Config";

import NavBar from "../NavBar";
import FooterBar from "../FooterBar";

class ForgotpasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", emailError: "", emailSuccess: false };
  }

  handlechange = (e) => {
    this.setState({ email: e.target.value });
  };

  onForgotSubmit = () => {
    this.setState({ emailSuccess: false, emailError: "" });
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@$%^&*()<>,.?/[]{}-=_+";
    var password = "";
    for (let i = 0; i < 8; ++i) {
      password += characters[Math.floor(Math.random() * 75)];
    }

    axios
      .post(`${USER_SERVER}/forgotpassword`, {
        email: this.state.email,
        password: password,
      })
      .then((data) => {
        if (data.data.success) {
          this.setState({ emailSuccess: data.data.msg });
        } else {
          this.setState({ emailError: data.data.msg });
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  render() {
    return (
      <>
        <NavBar />
        {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
        <div className="myMain">
          <div className="forgot">
            <div className="mainforgot">
              <div className="rect"></div>
              <div className="termstitle">Forgot password</div>
              <div className="forgotcontent">
                {this.state.emailError && (
                  <label>
                    <p
                      style={{
                        color: "#EB5757",
                        fontSize: "14px",
                        marginTop: "-10px",
                      }}
                    >
                      {this.state.emailError}
                    </p>
                  </label>
                )}
                {this.state.emailSuccess && (
                  <label>
                    <p
                      style={{
                        color: "green",
                        fontSize: "14px",
                        marginTop: "-10px",
                      }}
                    >
                      {this.state.emailSuccess}
                    </p>
                  </label>
                )}
                <span>
                  Enter your email address to reset your password.
                  <br />
                  Please follow the instruction inside your email.
                </span>
                <label
                  style={{
                    color: "#333333",
                    fontWeight: "bold",
                    margin: "15px 0px",
                  }}
                >
                  {" "}
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={this.state.email}
                  onChange={this.handlechange.bind(this)}
                />
                <button id="forgotsubmit" onClick={this.onForgotSubmit}>
                  Reset password
                </button>
              </div>
            </div>
          </div>
        </div>
        <FooterBar />
      </>
    );
  }
}

export default ForgotpasswordPage;
