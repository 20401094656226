import MenuBar from "../MenuBar";
import { Tabs, Tab, Form, Col, Button, Modal } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
// import { Autocomplete } from "@react-google-maps/api";
import React from "react";
import axios from "axios";
import { USER_SERVER } from "../../Config";
import NavBar from "../NavBar";

class MyAccountPage extends React.Component {
  constructor(props) {
    super(props);
    var users = JSON.parse(window.localStorage.getItem("user"));
    this.state = {
      id: users.id,
      firstName: users.firstName == null ? "" : users.firstName,
      lastName: users.lastName == null ? "" : users.lastName,
      email: users.email,
      company: users.company == null ? "" : users.company,
      mobileNumber: users.mobileNumber == null ? "" : users.mobileNumber,
      telephoneNumber:
        users.telephoneNumber == null ? "" : users.telephoneNumber,
      address: users.address == null ? "" : users.address,
      province: users.province == null ? "" : users.province,
      city: users.city == null ? "" : users.city,
      country: users.country == null ? "" : users.country,
      zipCode: users.zipCode == null ? "" : users.zipCode,
      emailChanged: users.newEmail == null ? false : true,
      showModal: false,
      newEmail: "",
      modalpassword: "",
      emailChangeError: "",
      emailChangeSuccess: "",
      emailChangeSent: false,
      currentpassword: "",
      newpassword: "",
      reenterpassword: "",
      currentPasswordError: "",
      passwordChangeSuccess: "",
      newPasswordError: "",
      reenterPasswordError: "",
      showDeleteAccountModal: false,
      showDeleteConfirmModal: false,
      deleteEmail: "",
      deletePassword: "",
      deleteAccountError: "",
      propShowMenu: true,
      mainClass: "body_panel col-sm-10",
      updateSuccess: "",
    };
    this.handleChange = this.handleChange.bind(this);
    // let history = useHistory();
  }

  componentDidMount() {
    if (window.innerWidth < 981)
      this.setState({ mainClass: "body_panel col-sm-12", propShowMenu: false });
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCancel = () => {
    var users = JSON.parse(window.localStorage.getItem("user"));
    this.setState({
      firstName: users.firstName == null ? "" : users.firstName,
      lastName: users.lastName == null ? "" : users.lastName,
      email: users.email,
      company: users.company == null ? "" : users.company,
      mobileNumber: users.mobileNumber == null ? "" : users.mobileNumber,
      telephoneNumber:
        users.telephoneNumber == null ? "" : users.telephoneNumber,
      address: users.address == null ? "" : users.address,
      province: users.province == null ? "" : users.province,
      city: users.city == null ? "" : users.city,
      country: users.country == null ? "" : users.country,
      zipCode: users.zipCode == null ? "" : users.zipCode,
    });
  };

  onUpdateProfile = () => {
    this.setState({ updateSuccess: "" });
    if (this.state.emailChanged) {
      alert("Please verify your email before update.");
    } else {
      axios
        .post(`${USER_SERVER}/updateprofile`, {
          id: this.state.id,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          company: this.state.company,
          mobileNumber: this.state.mobileNumber,
          telephoneNumber: this.state.telephoneNumber,
          address: this.state.address,
          province: this.state.province,
          city: this.state.city,
          country: this.state.country,
          zipCode: this.state.zipCode,
        })
        .then((data) => {
          if (data.data.success) {
            var users = JSON.parse(window.localStorage.getItem("user"));
            users.firstName = this.state.firstName;
            users.lastName = this.state.lastName;
            // users.email = this.state.email;
            users.company = this.state.company;
            users.mobileNumber = this.state.mobileNumber;
            users.telephoneNumber = this.state.telephoneNumber;
            users.address = this.state.address;
            users.province = this.state.province;
            users.city = this.state.city;
            users.country = this.state.country;
            users.zipCode = this.state.zipCode;
            window.localStorage.setItem("user", JSON.stringify(users));
            console.log(JSON.parse(window.localStorage.getItem("user")));
            this.setState({
              updateSuccess: "Successfully updated your profile.",
            });
          }
        })
        .catch(() => {
          console.log("Oops, request failed!");
        });
    }
  };

  onShowModal = () => {
    this.setState({ showModal: true });
  };

  onCloseModal = () => {
    this.setState({
      newEmail: "",
      modalpassword: "",
      showModal: false,
      emailChangeError: "",
      emailChangeSuccess: "",
    });
  };

  onClickSendEmail = () => {
    if (this.state.newEmail === this.state.email) {
      this.setState({
        emailChangeError:
          "The email address is same with current one. Please input another address.",
      });
    } else {
      axios
        .post(`${USER_SERVER}/sendemailchange`, {
          id: this.state.id,
          newEmail: this.state.newEmail,
          password: this.state.modalpassword,
          email: this.state.email,
        })
        .then((data) => {
          if (data.data.success) {
            this.setState({ emailChangeSuccess: data.data.msg });
            this.setState({ emailChanged: true });
          } else {
            this.setState({ emailChangeError: data.data.msg });
          }
        })
        .catch(() => {
          console.log("Oops, request failed!");
        });
    }
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "country" === addressArray[i].types[0]
        ) {
          country = addressArray[i].long_name;
          return country;
        }
      }
    }
  };

  getZipCode = (addressArray) => {
    let zipCode = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          zipCode = addressArray[i].long_name;
          return zipCode;
        }
      }
    }
  };

  onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      province = this.getState(addressArray),
      country = this.getCountry(addressArray),
      zipCode = this.getZipCode(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // Set these values in the state.
    this.setState({
      address: address ? address : "",
      city: city ? city : "",
      province: province ? province : "",
      country: country ? country : "",
      zipCode: zipCode ? zipCode : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
  };

  onUpdatePassword = () => {
    console.log(this.state.currentPasswordError);
    // let currentpassword = this.state.currentpassword;
    let newpassword = this.state.newpassword;
    let reenterpassword = this.state.reenterpassword;
    this.setState({ newPasswordError: "", currentPasswordError: "" });

    if (/(?=.*[a-zA-Z])(?=.*\d)/.test(newpassword) && newpassword.length > 7) {
      if (reenterpassword !== newpassword) {
        this.setState({ newPasswordError: "Password doesn't match" });
        this.handleCancelPassword();
      } else {
        axios
          .post(`${USER_SERVER}/changepassword`, {
            id: this.state.id,
            currentpassword: this.state.currentpassword,
            newpassword: this.state.newpassword,
          })
          .then((data) => {
            if (!data.data.changeSuccess) {
              console.log("on Failed.");
              this.setState({ currentPasswordError: data.data.message });
            } else {
              console.log("on Success.");
              this.setState({
                passwordChangeSuccess: "Password changed successfully.",
              });
              window.localStorage.removeItem("user");
              window.localStorage.setItem(
                "user",
                JSON.stringify(data.data.user)
              );
              console.log("Password changed successfully.");
            }
          })
          .catch(() => {
            console.log("Oops, request failed!");
          });
      }
    } else {
      this.setState({ newPasswordError: "Short password or Invalid rule" });
    }
  };

  handleCancelPassword = () => {
    this.setState({
      currentpassword: "",
      newpassword: "",
      reenterpassword: "",
    });
  };

  onShowDeleteAccountModal = () => {
    this.setState({ showDeleteAccountModal: true });
  };

  onCloseDeleteAccountModal = () => {
    this.setState({ showDeleteAccountModal: false });
  };

  onShowDeleteConfirmModal = () => {
    this.setState({ showDeleteConfirmModal: true });
  };

  onCloseDeleteConfirmModal = () => {
    this.setState({ showDeleteConfirmModal: false });
  };

  onDeleteAccount = () => {
    axios
      .post(`${USER_SERVER}/deleteownuser`, {
        userid: this.state.id,
        deleteEmail: this.state.deleteEmail,
        deletePassword: this.state.deletePassword,
      })
      .then((data) => {
        if (!data.data.success) {
          this.setState({ showDeleteConfirmModal: false });
          this.setState({ deleteAccountError: data.data.message });
        } else {
          axios.get(`${USER_SERVER}/logout`).then((response) => {
            if (response.status === 200) {
              window.localStorage.removeItem("user");
              this.props.history.push("/signin");
            } else {
              alert("Log Out Failed");
            }
          });
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  updateHamburger = (childShowMenu) => {
    this.setState({ propShowMenu: childShowMenu });
    let tempMainClass =
      childShowMenu && window.innerWidth > 981
        ? "body_panel col-sm-10"
        : "body_panel col-sm-12";
    this.setState({ mainClass: tempMainClass });
  };

  render() {
    return (
      <>
        <NavBar updateHamburger={this.updateHamburger} />
        {/* <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 90px)' }}> */}
        <div style={{ minHeight: "calc(100vh - 90px)" }}>
          <div className="mainpanel container-fluid">
            <div className="row">
              {this.state.propShowMenu ? <MenuBar /> : null}
              <div className={this.state.mainClass}>
                <div className="page_title">My Account</div>
                <div className="page_content">
                  <Tabs defaultActiveKey="accountinfo" transition={false}>
                    <Tab
                      eventKey="accountinfo"
                      title="Account information"
                      tabClassName="sub_menus"
                    >
                      <div className="sub_content">
                        <div className="sub_title">Account information</div>
                        <div className="sub_body">
                          <Form>
                            <Form.Row>
                              <Form.Group as={Col} sm={4}>
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                  value={this.state.firstName}
                                  name="firstName"
                                  onChange={this.handleChange}
                                />
                              </Form.Group>

                              <Form.Group as={Col} sm={4}>
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                  value={this.state.lastName}
                                  name="lastName"
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                            </Form.Row>

                            <Form.Row>
                              <Form.Group as={Col} sm={4}>
                                <Form.Label>Company</Form.Label>
                                <Form.Control
                                  name="company"
                                  value={this.state.company}
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                            </Form.Row>

                            <Form.Row>
                              <Form.Group
                                as={Col}
                                sm={4}
                                controlId="formGridEmail"
                              >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  ref="email"
                                  name="email"
                                  className={
                                    !this.state.emailChanged
                                      ? "is-valid validStatusEmail"
                                      : "is-invalid validStatusEmail"
                                  }
                                  // className={users.email == this.state.email ? "is-valid validStatusEmail" : "is-invalid validStatusEmail"}
                                  value={this.state.email}
                                  // onChange={this.handleChangeEmail}
                                  disabled={true}
                                />
                              </Form.Group>
                              <Form.Group as={Col} sm={2}>
                                {this.state.emailChanged ? (
                                  <Form.Label
                                    ref="verifiedStatus"
                                    onClick={this.onShowModal}
                                    style={{
                                      marginTop: "37px",
                                      color: "#F47721",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Resend verification
                                  </Form.Label>
                                ) : (
                                  <Form.Label
                                    ref="verifiedStatus"
                                    onClick={this.onShowModal}
                                    style={{
                                      marginTop: "37px",
                                      color: "#F47721",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Change email
                                  </Form.Label>
                                )}

                                <Modal
                                  show={this.state.showModal}
                                  onHide={this.onCloseModal}
                                >
                                  <Modal.Header
                                    closeButton
                                    style={{ borderBottom: "none" }}
                                  >
                                    <Modal.Title>Change email</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <p>
                                      Please enter your new email address and
                                      your password.
                                      <br />A email will be sent to your new
                                      address. Please follow the instructions in
                                      that email.
                                    </p>
                                    {this.state.emailChangeError && (
                                      <label>
                                        <p
                                          style={{
                                            color: "#EB5757",
                                            fontSize: "14px",
                                            marginTop: "-10px",
                                          }}
                                        >
                                          {this.state.emailChangeError}
                                        </p>
                                      </label>
                                    )}
                                    {this.state.emailChangeSuccess && (
                                      <label>
                                        <p
                                          style={{
                                            color: "green",
                                            fontSize: "14px",
                                            marginTop: "-10px",
                                          }}
                                        >
                                          {this.state.emailChangeSuccess}
                                        </p>
                                      </label>
                                    )}
                                    <Form.Group>
                                      <Form.Label>New email</Form.Label>
                                      <Form.Control
                                        value={this.state.newEmail}
                                        name="newEmail"
                                        onChange={this.handleChange}
                                      />
                                    </Form.Group>
                                    <Form.Group>
                                      <Form.Label> Current Password</Form.Label>
                                      <Form.Control
                                        type="password"
                                        value={this.state.modalpassword}
                                        name="modalpassword"
                                        onChange={this.handleChange}
                                      />
                                    </Form.Group>
                                  </Modal.Body>
                                  <Modal.Footer
                                    style={{
                                      borderTop: "none",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <Button
                                      className="normalButton"
                                      onClick={this.onClickSendEmail}
                                    >
                                      Save Changes
                                    </Button>
                                    <Button
                                      className="cancelButton"
                                      onClick={this.onCloseModal}
                                    >
                                      Close
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </Form.Group>
                            </Form.Row>

                            <Form.Row>
                              <Form.Group
                                as={Col}
                                controlId="formGridMobilePhone"
                                sm={4}
                              >
                                <Form.Label>Mobile Phone</Form.Label>
                                <Form.Control
                                  value={this.state.mobileNumber}
                                  name="mobileNumber"
                                  onChange={this.handleChange}
                                />
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                controlId="formGridLastName"
                                sm={4}
                              >
                                <Form.Label>Tel Phone</Form.Label>
                                <Form.Control
                                  name="telephoneNumber"
                                  value={this.state.telephoneNumber}
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                            </Form.Row>

                            <Form.Row>
                              <Form.Group
                                controlId="formGridAddress"
                                as={Col}
                                sm={9}
                              >
                                <Form.Label>Address</Form.Label>
                                <Autocomplete
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    padding: "6px 12px 6px 12px",
                                    marginTop: "2px",
                                    border: "1px solid #ced4da",
                                    borderRadius: ".25rem",
                                  }}
                                  onPlaceSelected={this.onPlaceSelected}
                                  defaultValue={this.state.address}
                                  types={["geocode"]}
                                />
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                sm={3}
                                controlId="formGridState"
                              >
                                <Form.Label>Province/State</Form.Label>
                                <Form.Control
                                  readOnly={true}
                                  value={this.state.province}
                                />
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                sm={3}
                                controlId="formGridCity"
                              >
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                  readOnly={true}
                                  value={this.state.city}
                                />
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                sm={3}
                                controlId="formGridCountry"
                              >
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                  readOnly={true}
                                  value={this.state.country}
                                />
                              </Form.Group>
                            </Form.Row>

                            <Form.Row>
                              <Form.Group
                                controlId="formGridZip"
                                as={Col}
                                sm={3}
                              >
                                <Form.Label>Postal/Zip code</Form.Label>
                                <Form.Control
                                  readOnly={true}
                                  value={this.state.zipCode}
                                />
                              </Form.Group>
                            </Form.Row>

                            {this.state.updateSuccess && (
                              <Form.Row>
                                <label>
                                  <p
                                    style={{
                                      color: "green",
                                      fontSize: "14px",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    {this.state.updateSuccess}
                                  </p>
                                </label>
                              </Form.Row>
                            )}
                            <Button
                              id="updateProfile"
                              className="normalButton"
                              onClick={this.onUpdateProfile}
                              style={{ marginRight: "8px" }}
                            >
                              Update
                            </Button>
                            <Button
                              className="cancelButton"
                              onClick={this.handleCancel.bind(this)}
                            >
                              Cancel
                            </Button>
                            <Form.Row>
                              <div
                                className="sub_title"
                                style={{ marginTop: "40px" }}
                              >
                                Delete Account
                              </div>
                            </Form.Row>
                            <Form.Row>
                              <span style={{ color: "#333333" }}>
                                Once your account is deleted, there is no going
                                back. Please be careful.
                              </span>
                            </Form.Row>
                            <Form.Row>
                              <Button
                                className="cancelButton"
                                onClick={this.onShowDeleteAccountModal}
                                style={{ color: "#EB5757", marginTop: "20px" }}
                              >
                                Delete your account
                              </Button>
                            </Form.Row>
                          </Form>
                          <Modal
                            show={this.state.showDeleteAccountModal}
                            onHide={this.onCloseDeleteAccountModal}
                          >
                            <Modal.Header
                              closeButton
                              style={{ borderBottom: "none" }}
                            >
                              <Modal.Title>Delete account</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form style={{ color: "#333333" }}>
                                {this.state.deleteAccountError && (
                                  <label>
                                    <p
                                      style={{
                                        color: "#EB5757",
                                        fontSize: "14px",
                                        marginTop: "-10px",
                                      }}
                                    >
                                      {this.state.deleteAccountError}
                                    </p>
                                  </label>
                                )}
                                <p>
                                  You are about to delete your account. Once you
                                  press the ‘Delete account’ button below, you
                                  will have 30 days to re-activate your account
                                  if you change your mind.
                                </p>
                                <p>
                                  If you do NOT re-activate your account within
                                  30 days, ALL your data will be deleted and
                                  your data is NOT recoverable.
                                </p>
                                <p>
                                  To delete your account, please confirm your
                                  email address and password.{" "}
                                </p>
                                <Form.Row>
                                  <Form.Group as={Col}>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                      name="deleteEmail"
                                      type="email"
                                      value={this.state.deleteEmail}
                                      onChange={this.handleChange}
                                    />
                                  </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                  <Form.Group as={Col}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                      name="deletePassword"
                                      type="password"
                                      value={this.state.deletePassword}
                                      onChange={this.handleChange}
                                    />
                                  </Form.Group>
                                </Form.Row>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer
                              style={{
                                borderTop: "none",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Button
                                className="normalButton"
                                onClick={this.onShowDeleteConfirmModal}
                              >
                                Delete account
                              </Button>
                              <Button
                                className="cancelButton"
                                onClick={this.onCloseDeleteAccountModal}
                              >
                                Cancel
                              </Button>
                            </Modal.Footer>
                          </Modal>

                          <Modal
                            show={this.state.showDeleteConfirmModal}
                            onHide={this.onCloseDeleteConfirmModal}
                          >
                            <Modal.Header
                              closeButton
                              style={{ borderBottom: "none" }}
                            >
                              <Modal.Title>Delete account</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form style={{ color: "#333333" }}>
                                <p>Are you sure to delete your account?</p>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer
                              style={{
                                borderTop: "none",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Button
                                className="normalButton"
                                onClick={this.onDeleteAccount}
                              >
                                Yes
                              </Button>
                              <Button
                                className="cancelButton"
                                onClick={this.onCloseDeleteConfirmModal}
                              >
                                Cancel
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="changepassword"
                      title="Change Password"
                      tabClassName="sub_menus"
                    >
                      <div className="sub_content">
                        <div className="sub_title">Change password</div>
                        <div className="sub_body">
                          {this.state.passwordChangeSuccess && (
                            <label>
                              <p
                                style={{
                                  color: "green",
                                  fontSize: "14px",
                                  marginTop: "-10px",
                                }}
                              >
                                {this.state.passwordChangeSuccess}
                              </p>
                            </label>
                          )}
                          <Form>
                            <Form.Row>
                              <Form.Group as={Col} sm={4}>
                                <Form.Label>Current password</Form.Label>
                                <Form.Control
                                  type="password"
                                  name="currentpassword"
                                  value={this.state.currentpassword}
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                            </Form.Row>
                            {this.state.currentPasswordError && (
                              <label>
                                <p
                                  style={{
                                    color: "#EB5757",
                                    fontSize: "14px",
                                    marginTop: "-10px",
                                  }}
                                >
                                  {this.state.currentPasswordError}
                                </p>
                              </label>
                            )}
                            <Form.Row>
                              <Form.Group as={Col} sm={4}>
                                <Form.Label>New password</Form.Label>
                                <Form.Control
                                  type="password"
                                  name="newpassword"
                                  value={this.state.newpassword}
                                  onChange={this.handleChange}
                                />
                                <Form.Label style={{ fontWeight: "normal" }}>
                                  Password must be at least 8 characters with
                                  one capital and one number.
                                </Form.Label>
                              </Form.Group>
                            </Form.Row>
                            {this.state.newPasswordError && (
                              <label>
                                <p
                                  style={{
                                    color: "#EB5757",
                                    fontSize: "14px",
                                    marginTop: "-10px",
                                  }}
                                >
                                  {this.state.newPasswordError}
                                </p>
                              </label>
                            )}
                            <Form.Row>
                              <Form.Group as={Col} sm={4}>
                                <Form.Label>Re-enter new password</Form.Label>
                                <Form.Control
                                  type="password"
                                  name="reenterpassword"
                                  value={this.state.reenterpassword}
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                            </Form.Row>
                            {this.state.reenterPasswordError && (
                              <label>
                                <p
                                  style={{
                                    color: "#EB5757",
                                    fontSize: "14px",
                                    marginTop: "-10px",
                                  }}
                                >
                                  {this.state.reenterPasswordError}
                                </p>
                              </label>
                            )}
                            <Button
                              className="normalButton"
                              onClick={this.onUpdatePassword}
                              style={{ marginRight: "8px" }}
                            >
                              Update
                            </Button>
                            <Button
                              className="cancelButton"
                              onClick={this.handleCancelPassword}
                            >
                              Cancel
                            </Button>
                          </Form>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MyAccountPage;
